
.channel_title_info {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
}

.body_channel_payment {
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 16px;
}

.channel_infomation {
  width: 100%;
  display: flex;
  background: #f0f0f0;
  border-radius: 16px;
  padding: 16px;

  .channel_infomation_avatar {
    width: 60px;
    height: 60px;
    display: flex;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .channel_infomation_text {
    width: 100%;
    padding-left: 16px;

    .channel_infomation_text_title {
      width: 100%;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
    }

    .channel_infomation_text_description {
      width: 100%;
      display: inline-block;
    }
  }
}

.create_channel_logo {
  width: 100%;
  display: flex;
  align-items: center;

  .create_channel_logo_text {
    display: flex;
    font-size: 26px;
    font-weight: 700;
  }

  .create_channel_logo_image {
    height: 40px;
    width: auto;
    margin: 5px 8px;
    border-radius: 12px;
    display: flex;
  }
}

.create_channel_preview_file {
  border-radius: 12px;

  .create_channel_preview_file_loading {
    width: 80px;
    height: 80px;
    background-color: #e0e0e0;
    border-radius: 12px;

    img {
      width: 40px;
      height: 40px;
      margin: 20px;
    }
  }

  img {
    object-fit: cover;
    width: 80px;
    height: 80px;
    border-radius: 12px;
  }

  .create_channel_preivew_file_text {
    color: red;
    user-select: none;
    cursor: pointer;
  }
}

.create_channel_upload_file {
  border: 2px dotted #c0c0c0;
  border-radius: 8px;
  display: block;
  padding: 16px 16px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  .create_channel_upload_file_image {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    img {
      display: flex;
      width: 60px;
      height: 60px;
    }
  }

  .create_channel_upload_file_text {
    width: 100%;
    display: flex;
    text-align: center;
    color: #909090;
    margin-top: 8px;
  }

  .create_channel_upload_file_button {
    display: flex;
    text-align: center;
    text-align: center;
    align-content: center;
    justify-content: center;
    margin-top: 12px;
  }
}

.handle_input_hidden {
  width: 0px;
  height: 0px;
  overflow: hidden;
}


#is_premium_pending svg {
  width: 16px;
  height: 16px;
  float: left;
  margin: 0 2px -2px 0;
  display: inline-block;
}

#is_premium_pending p {
  display: inline-block;
  margin: -1px 0 0 12px;
  font-size: 15px;
  width: calc(100% - 30px);
  line-height: 1.3;
  white-space: pre;
}

.is_premium_pending_top {
  padding: 0 20px;
  font-size: 18px;
  font-weight: 700;
  color: #444;
  background: -webkit-linear-gradient(#d397fa, #8364e8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.is_premium_pending_info {
  border-bottom: 1px #e0e0e0 solid;
  padding: 10px 20px;
}

.is_premium_pending_number {
  border-bottom: 1px #e0e0e0 solid;
  padding: 10px 20px;
}

.is_premium_pending_name {
  border-bottom: 1px #e0e0e0 solid;
  padding: 10px 20px;
}

.text_approve{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.is_premium_pending_bank {
  border-bottom: 1px #e0e0e0 solid;
  padding: 10px 20px;
}

.is_premium_pending_bank p {
  width: calc(100% - 30px);
  line-height: 1.3;
  white-space: unset !important;
}

.is_premium_pending_price {
  border-bottom: 1px #e0e0e0 solid;
  padding: 10px 20px;
}

.is_premium_pending_description {
  border-bottom: 1px #e0e0e0 solid;
  padding: 10px 20px;
}

.is_premium_pending_content {
  border-bottom: 1px #e0e0e0 solid;
  padding: 10px 20px;
}
.container_approve{
  max-width: 600px;
  margin: 0 auto;
  .logo{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form-group {
  position: relative;
}

.form-group-show {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 56px;
  cursor: pointer;
  right: 0;
  user-select: none;

  &.active {
    svg {
      fill: var(--color-theme-primary);
    }
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #aaa;
  }
}

.modal-body {
  &__loading {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;

    .spinner-border {
      margin: 0 auto;
      margin-top: calc(50vh - 50px);
      margin-bottom: 100px;
      color: #fff !important;
    }
  }
}

.login__right__form__username__alert {
  // margin-top: 50px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
  margin-top: 10px;

  button {
    margin-right: 10px;
  }
}
