.viewGiftMission_item {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 80px repeat(1, minmax(0, 1fr));
  margin-bottom: 20px;
}

.viewGiftMission_item:last-child {
  margin-bottom: unset;
}

.viewGiftMission_item_img {
  border-radius: 6px;
}

.viewGiftMission_item_right_name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
  word-break: normal;
  cursor: default;
}
