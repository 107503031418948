.channelSettingPayment_container {
  display: grid;
  row-gap: 20px;
}

.channelSettingPoint_header_title {
  font-size: 20px;
  font-weight: 600;
}

.channelSettingPayment_form_wrapper {
  display: grid;
  row-gap: 10px;
}

.channelSettingPayment_image_label {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, auto));
  place-items: center;
  row-gap: 10px;
  border: 2px dotted #c0c0c0;
  border-radius: 6px;
  padding: 16px;
}

.channelSettingPayment_image_input {
  display: none;
}

.channelSettingPayment_image_title {
  font-size: medium;
  text-align: center;
}

.channelSettingPayment_image_button_label {
  padding: 2px 10px;
  border-radius: 6px;
  color: #fff;
  text-transform: uppercase;
}

.channelSettingPayment_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.channelSettingPayment_image_view_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.channelSettingPayment_image_view_wrapper img {
  border-radius: 6px;
  object-fit: cover;
}

.channelSettingPayment_delete_image_title {
  color: #ff0000;
  user-select: none;
  cursor: pointer;
}
