.loading_icon{
  right: 0;
  left: 0;
  top: 22px;
  position: absolute;
  text-align: center;
}
.text-payment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

@media screen and (max-width: 1380px) {
  .payment_text {
    font-size: 14px;

  }
  .payment__time_container {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 768px) {
  payment_reponsive{
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
  }

}
