

#broadcase {
    overflow: auto;
    transition: width 0.3s ease 0s, height 0.3s ease 0s;

    .broadcase_video_wrap {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-bottom: calc(var(--aspect-ratio, .5625) * 100%); 
        height: 0;
        background-color: #333;

        .empty_video {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
        }

        iframe,
            embed,
                object  {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
            width: 100%    !important;
            height: auto   !important;
            transition: width 0.3s ease 0s, height 0.3s ease 0s;
        }
    } 

}






#broadcase_chatting {

    border: 1px solid #ccc;
    position: relative;

    .broadcase_chatting_heading {
        overflow: auto;
        text-align: center;
        border-bottom: 1px solid #ccc;
        padding: 5px;
    }

    .broadcase_chatting_body {
        overflow: auto;
        height: calc(100vh - 200px);

        .broadcase_chatting_wrap {
            overflow: scroll;
            height: 100%;
            width: 100%;
            padding: 10px
        }
    }

    .broadcase_chatting_footer {
        overflow: auto;
        position: relative;
        width: 100%;
        min-height: 40px;
        padding: 5px;
        background-color: #fff;
        border-top: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1.4;

        .span_placeholder {
            position: absolute;
            left: 10px;
            top: 10px;
            font-size: 14px;
        }
        .editedField {
            overflow: auto;
            width: 85%;
            padding: 5px;
            position: relative;

        } 
        .submitButton {
            width: 15%;
            height: 100%;
            text-align: center;
            cursor: pointer;
        } 
    }


    .chat_element {
        overflow: auto;
        display: flex;
        gap: 5px;
        align-content: flex-start;
        justify-content: flex-start;
        margin-bottom: 15px;


        .chat_avatar {
            overflow: auto;
            width: 35px;
            min-width: 35px;
            height: auto;
        }
        .chat_meta {
            overflow: auto;
            font-size: 14px;
            line-height: 1.4;


            .chat_meta_name {
                color: #838383
            }

            .chat_meta_content {
                color: #333
            }
        }
    }
}





.share_wrap {
    overflow: hidden;

    a {
        overflow: auto;
        outline: unset;
        border: none;
        margin-right: 5px;
    }
}

















