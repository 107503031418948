#containerLivestreamAdmin {
  background-color: #000;
  height: 100vh;
  overflow: hidden;
  .livestream_action {
    height: calc(100vh - 30px);
    margin: 15px 0 15px -10px;

    .livestream_action_row_1 {
      overflow: hidden;
      background-color: var(--color-sidebar-dark);
      height: 22vh;
      border-radius: 8px;
    }
    .livestream_action_row_2 {
      overflow: hidden;
      background-color: var(--color-sidebar-dark);
      height: calc(78vh - 43px);
      border-radius: 8px;
    }
  }

  .livestreamvideo_container_bottom{
    position: absolute;
    bottom:3em;
    background: transparent;
    width: 98%;
    display: flex;
    padding: 2.5em 1em 1em 1em;
    justify-content: flex-end;
    pointer-events: none;
  }

  .livestreamvideo_btn_react{
    width: 5em;
    height: 5em;
    border: 0;
    background: transparent;
    padding: 0 !important;
    //transform: scale(1.4);
  }

  .livestream_container_preview {
    height: calc(92vh - 3.28rem);
    background-color: var(--color-sidebar-dark);
    margin: 15px 0;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
  }

  .livestream_footer {
    height: calc(8vh + 10px);
    background-color: var(--color-sidebar-dark);
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em;
  }

  .livestream_setting_volumn{
    display: flex;
  }

  .livestream_setting_group{
    display: flex;
  }

  .livestream_status_box{
    border: 1px dashed white;
  }


  @media screen and (max-width: 768px) {
    .livestream_setting_volumn{
      display: block;
    }
    .livestream_setting_group{
      display: block;
    }
  }

  .livestream_header {
    justify-content: space-between;
    display: flex;
    background-color: var(--color-sidebar-dark);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.3em 0;
  }

  .livestream_chatlist_container {
    flex:1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;


    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-theme-white); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }

  .pointer_custom{
    cursor: pointer;
  }

  .livestream_video_content {
    position: relative;
    overflow: hidden;
    text-align: center;
    height: calc(100% - 44px);
    //padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    .video-player {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      height: 100%;
      width: 100%;
      //margin-top: 0;
      //margin-right: auto;
      //margin-left: auto;
      //object-fit: contain;
    }
  }

  .livestreamadminreact{
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .react_img_board{
    width: 1.8em;
    height: 1.8em;
    margin-right: 0.5em;
  }

  .livestreamadmin_btn{
    margin-left: 1em;
    background-color: transparent;
    border: 0;
    font-size: var(--font-size-normal);
    color: var(--color-theme-white);
  }

  .livestreamadmin_btn_setting_source{
    margin-left: 0;
    padding-left: 0 !important;
    margin-right: 2em;
    background-color: transparent;
    border: 0;
    font-size: var(--font-size-normal);
    color: var(--color-theme-white);
  }

  .icon_btn{
    font-size: var(--font-size-h5);
  }

  .volume-slider {
    display: flex;
    align-items: center;
  }

  .slider {
    width: 100%;
    opacity: 0.7;
  }

  .slider:hover {
    opacity: 1;
  }

  .volume-label {
    margin-left: 1em;
    width: 50px;
    font-size: var(--font-size-normal);
  }
}


.livestreamAdminDeviceModal{

  .dragdrop_container_livestream{
    width:100%;
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    object-fit: cover;

    .loaded {
      position: absolute;
      width: 0;
      height: 100%;
      width: 100% !important;
      background-color: #000;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 9;
      //opacity: 0.4;
      color: #fff;
      font-weight: 700;
      font-size: var(--font-size-h5);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      pointer-events: none
    }
  }

  .livepreview_device_modal_body{
    background-color: var(--color-sidebar-dark);
    border-radius: 0;
    height: 400px;
  }

  .livepreview_modal_body_bg{
    background-color: var(--color-sidebar-dark);
    border: 0;
  }

  .livestreamSelection{
    height:300px !important;
    display: flex;
    flex-direction: column;
    padding: 0 15px 15px 15px;
    justify-content: center;
  }

  .livestreamSelection_note{
    font-size: var(--font-size-normal);
    color: white;
  }

  .livestreamSelection_obs_btn{
    background-color: var(--color-theme-primary-medium);
    color: white;
    font-weight: var(--font-weight-semi-bold);
    border: 0;
    :hover{
      background-color: var(--color-theme-primary);
    }
  }

  .livestreamadmin_screen_video_demo{
    background-color: var(--color-theme-black);
    margin-right: 1em;
    width: auto;
    height: 170px;
    object-fit: contain;
  }

  & .color-text-title{
    color: var(--color-theme-primary) !important;
  }
  & .nav-tabs{
    border: none !important;
    background: transparent !important;
    border-radius: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 0 !important;
  }

  & .nav-link {
    color: var(--color-theme-white) !important;
    border: 0 !important;
    margin-bottom: 20px !important;
  }

  & .nav-link:hover {
    color: var(--color-theme-primary-medium) !important;
  }

  & .nav-link.active {
    border-radius: 10px;
    color: var(--color-theme-black) !important;
    background-color: var(--color-theme-white) !important;
    border-bottom-color: var(--color-theme-primary) !important;
    border-bottom-width: 2px !important;
  }

  label{
    color: var(--color-theme-white) !important;
  }
  button.upload-button{
    color: var(--color-theme-white) !important;
  }

  .dragdrop_container{
    background: transparent !important;
  }

  #label-file-upload{
    background-color: transparent !important;
  }

}
