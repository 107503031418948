.livestream_chatitem_container{
  display: flex;
  margin: 6px 0;
  text-decoration: none;

  .livestream_chatitem_container_content{
    flex:1;
    padding: 0 5px 0 0;
  }

  .livestream_chatitem_txtname{
    color: var(--gamifa--preset--color--cyan-bluish-gray);
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-medium);

    &.is_admin{
      color: var(--color-theme-primary-medium);
    }
  }
  .livestream_chatitem_txtcontent{
    width: 100%;
    color: var(--color-theme-white);
    font-size: var(--font-size-small);
  }

  .livestream_chatitem_avatar{
    margin: 0 0.5em;
    width: 2em;
    background-color: red;
    height: 2em;
    border-radius: 2em;
    overflow: hidden;

    .image{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
