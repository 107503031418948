.listApprovalPost_container {
  display: grid;
  row-gap: 10px;
}

.listApprovalPost_wrapper {
  display: grid;
  row-gap: 10px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.listApprovalPost_item {
  display: grid;
  row-gap: 10px;
  justify-content: center;
}

.listApprovalPost_dropdown {
  display: flex;
  justify-content: flex-end;
}
