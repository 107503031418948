#gift-list {
  .gift_container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .gift_container_header {
    background: rgb(165, 28, 48);
    background: linear-gradient(
      162deg,
      var(--color-theme-primary-medium) 0%,
      var(--color-theme-warning) 40%,
      var(--color-theme-primary-light) 70%,
      var(--gamifa--preset--color--theme-yellow) 100%
    );
    height: 18em;
    width: 100%;
    display: flex;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .banner_gift {
    background-color: var(--color-theme-primary-light);
  }

  /*
  ========================
        3D Effect
  ========================
  */

  .gift_txtgift {
    text-transform: uppercase;
    font-size: var(--font-size-h1);
    font-weight: 700;
    color: #f5f5f5;
    text-shadow:
      1px 1px 1px #919191,
      1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 0px 1px #919191,
      1px 0px 1px #919191,
      1px 0px 1px #919191,
      1px 0px 1px #919191,
      1px 16px 5px rgba(16, 16, 16, 0.4),
      1px 20px 8px rgba(16, 16, 16, 0.2),
      1px 22px 30px rgba(16, 16, 16, 0.2),
      1px 26px 40px rgba(16, 16, 16, 0.4);
  }

  .gift_container_left {
    padding: 1.5em 0 1.5em 1.7em !important;
  }

  .gift_container_products {
    padding: 1.5em 1.7em !important;
  }

  @media only screen and (max-width: 1200px) {
    .gift_container_left {
      padding: 0 !important;
    }
    .gift_container_left_sticky_row {
      padding: 0 16px;
    }
    .gift_container_products {
      padding: 1em 0 !important;
    }
  }

  .gift_container_left_sticky {
    position: sticky;
    top: 5em;
  }

  .gift_container_fillter {
    background: var(--color-theme-white);
    padding: 1em;
    border: 1px solid #f0f0f0;
    border-radius: var(--border-radius-box);
    margin-bottom: 1.5em;
    h5 {
      font-weight: var(--font-weight-medium);

      overflow: hidden;
      line-height: 40px;

      //margin-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: var(--color-theme-secondary);

      padding-bottom: 0.5em;
    }

    .form-check {
      color: var(--global-font-color);
      font-size: var(--font-size-body);
      font-weight: var(--font-weight-medium);
      margin: 0.3em 0;
    }
  }

  .gift_container_products_header_frame {
    background: var(--color-theme-white);
    width: 100%;
    border: 1px solid #f0f0f0;
    border-radius: var(--border-radius-box);
    padding: 1em;
  }
  .gift_container_products_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      color: var(--global-font-color);
    }
  }

  @media (max-width: 1199px) {
    .sidebar-service-right.css_prefix-woo-sidebar {
      bottom: 0;
      left: 0;
      position: fixed;
      right: auto;
      top: 0;
      width: var(--filter-sidebar-width);
      transform: translate(-100%);
      -webkit-transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      z-index: 99999;
      background: var(--color-theme-white-box);
      -webkit-box-shadow: var(--global-box-shadow);
      box-shadow: var(--global-box-shadow);
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  @media only screen and (max-width: 811px) {
    .gift_container_header {
      background: rgb(165, 28, 48);
      background: linear-gradient(
        162deg,
        var(--color-theme-primary-medium) 0%,
        var(--color-theme-warning) 40%,
        var(--color-theme-primary-light) 70%,
        var(--gamifa--preset--color--theme-yellow) 100%
      );
      height: 8em;
      width: 100%;
      display: flex;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .form-select,
  select {
    //line-height: 3.123em!important;
    //height: 3.123em!important;
    font-weight: var(--font-weight-medium) !important;
    padding: 0.5em 3em 0.5em 1em !important;
    width: 100% !important;
    color: var(--global-font-color) !important;
    background-color: var(--global-body-bgcolor) !important;
    border: 0.0625em solid var(--border-color-light) !important;
    border-radius: var(--border-radius) !important;
    -webkit-transition: all 0.45s ease-in-out !important;
    transition: all 0.45s ease-in-out !important;
  }

  .gift_container_admin_header {
    margin-top: 1em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 1em;
    h6 {
      color: var(--global-font-color);
    }
  }

  .gift_btn_add {
    background: var(--color-theme-primary-medium) !important;
    color: var(--color-theme-white) !important;
    &:hover {
      background: var(--color-theme-primary) !important;
    }
  }

  .gift_container_search {
    & input {
      outline: none !important;
      box-shadow: none !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0;
      padding: 0 !important;
      margin: 0 0 0 0.5em !important;
    }

    & input:focus {
      outline: none !important;
      box-shadow: none !important;
      width: 13em;
      border-bottom: 1px solid var(--color-theme-primary);
      border-radius: 0;
      padding: 0 !important;
      margin: 0 0 0 0.5em !important;
    }
  }
}
