.chatIframe_container {
  display: grid;
  grid-template-rows: 70px 1fr auto;
  height: 100%;
  font-family:
    Montserrat,
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    Helvetica Neue,
    Segoe UI,
    Apple SD Gothic Neo,
    Noto Sans KR,
    Malgun Gothic,
    sans-serif;
}

.chatIframe_header,
.chatIframe_footer {
  background-color: #ffffff;
}

.chatIframe_header {
  display: grid;
  grid-template-columns: 40px 1fr 120px;
  column-gap: 10px;
  align-items: center;
  padding: 12px 20px;

  & .back-to-list{
    display: none;
  }
}

.chatIframe_body {
  padding: 20px;
  overflow: auto;
  width: 100%;
}

.chatIframe_header_image_wrapper {
  position: relative;
}

.chatIframe_header_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chatIframe_header__image__status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #00afb9;
  border: 3px #fff solid;
  position: absolute;
  bottom: -2px;
  right: 0;
}

.chatIframe_header_info_wrapper {
  display: grid;
  grid-template-rows: auto auto;
}

.chatIframe_header_info_name {
  color: var(--gamifa--preset--color--black);
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatIframe_header_info_active {
  font-size: 14px;
  color: #aaaaaa;
  white-space: nowrap;
}

.chatIframe_body {
  background-color: #fafafa;
  overflow-y: auto;
  .empty-message{
    align-items: center;
    .chatDetail_avatar{
      width: var(--Boder-Radius-R8, 100px);
      height: var(--Boder-Radius-R8, 100px);
      flex-shrink: 0;
      border-radius: 50%;
      object-fit: cover;
    }

    .button_info{
      
      .btn_item:hover{
        background-color: transparent;
        outline: none;
      }
    }
  }
}

.chatIframe_footer_wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  margin: 20px 14px;
  background: #f7f8f8;
  border-radius: 6px;
}

.chatIframe_footer_input {
  outline: none;
  border: none;
  color: #212529;
  padding: 12px 16px;
  height: 50px;
  background: #f7f8f8;
  max-height: 100px !important;
  resize: none;
  border-radius: 6px 0 0 6px;
}

.chatIframe_footer_input:focus,
.chatIframe_footer_input:focus-visible {
  outline: none;
  border: none;
}

.chatIframe_footer_icons {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
}

.chatIframe_footer_icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.send_message {
  outline: none;
  background: transparent;
  border: none;
}

.chatIframe_footer_icon:hover {
  background-color: #efefef;
  border-radius: 6px;
}

.chatIframe_footer_icon_item {
  width: 22px;
  height: 22px;
}

.chatIframe_footer_emoji {
  position: absolute;
  width: 390px;
  height: 350px;
  right: 20px;
  bottom: 68px;
  overflow: auto;
  background-color: #ffffff;
}

.chatIframe_client_typing {
  position: absolute;
  bottom: 84px;
  left: 16px;
  padding: 10px 0;
  font-size: 12px;
}

@media screen and (max-width: 678px) {
  .chatIframe_footer_emoji {
    right: -92px;
  }
}

@media screen and (max-width: 1024px){
  .chatIframe_header {
    grid-template-columns: 30px 40px 1fr 120px;
    padding: 12px 0;

    & .back-to-list{
      display: block;
    }
  }

  .chatIframe_body{
    .empty-message{
      align-items: center;
      .chatDetail_avatar{
        width: var(--Boder-Radius-R8, 100px);
        height: var(--Boder-Radius-R8, 100px);
        flex-shrink: 0;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
