#form-file-upload {
  text-align: center;
  position: relative;
  width:100%;
  height:100%;
}
#container_loading {
  width:100%;
  height:100%;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  //padding: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  //border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: '.SFNSText-Regular', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#thumbnail{
  position: absolute;
  top:0;
  width:100%;
  height:100%;
  pointer-events: none;
  object-fit: cover;
  border: 1px solid #fff;
}
.dragdrop_container{
  width:100%;
  display: flex;
  position: relative;
  overflow: hidden;
  height:100%;

  background: white;
  align-items: center;
  justify-content: center;
  //border: 1px solid #fff;
  object-fit: cover;

  .loaded {
    position: absolute;
    width: 0;
    height: 100%;
    background-color: #868686;
    top: 0;
    left: 0;
    z-index: 9;
    //opacity: 0.4;
    color: black;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}
.thumbnail_module_drop{
  position: absolute;
  width:100%;
  height:100%;
  pointer-events: none;
  object-fit: cover;
  border: 1px solid #fff;
}
.thumbnail_module_bottom_drop{
  background: black;
  opacity: 0.8;
  color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-normal);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 0;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
