.custom-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.6);
  animation: fade-in 0.3s ease-in-out;
  overflow: auto;
  z-index: 10001;
}

.custom-modal .show {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  // background-color: red;
  // border-bottom: 1px solid #f0f1f5;
  padding-bottom: 16px;
  background-color: white;
  position: relative;
  top: 0;
  z-index: 999;
  padding: 16px;
  // height: 120px;

  @media (max-width: 767px) {
    height: 90px;
  }

  &_title {
      font-weight: 600;
      text-align: center;

      &_button {
          border: none;
          width: 30px;
          height: 30px;
          border-radius: 60px;
          background-color: white;
      }
  }
}

#mission_status{
  color: white;
  width: 100px;
  border-radius: 8px;
  top: -20px;
  height: 20px;
  font-size: 14px
}

@keyframes fade-in {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}
.modal_body {
  overflow: hidden;
  // margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px 16px 0px 16px;

  @media (max-width: 767px) {
    padding: 0px;
  }
}

.modal-dialog {
  width: 0;
  height: 0;
  transition: width 0.5s, height 0.5s;
}

.zoom-enter-active,
.zoom-leave-active {
  transition: width 0.5s, height 0.5s;
}

.zoom-enter,
.zoom-leave-to {
  width: 300px;
  /* Kích thước phóng to bạn mong muốn */
  height: auto;
}

.modal_body .col_left {
  height: 100%;
  border-right: 1px solid #f0f1f5;
}

.modal_dialog_delete {
  border-radius: 32px;
  width: 0;
  height: 0;
  transition: width 0.6s, height 0.6s;
  max-height: 60vh;
  overflow-y: auto,
}

.custom-modal .modal_dialog {
  border-radius: 8px;
  padding: 0px 16px 0px 16px;
  overflow: auto;
  height: 90%;
}

.bg-primary {
  background-color: var(--color-theme-primary) !important;
}

/* Thêm các quy tắc media query để điều chỉnh kích thước modal */
@media (min-width: 768px) {
  .custom-modal .modal_dialog_delete {
      width: 10%;
      background-color: white;
      // height: auto;
      // padding: 0px 16px 16px 16px
  }
}

@media (max-width: 767px) {
  .custom-modal .modal_dialog_delete {
      width: 30%;
      background-color: white;
      padding: 16px
  }
}
.title_modal_notification {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
