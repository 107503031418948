#course-view {
  .courseview_topcontainer {
    background: var(--lp-secondary-color);
    width: 100%;
  }
  .courseview_container {
    padding: 1em;
  }
  .courseview_topcontainer.iconbook {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
  .courseview_topcontainer.iconmedal {
    width: 1.3em;
    height: 1.3em;
    margin-right: 5px;
    color: var(--color-theme-primary);
  }

  .courseview_topcontainer.title_course {
    font-weight: var(--font-weight-medium);
    overflow: hidden;
    line-height: 40px;
    font-size: 24px;

    //margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--color-theme-white);

    margin: 10px 0;
    padding: 0;
    padding-bottom: 20px;
  }

  .courseview_topcontainer.des_course {
    margin-top: 15px;
    font-size: 16px;
    font-weight: var(--font-weight-regular);
    color: var(--color-theme-white);
  }
  .courseview_topcontainer.createby_course {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 300;
    color: white;
  }
  .courseview_topcontainer.createat_course {
    font-size: 15px;
    font-weight: var(--font-weight-medium);
    color: var(--color-theme-white);
  }
  .courseview_topcontainer.lvl_course {
    font-size: var(--font-size-body);
    font-weight: 500;
    color: white;
  }
  .courseview_topcontainer.lvl_public {
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    color: var(--color-theme-online);
  }

  .courseview_topcontainer_infoontainer .breadcrumb,
  .courseview_topcontainer_infoontainer .breadcrumb a,
  .courseview_topcontainer_infoontainer .breadcrumb .breadcrumb-item,
  .courseview_topcontainer_infoontainer .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: var(--gamifa--preset--color--pale-pink);
    font-weight: var(--font-weight-medium);
    font-size: inherit;
  }

  .courseview_topcontainer_infoontainer .breadcrumb-item.active {
    color: #fff;
  }

  .courseview_topcontainer_menu {
    display: flex;
    color: white;
    align-items: center;
  }

  .courseview_topcontainer_menu.title_menu {
    color: white;
    font-weight: 600;
    font-size: 16px;
  }

  .courseview_topcontainer_menu.iconarrow_menu {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
  .courseview_inputdetailcourse {
    font-size: 16px;
    font-weight: 500;
    color: black;
    background: transparent;
    padding: 15px;
    width: 100%;
    margin-top: 5px;
    //margin-bottom: 100px;
    text-align: justify;
    //min-height: 100px;
    resize: none;
    overflow-y: hidden;

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }

    th,
    td {
      padding: 16px;
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
    }

    th:first-child,
    td:first-child {
      border-left: 1px solid #e0e0e0;
    }

    th {
      background-color: #f8f8f8;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #fff8f8;
    }
  }
  .courseview_txtdetailcourse {
    font-size: 18px;
    color: var(--lp-secondary-color);
    font-weight: var(--font-weight-semi-bold);
  }

  .courseview_txtcontent {
    font-size: 18px;
    color: var(--lp-secondary-color);
    font-weight: var(--font-weight-semi-bold);
  }

  @media only screen and (max-width: 768px) {
    .course_header_in_detail {
      .courseview_txtcontent {
      }
    }
  }

  .courseview_addbutton {
    margin-top: 5px;
    margin-bottom: 5px;
    background: #cb4859;
    border-radius: 5px;
    padding: 7px 15px;
    text-decoration: none;
    font-size: 12px;

    background: var(--color-theme-primary-light) !important;
    border: 0 !important;
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-bold) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: var(--color-theme-primary) !important;
    }
    &:hover {
      color: var(--color-theme-white) !important;
    }
  }

  .courseview_txtempty {
    font-size: 18px;
    margin-top: 25px;
    color: gray;
    font-weight: 600;
  }
  .courseview_txtnumofmodule {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #444444;
    font-weight: 400;
  }
  .courseview_viewmodule {
    width: 100%;
    margin-top: 25px;
    background: white;
    padding: 15px;
    border: 1px solid #f0f0f0;
  }
  .courseview_viewlongdescription {
    width: 100%;
    margin-top: 25px;
    background: white;
    padding: 15px;
    border: 1px solid #f0f0f0;
  }

  .courseview_card {
    display: flex;
    flex-direction: column;
    margin-top: -13em;
    background: white;
  }
  .courseview_card_media {
    display: flex;
    width: 100%;
    height: 100%;
    background: white;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    object-fit: cover;
  }
  .courseview_card_media_container {
    width: 100%;
    height: 15em;
  }
  .courseview_card_info {
    padding: 2em;
  }
  .courseview_card_media.logo_image {
    object-fit: contain;
    border: 0 solid #000;
  }
  .courseview_card_media_logo_video {
    object-fit: contain;
    border: 0 solid #000;
    width: 100%;
    height: 15em;
    background: white;
  }

  .courseview_card_button {
    background: var(--color-theme-primary-medium);
    border-radius: var(--border-radius-box);
    color: white;
    border: 0;
    padding: 0.5em 0;
    width: 100%;
    font-size: 15px;
  }
  .courseview_card_button:hover {
    background: var(--color-theme-primary);
  }

  .courseview_card_button_auth {
    margin-top: 25px;
    margin-right: 15px;
    margin-left: 15px;
    background: white;
    border-radius: 6px;
    color: #a51c30;
    border: 1px solid #a51c30;
    padding: 7px 25px;
  }
  .courseview_card_button_auth:hover {
    background: #fff6f6;
  }

  .courseview_card_txtauth {
    margin-top: 3em;
    margin-bottom: 0.5em;
    align-self: flex-start;
    font-size: 0.925em;
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-medium);
  }

  .courseview_card_avatar {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    object-fit: cover;
  }

  .courseview_card_authcontainer {
    &:hover .courseview_card_txtteacher {
      color: var(--color-theme-primary);
    }
    &:hover .courseview_card_txtnameauth {
      color: var(--color-theme-primary);
    }
  }
  .courseview_card_txtteacher {
    font-size: 13px;
    color: #262626;
    font-weight: 400;
  }
  .courseview_card_txtnameauth {
    //width:180px;
    font-size: 18px;
    color: #262626;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .courseview_card_txtdescription {
    font-size: 16px;
    color: #262626;
    font-weight: 400;
    text-align: justify;
    margin-top: 1em;
  }

  .courseview_card_btnupdate {
    background: var(--color-theme-primary-light) !important;
    border: 0 !important;
    color: var(--color-theme-primary) !important;
    font-weight: var(--font-weight-bold) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 15px !important;
    padding: 10px 12px !important;
    &:hover {
      background: var(--color-theme-primary) !important;
      color: var(--color-theme-white) !important;
    }
  }

  .courseview_card_btndelete {
    background: var(--color-theme-danger-light) !important;
    border: 0 !important;
    color: var(--color-theme-danger) !important;
    font-weight: var(--font-weight-bold) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 0.925em !important;
    padding: 0.6em 1em !important;
    &:hover {
      background: var(--color-theme-danger) !important;
    }
    &:hover .icon_delete {
      color: var(--color-theme-white) !important;
    }
  }

  .courseview_txt_progress {
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
  }
  .courseview_txt_progress.percent {
    font-size: 18px;
    color: #a51c30;
    font-weight: 700;
  }

  .courseview_txt_status {
    font-size: 14px;
    font-weight: 600;
  }
  .courseview_txt_status.ban {
    color: var(--color-theme-danger);
  }
  .courseview_txt_status.draf {
    color: #5d5d5d;
  }
  .courseview_txt_status.done {
    color: var(--color-theme-success);
  }

  .courseview_img_status {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    color: var(--color-theme-success);
  }

  .courseview_cardcontainer {
    margin-right: 15px;
    position: sticky;
    top: 18em;
    padding: 1em;
  }

  @media only screen and (max-width: 811px) {
    .courseview_cardcontainer {
      margin-right: 0;
      position: relative;
      top: 0;
      padding: 0;
    }
    .courseview_card {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 991px) {
    .courseview_card {
      margin-top: 0;
    }
  }
}
