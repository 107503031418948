.userDetail_header_image_wrapper {
  position: relative;
}

.userDetail_header_image_add {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  column-gap: 10px;
  align-items: center;
  bottom: 20px;
  right: 20px;
  padding: 4px 12px;
  color: #fff;
  border-radius: 6px;
}

.userDetail_header_image_add span {
  font-weight: 500;
}

.userDetail_header_image {
  height: 280px;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.userDetail_header_image_user {
  position: relative;
  width: 200px;
  height: 200px;
  border: 10px solid var(--gamifa--preset--color--white);
  border-radius: 50%;
  object-fit: cover;
}

.userDetail_header_body {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr)) auto;
  padding: 0 40px;
}

.userDetail_header_button_wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  height: 100%;
  align-items: center;
}

.userDetail_header_button {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 175px));
  row-gap: 10px;
}

.userDetail_header_button_follow {
  outline: unset;
  border: 1px solid var(--color-theme-primary);
  background-color: #fff;
  font-size: 16px;
  color: var(--color-theme-primary);
  border-radius: 6px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
}

.userDetail_header_button_follow:hover {
  background-color: #f7abab;
}

.userDetail_header_user_wrapper {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  column-gap: 10px;
  align-items: center;
  margin-top: -60px;
}

.userDetail_header_info_user_name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  cursor: default;
  font-size: 26px;
  font-weight: 700;
  margin: unset;
}

.userDetail_header_info_user_title.userDetail_header_info_user_mentor {
  font-weight: 600;
  color: var(--color-theme-primary);
}

.userDetail_header_info_user_title {
  font-size: 18px;
  font-weight: 400;
}

.userDetail_body {
  display: flex;
  padding: 0 40px;
}

.userDetail_body_left {
  // max-width: 30%;
}

.userDetail_body_right {
  height: auto;
}

.userDetail_body_left_wrapper {
  position: sticky;
  top: 80px;
  height: auto;
  background-color: #fff;
  margin-top: 24px;
  border-radius: 6px;
  padding: 16px;
}

.userDetail_body_left_item {
  display: grid;
  grid-template-rows: auto repeat(1, minmax(0, 1fr));
}

.userDetail_body_left_item_title {
  font-weight: 600;
  font-size: 16px;
  margin-top: 8px;
}

.userDetail_body_left_item_content {
  font-size: 15px;
  font-family: var(--global-font-family);
  line-height: 1.5;
}

.userDetail_body_left_item_content_truncated {
  display: flex;
  justify-content: center;
  color: var(--global-font-color);
  text-decoration: underline;
}

.userDetail_body_left_item_content_truncated:hover {
  opacity: 0.6;
}

.userDetail_body_left_second {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  border-bottom: 1px solid #f0f1f5;
  border-top: 1px solid #f0f1f5;
  padding: 8px 0;
  margin-top: 8px;
}

.userDetail_body_left_second_item {
  display: grid;
  grid-template-rows: repeat(2, minmax(0, auto));
  text-align: center;
}

.userDetail_body_left_second_item_value {
  font-size: 15px;
  font-weight: 600;
}

.userDetail_body_left_second_item_title {
  font-size: 13px;
  line-height: 1.5;
}

.userDetail_body_left_second_item:nth-child(2) {
  border-left: 1px solid #f0f1f5;
  border-right: 1px solid #f0f1f5;
}

.userDetail_body_left_third {
  margin-top: 8px;
  padding-bottom: 8px;
}

.userDetail_body_left_third_item {
  display: grid;
  grid-template-columns: auto repeat(1, minmax(0, 1fr));
  align-items: center;
  column-gap: 10px;
}

.userDetail_body_left_four {
  display: grid;
  row-gap: 16px;
  border-top: 1px solid #f0f1f5;
  padding-top: 8px;
}

.userDetail_body_left_four_item {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
}

.userDetail_body_left_four_item_value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  color: var(--color-theme-primary);
}

.userDetail_body_left_four_item_image {
  border-radius: 6px;
}

@media screen and (max-width: 960px) {
  .userDetail_body {
    flex-direction: column;
    padding: unset;
  }

  .userDetail_body_left {
    // max-width: 100%;
  }

  .userDetail_header_button{
    justify-content: center;
  }

  .userDetail_header_user_wrapper {
    grid-template-columns: 1fr;
    margin-top: -30px;
  }

  .userDetail_header_image_user_wrapper,
  .userDetail_header_info_user,
  .userDetail_header_button_edit_profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .userDetail_header_image_user {
    width: 160px;
    height: 160px;
  }

  .userDetail_header_body {
    grid-template-columns: 1fr;
    justify-content: center;
    row-gap: 20px;
    padding: unset;
  }
}

@media screen and (max-width: 566px) {
  .userDetail_header_image_add {
    column-gap: unset;
    padding: 5px;
  }

  .userDetail_header_image_add span {
    display: none;
  }
}
