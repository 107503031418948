.userSetting_container {
  display: flex;
}

.userSetting_left {
  width: 30%;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  position: sticky;
  top: 80px;
  height: fit-content;
}

.userSetting_left_item {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
  padding: 10px 0;
}

.userSetting_left_item.active {
  color: var(--color-theme-primary);
}

.userSetting_left_item:nth-child(2) {
  border-bottom: 2px solid #f0f1f5;
  border-top: 2px solid #f0f1f5;
}

.userSetting_left_item:nth-child(3) {
  border-bottom: 2px solid #f0f1f5;
}

.userSetting_left_item_title {
  font-weight: 600;
}

.userSetting_right {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  flex: 1;
  margin-left: 20px;
}

@media screen and (max-width: 920px) {
  .userSetting_container {
    flex-direction: column;
  }

  .userSetting_left {
    position: unset;
    width: 100%;
  }

  .userSetting_right {
    margin-left: unset;
    margin-top: 20px;
  }
}
