.pricing-mobile-container{
  background-color:#F0F0EF;
  padding: 60px 50px;
}
.pricing-mobile-card{
  border-radius: 20px !important;
  height: 100%;
  margin-top: 32px;
  margin-bottom: 24px;
  background-color: #fff;
  border: solid 1px #cccccc;;
  overflow: hidden;
  box-shadow: 2px 2px 2px  #cccccc;
}
.mobile-card-content{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 30px 20px 0 !important;

  .mobile-card-title{
    font-size: 30px;
    font-weight: bold;
  }
  .mobile-card-lable{
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 8px;
  }
  .mobile-card-text{
    font-size: 14px;
    margin: 0;

    .mobile-card-price{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .price-text{
    font-size: 42px;
    margin: 0;
  }
}
.mobile-card-body{
  padding: 0 20px !important;
  margin: 0 !important;
}
.mobile-card-des{
  border-color: var(--color-theme-primary) !important;
  .des-text{
    background-color: var(--color-theme-primary-light);
    color: var(--color-theme-primary) ;
    font-size: 16px;
  }
}
.mobile-card-btn{
  padding: 20px 0;
  .btn{
    border-radius: 20px;
    width: 100%;
  }
}
.mobile-card-item{
  max-width: 350px !important;
  margin: auto;
}
@media screen and (max-width: 1400px){
  .mobile-card-content{
    .mobile-card-title{
      font-size: 24px;
    }
    .price-text{
      font-size: 30px;
      margin: 0;
    }
  }
}
@media screen and (max-width: 992px){
  .pricing-mobile-container{
    padding: 60px 10px;
  }
  .mobile-card-content{
    margin: 0;
    padding: 20px 10px 0 !important;
    .mobile-card-title{
      font-size: 22px;
    }
    .price-text{
      font-size: 28px;
      margin: 0;
    }
    .card-des-text{
      font-size: 14px
    }
  }
  .mobile-card-des{
    .des-text{
      font-size: 13px !important;
      padding: 8px 10px;
    }
  }
  .mobile-card{
    display: flex;
    flex-direction: column;
  }
  .mobile-card-item{
    width: 350px !important;
  }
}
