.coursemoduleitem {
  border-top: 0.5px solid #d1d7dc;
  border-right: 0.5px solid #d1d7dc;
  border-left: 0.5px solid #d1d7dc;
  //padding: 12px;
}
.coursemoduleitem.last_module {
  border-bottom: 0.5px solid #d1d7dc;
}

.coursemoduleitem_angleicon {
  margin-right: 15px;
  width:14px;
  height: 14px;
  transition: transform 0.3s;
  color: var(--global-font-color)
}

.coursemoduleitem_checkicon {
  margin-right: 15px;
  width:12px;
  height: 12px;
  color: var(--color-theme-success)
}
.coursemoduleitem_bookicon {
  margin-right: 15px;
  width:12px;
  height: 12px;
  color: var(--color-theme-danger)
}

.coursemoduleitem_container:hover .coursemoduleitem_titlemodule{
  color: var(--color-theme-primary)
}

.coursemoduleitem_title {
  color: var(--global-font-title);
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 20px;
}
.coursemoduleitem_title.empty_module {
  color: gray;
  //font-weight: 400;
  //font-size: 18px;
}

.coursemoduleitem_numModule {
  color: #000;
  font-weight: 400;
  font-size: 14px;
}

.coursemoduleitem_titlemodule {
  color: var(--global-font-title);
  font-weight: 400;
  font-size: 15px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.coursemoduleitem_titlemodule.selected_module {
  color: #a51c30;
  font-weight: bold;
}

.coursemoduleitem_groupheader {
  background: #f7f9fa;
  padding: 12px;

  & .item_left{
    max-width: 83%;
  }
}
.coursemoduleitem_groupheader.not_empty {
  cursor: pointer;
}

.rotate_arrow_module {
  transform: rotate(180deg);
}

.coursemoduleitem_titlemodule_createat {
  color: #000;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
}
.coursemoduleitem_titlemodule_createat.selected_module {
  color: #a51c30;
}
