#long_event {
}

.single-box {
    margin-bottom: 24px;
    background-color: #FFF;
    border-radius: 15px;
}

.avatar-box {
    width: 100%;
    height: auto;
}



.image-event {
    object-fit: fill;
    width: 100%;
    height: 200px;
}

.date-area {
    padding: 6px 10px;
    background-color: var(--color-theme-primary);
    border-radius: 10px;
    color: var(--bs-white);
}

.mdtxt {
    font-size: 16px;
    line-height: 22px;
}

.smtxt {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
}

.city-area {
    color: #555353;
}

.cmn-btn {
    background: var(--primary-color);
    padding: 4px 15px;
    color: var(--bs-white);
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    z-index: 0;
    border-radius: 10px;
    transition: 0.3s;
}

.button-event{
    background-color: var(--color-theme-primary) !important;
    border-color:  var(--color-theme-primary) !important;
    color: #fff !important
}
.button-event:hover{
    background-color: var(--color-theme-primary-medium) !important;
    border-color: var(--color-theme-primary-medium) !important;
}

.event_element {
    overflow: hidden;
    .event_title {
        // font-size: 1em;
        line-height: 1.5rem;
        font-weight: var(--font-weight-semi-bold);
        height: 48px;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.height-image-event {
    max-height: 500px;
    min-height: 300px;
    object-fit: cover;
    background: #000;
}

.bg-event-header {
    background: rgba(0, 0, 0, .5)
}

.details-area {
    background: white;
    // box-shadow: var(--box-2nd-shadow);
    border-radius: 10px;
}

.top-area {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
}

button.active {
    color: var(--primary-color);
    transition: 0.3s;
}

.friends-list img {
    width: 25px;
    border-radius: 50%;
    background: var(--box-1st-color);
    height: 25px;
}

.friends-list ul {
    margin-left: 5px;
    padding: 0;
    margin: 0;
    list-style: none;
}

.friends-list li {
    margin-left: -5px;
}

.friends-list span {
    color: var(--para-1st-color);
}

.mdtxt {
    font-size: 16px;
    line-height: 22px;
}

.map-event {
    width: 100%;
    max-height: 400px;
    min-height: 300px;
}

.image-event-item{
    height: 200px;
    object-fit: cover;
}

.event_container_search{
    margin: 0 1em;
  & input{
    outline: none !important;
    box-shadow: none !important;
    width: 13em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 0;
    padding: 0 !important;
    margin: 0 0 0 0.5em !important;
    background: transparent !important;
  }

  & input:focus{
    outline: none !important;
    box-shadow: none !important;
    width: 13em;
    border-bottom: 1px solid var(--color-theme-primary) !important;
    border-radius: 0;
    padding: 0 !important;
    margin: 0 0 0 0.5em !important;
  }


}
