// Css LightBox
#shopDetail .yarl__flex_center {
  border-radius: 12px;
}

#shopDetail .yarl__icon {
  color: #a51c30;
  width: 24px;
  height: 24px;
}

#shopDetail .yarl__button {
  background: #fff;
  border-radius: 12px;
  padding: 9px;
  height: 42px;
  margin: 0 17px;
}