.settingMission_container {
  display: grid;
  row-gap: 10px;
}

.settingMission_wrapper {
  display: grid;
  width: 100%;
  row-gap: 20px;
}

.settingMission_body {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
}

.settingMission_body_wrapper,
.settingMission_body_header_wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)) 30px;
  background-color: var(--color-theme-white);
  box-shadow: 0 0px 1px var(--color-theme-primary);
  color: var(--color-theme-black);
  border-radius: 6px;
  padding: 10px 20px;
  column-gap: 10px;
  height: auto;
  margin-bottom: 20px;
  align-items: center;
}

.settingMission_body_header_wrapper {
  background-color: var(--color-theme-primary);
  color: var(--color-theme-white);
  font-weight: 500;
}

.settingMission_body_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: 0.3s all ease-in-out;
}

.settingMission_body_button:hover {
  background-color: var(--gamifa--preset--color--custom-sun);
  cursor: pointer;
  color: var(--color-theme-primary);
}

.settingMission_body_button.active {
  transform: rotate(180deg);
}

.settingMission_body_timeline {
  margin-bottom: 10px;
  background-color: var(--color-theme-primary-light);
  padding: 20px;
  border-radius: 6px;
}

.settingMission_body_title {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: normal;
}
