// Order detail

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// mixins
$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin flex($dir: row, $align: center, $justify: center, $gap) {
  display: flex;
  flex-direction: $dir;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: wrap;
  gap: $gap;
}

article {
  margin-bottom: 30px;
}

.order-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1.5em;
  @include mobile() {
    padding: 20px;
  }
  @include tablet() {
    padding: 40px;
  }
}
.order_notification {
  .order_notification_item{
    display: flex;
    flex-direction: row;
    padding: 0 12px;
    border: 10px;
    &:last-child{
      color: var(--color-theme-primary);
      .order__border{
          display: none;
      }
    }

  }
}

.order-detail-wrapper {
  background: #fff;
  padding: 10px 12px;
  border: 10px;
  & p {
    margin: 0 0 16px 0;
  }
}
.order__item{
  height: 100%;
}

.order__title {
  text-transform: uppercase;
  font-weight: 500;
}

.icon_loading{
  right: 0;
  left: 0;
  top: 18px;
  position: absolute;
  text-align: center;
}

.order__status {
  @include flex(row, flex-start, flex-start, 16px);
}

.order__customer {
  @include flex(row, flex-start, space-between, 30px);
  @include mobile() {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    height: 100%;
  }
  & > div {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .customer-info,
  .payment-info {
    flex: 1;
  }

  .customer-name {
    font-weight: 900;
    color: var(--global-font-title);
  }
}

.order__payments {
  @include flex(column, flex-end, center, 10px);
  img {
    width: 25px;
    height: 25px;
    margin-right: 6px;
  }

  &-status--danger {
    color: var(--color-theme-danger);
  }
  &-status--success {
    color: var(--color-theme-success-dark);
  }
  &-status--warning {
    color: var(--color-theme-ratting);
  }
}

.order-item-wrapper {
  @include flex(row, flex-start, flex-start, 10px);
}

.product-image {
  width: 150px;
  height: 100px;
  img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
}

.product-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  > p {
    margin: 0 0 3px !important;
    //margin-bottom: 3px;
  }
}

.order__actions {
  @include flex(row, center, flex-start, 10px);
  a {
    color: inherit;
    text-decoration: none;
  }
}

.order__summary {
  align-items: center;
  //min-width: 300px;
  //margin-left: auto;
  //@include flex(row, center, end, 20px);
  .total-price {
    font-size: 20px;
    color: var(--color-theme-danger);
    font-weight: 900;
    text-align: end;
  }
}

.order-info{
  min-height: 461px !important;
}
.order_payment_text{
  font-style: italic;
  font-weight: 500;
}
.order-code{
  padding-left: 30px !important;
}
.order-price{
  text-align: end;
  padding-right: 50px !important;
}

/** Order Item **/

.order-item-body {
  .status {
    text-transform: capitalize;
    &--success
    {
      color: var(--color-theme-success-dark);
    }
    &--warning {
      color: var(--color-theme-ratting);
    }
    &--danger
    {
      color: var(--color-theme-danger);
    }
  }
  td:nth-child(6) {
    //display: -webkit-box;
    -webkit-box-orient: vertical;
    //-webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
  }
}

@media screen and (max-width: 1200px){
  .order-price{
    padding-right: 30px !important;
  }
}

@media screen and (max-width: 1200px){
  .order-item-body{
    font-size: 14px !important;
  }

  .order__customer{
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 507px){
  .order__payments {
    margin-left: auto;
  }
}
