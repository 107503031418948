.listChannel_container {
  width: 100%;
  display: grid;
  place-items: center;
}

.listChannel_wrapper {
  display: grid;
  place-items: center;
  width: 100%;
}

.listChannel_logo_wrapper {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  align-items: center;
}

.listChannel_logo_title {
  font-size: 26px;
  font-weight: 700;
  color: var(--color-theme-primary);
}

.listChannel_logo_image {
  border-radius: 50%;
  object-fit: cover;
}

.listChannel_list_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  width: 100%;
  place-items: center;
  border-top: 1px #e0e0e0 solid;
}

.listChannel_list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;
}

.listChannel_list_item {
  display: grid;
  grid-template-rows: 200px auto;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
}

.listChannel_list_item_channel {
  display: grid;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
}

.listChannel_list_item_channel:hover {
  background-color: #e0e0e0;
  border-radius: 6px;
}

.listChannel_list_item_icon_wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #e0e0e0;
}

.listChannel_list_item_icon_wrapper:hover {
  background-color: #c4c5c6;
}

.listChannel_list_item_image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.listChannel_list_item_name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: auto;
  color: #444;
  font-size: 20px;
  font-weight: 700;
  margin: 24px 0 0;
  height: 70px;
}

.listChannel_list_item_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.listChannel_list_item_button {
  background-color: #e0e0e0;
  border: unset;
  border-radius: 6px;
  padding: 5px 10px;
  width: 160px;
}

.loader_wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #67676717;
  display: grid;
  place-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: var(--color-theme-primary);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .listChannel_list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 567px) {
  .listChannel_list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-content: center;
    align-items: flex-start;
  }

  .listChannel_list_item_image_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
