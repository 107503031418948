//header
.upgrade_header_container{
  //min-height: 400px;
  height: 600px;
  background-image: url("../image/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .upgrade-header-content{
    padding-top: 20px;
    color: var(--color-theme-primary);
  }
}

//upgrade-table
.upgrade-compare-container{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 24px;
}
.upgrade-compare-title{
  color: var(--global-font-color) !important;
}
.upgrade-compare-des{
  text-align: center;
}
.upgrade-compare-table{
  text-align: start;
  td,th {
    width: 200px;
  }
  tr:nth-child(odd) td {
    background-color: var(--color-theme-primary-light);
  }
}

.upgrade-card-btn{
  width: 100%;
  display: flex;
  padding: 20px 0;
  .btn{
    display: flex;
    justify-items: flex-start;
    border-radius: 20px;
    width: max-content;
  }
}

@media screen and (max-width: 992px ) {
  .upgrade_header_container{
    height: 400px !important;
    .upgrade-header-content{
      font-size: 2rem !important;
    }
  }
}

@media screen and (max-width: 890px) {
  .upgrade_header_container{
    .upgrade-header-content{
      font-size: 1.5rem !important;
    }
    .upgrade-header-subContent{
      font-size:13px !important;
    }
  }
}

@media screen and (max-width: 567px) {
  .upgrade-btn-item{
    display: flex;
    justify-content: flex-end !important;
  }
}
