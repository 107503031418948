#course-update {
  .courseupdate_topcontainer {
    background: var(--lp-secondary-color);
    width: 100%;
  }
  .courseupdate_topcontainer.iconbook {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  .courseupdate_topcontainer.iconmedal {
    width: 24px;
    height: 24px;
    margin-left: 25px;
    color: var(--color-theme-primary);
  }
  .courseupdate_topcontainer.title_course {
    font-size: 32px;
    font-weight: 700;
    color: white;
  }
  .courseupdate_inputdes_course {
    padding: 5px 15px;
    resize: none;
    overflow-y: hidden;
    margin-left: -15px;

    margin-top: 15px;
    font-size: 0.925em;
    font-weight: var(--font-weight-regular);
    color: var(--color-theme-white);
  }
  .courseupdate_input_price_container {
    display: grid;
    align-items: center;
    row-gap: 10px;
  }
  .courseupdate_input_price_wrapper {
    display: grid;
    grid-template-columns: auto repeat(1, minmax(0, 1fr));
    align-items: center;
  }
  .courseupdate_body_setup_price {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 10px;
    align-items: flex-start;
  }
  .courseupdate_input_price_wrapper > label {
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-align: end;
  }
  .courseupdate_input_price {
    margin: 0 5px;
  }
  .courseupdate_input_time_wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 10px;
  }
  .courseupdate_topcontainer.createby_course {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 300;
    color: white;
  }
  .courseupdate_topcontainer.createat_course {
    font-size: 13px;
    font-weight: 300;
    color: white;
  }
  .courseupdate_topcontainer.lvl_course {
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-align: end;
  }
  .courseupdate_topcontainer_infoontainer {
    padding: 3em 1em;
    display: flex;
    flex-direction: column;
  }

  .courseupdate_topcontainer_menu {
    display: flex;
    color: white;
    align-items: center;
  }

  .courseupdate_topcontainer_menu.title_menu {
    color: white;
    font-weight: 600;
    font-size: 16px;
  }

  .courseupdate_topcontainer_menu.iconarrow_menu {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
  .courseupdate_addbutton {
    margin-top: 5px;
    margin-bottom: 5px;
    background: #cb4859;
    border-radius: 5px;
    color: white;
    border: 0;
    padding: 7px 15px;
    text-decoration: none;
    font-size: 14px;
  }
  .courseupdate_addbutton:hover {
    background: #a51c30;
  }
  .courseupdate_txtcontent {
    font-size: 1.1em;
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-semi-bold);
  }
  .ck-editor__editable {
    min-height: 300px;
  }
  .courseupdate_txtdetailcourse {
    margin-bottom: 15px;

    font-size: 1.1em;
    color: var(--lp-secondary-color);
    font-weight: var(--font-weight-semi-bold);
  }
  .courseupdate_txtempty {
    font-size: 18px;
    margin: 15px 0;
    color: var(--color-theme-grey);
    font-weight: 600;
  }
  .courseupdate_txtempty.small_txt {
    font-size: 14px;
    color: var(--color-theme-grey);
    font-weight: 600;
  }
  .courseupdate_txtnumofmodule {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #444444;
    font-weight: 400;
  }
  .courseupdate_viewmodule {
    width: 100%;
    margin-top: 25px;
    background: white;
    padding: 15px;
    border: 1px solid #f0f0f0;
  }
  .course_view_author_meta_wrap {
    padding: 2em;
  }

  .courseupdate_cardcontainer {
    margin-right: 15px;
    position: sticky;
    top: 18em;
    padding: 1em;
  }
  .courseupdate_card {
    display: flex;
    flex-direction: column;
    margin-top: -13em;
    background: white;
    @media (max-width: 1220px) {
      margin-top: -8em;
    }
  }
  .courseupdate_uploadmedia_container {
    width: 100%;
    height: 15em;
  }
  .courseupdate_container_form{
    overflow-y: scroll;
    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--global-font-color); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }

  .courseupdate_card_media {
    display: flex;
    width: 100%;
    height: 100%;
    background: white;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    object-fit: cover;
  }
  .courseupdate_card_media.logo_image {
    object-fit: contain;
    border: 0 solid #000;
  }
  .courseupdate_card_media_logo_video {
    object-fit: contain;
    border: 0 solid #000;
    width: 100%;
    height: 100%;
    background: white;
  }

  .courseupdate_card_button {
    margin-top: 25px;
    margin-right: 15px;
    margin-left: 15px;
    background: transparent;
    border-radius: 10px;
    color: gray;
    border: 0;
    padding: 7px 25px;
  }

  .courseupdate_card_button_auth {
    margin-top: 25px;
    margin-right: 15px;
    margin-left: 15px;
    background: transparent;
    border-radius: 6px;
    color: gray;
    border: 1px solid transparent;
    padding: 7px 25px;
  }

  .courseupdate_card_txtauth {
    margin-bottom: 0.5em;
    align-self: flex-start;
    font-size: 0.925em;
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-medium);
  }

  .courseupdate_card_btncancel {
    background: var(--color-theme-danger-light) !important;
    border: 0 !important;
    color: var(--color-theme-danger) !important;
    font-weight: var(--font-weight-bold) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 0.925em !important;
    padding: 0.6em 0 !important;
    &:hover {
      background: var(--color-theme-danger) !important;
      color: var(--color-theme-white) !important;
    }
  }

  .courseupdate_card_btnupdate {
    background: var(--color-theme-primary-light) !important;
    border: 0 !important;
    color: var(--color-theme-primary) !important;
    font-weight: var(--font-weight-bold) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 0.925em !important;
    padding: 0.6em 0 !important;
    &:hover {
      background: var(--color-theme-primary) !important;
      color: var(--color-theme-white) !important;
    }
  }

  .courseupdate_card_avatar {
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }

  .courseupdate_card_authcontainer {
    margin: 0 15px;
  }
  .courseupdate_card_txtteacher {
    font-size: 13px;
    color: #262626;
    font-weight: 400;
  }
  .courseupdate_card_txtnameauth {
    font-size: 18px;
    color: #262626;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .courseupdate_card_txtdescription {
    font-size: var(--font-size-normal);
    color: #262626;
    font-weight: 400;
    text-align: justify;
    margin-top: 1em;
  }

  .courseupdate_txt_progress {
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
  }
  .courseupdate_txt_progress.percent {
    font-size: 18px;
    color: #a51c30;
    font-weight: 700;
  }

  .courseupdate_txt_status {
    font-size: 14px;
    font-weight: 600;
  }
  .courseupdate_txt_status.ban {
    color: #c20b0b;
  }
  .courseupdate_txt_status.done {
    color: #0bb724;
  }

  .courseupdate_img_status {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  .courseupdate_inputnamecourse {
    font-size: 1.5rem;
    background: transparent;
    padding: 0 15px;
    font-weight: var(--font-weight-medium);
    resize: none;
    margin-left: -15px;

    overflow: hidden;
    line-height: 40px;

    //margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--color-theme-white);

    padding-bottom: 20px;
  }
  .courseupdate_inputdetailcourse {
    font-size: 16px;
    font-weight: 700;
    color: black;
    background: transparent;
    padding: 15px;
    width: 100%;
    margin-top: 5px;

    resize: none;
    overflow-y: hidden;
    margin-left: -15px;
  }

  .courseupdate_input {
    outline: none;
    background: transparent;
    border: 1px dashed transparent;
  }
  .courseupdate_input:hover {
    border: 1px dashed white;
  }
  .courseupdate_inputdetail {
    outline: none;
    background: transparent;
    border: 1px dashed transparent;
  }
  .courseupdate_inputdetail:hover {
    border: 1px dashed gray;
  }
  .courseupdate_optionbox {
    //max-width: 180px !important;
    //margin: 0 5px;
    //background: transparent !important;
    //color: white !important;
    //fill: white !important;
    //background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23ffffff'><polygon points='0,0 100,0 50,50'/></svg>")
    //  no-repeat !important;
    //background-size: 16px !important;
    //background-position: calc(100% - 20px) 17px !important;
    //font-size: var(--font-size-normal) !important;
  }

  select option {
    background: #2d2f31 !important;
  }
  .selected-option {
    background-color: #2d2f31 !important;
  }

  .courseupdate_switch {
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
  }
  .course_details {
    @media (max-width: 1220px) {
      width: 100% !important;
    }
  }
  .infor_user {
    @media (max-width: 1220px) {
      width: 100% !important;
    }
  }
  .ck-editor__editable_inline {
    max-height: 400px;
    min-height: 400px;
  }
}
