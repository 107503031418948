.avatar-detail {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  margin-right: 4px;
}

.header-challenge {
  font: arial;
  font-size: 14px;
}
.challenge__avatar {
  height: 350px;
  img {
    height: 350px;
    overflow: hidden;
    object-fit: cover;
  }
}

.challenge__button__header {
  height: 40px;
}

.challenge__detail_challenge {
}

.challenge__gift__avatar {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}
