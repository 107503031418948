#extension_wrap {
    background-color: #eee;

    #hero_banner{
        height: 300px;
        background-image: url('../images/banner.png') ;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        background-attachment: local;
    }
    .mask {
        background-color: rgba(0, 0, 0, 0.2);
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .extension_meta_block {
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding: 15px;

        .a {
            padding: 5px;
            width: 25%;
            text-align: center;

            &:last-child {
                border: none;
            }

            .description {
                font-weight: bold;
                color: #000
            }
        }
    }


    .extension_element {
        overflow: auto;
        justify-content: flex-start;
        gap: 10px;
        .extension_thumbnail {
            overflow: auto;
            min-width: 100px;
            width: 100px;
            height: 100px;
        }
        .extension_thumbnail:hover{
          cursor: pointer;
        }
        .extension_meta_in_list {
            font-size: 14px;
            line-height: 1.4;
            .e_title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 5px;
              &:hover{
                color: #6366f1;
                cursor: pointer;
                z-index: 1;
              }
            }
        }
    }

  .pricing-card{
      height: 100%;
      margin-top: 32px;
      margin-bottom: 24px;
      background-color: #fff;
      border-radius: 10px;
      border: solid 1px #cccccc;;
      overflow: hidden;
      box-shadow: 2px 2px 2px  #cccccc;
    }
    .title-wrap{
      display: flex;
      .free-trial{
        font-size: 20px;
        color:#14522d;
        background: #ebffe9;
        padding: 0 10px;
        margin-left: 10px;
        border: solid 1px #81C784;
        border-radius: 15px;
      }
    }
  }
.extension_lable{
  text-align: center;
  padding: 0 10px;
  margin: 5px 0;
  border-radius: 20px;
  color: var(--color-theme-primary);
  background-color: var(--color-theme-primary-light) ;
}

#extension_body {
	// background-color: #fff;
	// height: 100vh;
	// border: 1px solid #ccc;
	margin-top: 1em;
    overflow: hidden;
    padding: 15px;

}
.extension_card_media {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  padding: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  background: white;
  align-items: center;
  border: 1px solid #fff;
  object-fit: cover;
  .reponsive_video{
    width: 75%;
  }
}
.logo_image {
  max-height: 400px;
  //object-fit: contain;
  border: 0 solid #000;
  height: 130px;
  object-fit: cover;
}
.logo_video {
  max-height: 400px;
  object-fit: contain;
  border: 0 solid #000;
}
.image-default{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 22px;
}
.extension_title{
  font-size: 24px !important;
}
@media screen and  (max-width: 1400px) and (min-width: 1200px) {
  #extension_wrap{
    #hero_banner{
      height: 250px;
      background-image: url('../images/banner2.png') ;
    }
  }
  .extension_title{
    font-size: 22px !important;
  }
  .extension_lable{
    font-size: 14px;
  }
  .title {
    font-size: 14px;
  }
  .extension_btn{
    font-size: 14px;
  }

}

@media screen and  (max-width: 1200px) {
  #extension_wrap{
    #hero_banner{
      height: 250px;
      background-image: url('../images/banner2.png') ;
    }
  }
  .extension_card_media{
    display: flex;
    flex-direction: column;
    .reponsive_video{
      position: relative;
      width: 100%;
    }
    .reponsive_image{
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }
  .extension_content{
    display: flex;
    flex-direction: column;
    .extension_item{
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .extension_container{
    display: grid;

  }
  .extension-text{
    font-size: 15px;
    .extension_meta_block {
      font-size: 12px;
      padding: 15px 0 !important;
   }
  }
}

@media screen and (max-width: 420px) {
  #extension_wrap{
    #hero_banner{
      height: 200px;
      background-image: url('../images/banner3.png') ;
    }
  }
  .extension-sub-text{
    font-size: 15px !important;
  }
  .extension-text-header{
    font-size: 25px !important;
  }
}





















































