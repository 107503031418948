input[type='range'] {
  background: var(--color-theme-primary);
  border: solid 1px var(--color-theme-primary);
  border-radius: 8px;
  height: 2px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  appearance: none;
  &::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: #000;
    cursor: unset;
  }
}
