.chatInformation_container {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.chatInformation_header_banner {
  width: 100%;
}

.chatInformation_header_banner > img {
  width: 100%;
  height: 187px;
  object-fit: cover;
}

.chatInformation_header_banner_button_close {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 16px;
  right: 16px;
  display: grid;
  place-items: center;
}

.chatInformation_header_banner_button_close:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.chatInformation_header_info_client_body {
  height: 100%;
}

.chatInformation_header_info_client {
  display: grid;
  margin-top: -50px;
}

.chatInformation_header_info_client_avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.chatInformation_header_info_client_avatar > img {
  border-radius: 50%;
  border: 3px solid #ffffff;
  object-fit: cover;
}

.chatInformation_header_info_client_name {
  display: grid;
  padding: 0 24px;
  row-gap: 16px;
}

#chatInformation .chatInformation_header_info_client_name_title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.chatInformation_header_info_client_button {
  height: 44px;
}

.chatInformation_header_info_client_button > button {
  width: 100%;
  border: none;
  height: 100%;
  outline: none;
  border-radius: 12px;
  padding: 0 16px;
  color: #0e1426;
  font-weight: 500;
}

.chatInformation_header_info_client_notification {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 16px;
  padding: 10px 0;
}

.chatInformation_header_info_client_line {
  margin: 12px 0;
  background: #e0e2e6;
}

.chatInformation_header_info_client_file_item {
  cursor: pointer;
  display: grid;
  column-gap: 16px;
  grid-template-columns: auto 1fr;
  background: #ffffff;
  padding: 10px 24px;
}

.chatInformation_header_info_client_file_item:hover {
  border-radius: 12px;
  background: #e0e2e6;
}

.disabled {
  background: #e0e2e6;
  cursor: default;
}

.disabled:hover {
  border-radius: unset;
  background: #e0e2e6;
}

//
.chatInformation_container_media {
  width: 100%;
  position: absolute;
  right: -500px;
  top: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.show_media {
  left: 0;
  right: unset;
}

.chatInformation_header_media {
  display: grid;
  grid-template-columns: 44px 1fr;
  column-gap: 16px;
  height: 77px;
  justify-content: flex-start;
  padding: 8px 24px;
  align-items: center;
}

.chatInformation_header_media img {
  display: grid;
  place-items: center;
}

.chatInformation_body_media {
  padding: 0 24px 24px 24px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
}

.chatInformation_tab_media {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  border-bottom: 0.5px solid #e0e2e6;
  align-items: center;
  justify-content: center;
}

.chatInformation_tab_media_item {
  padding: 10px;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.chat_active_tab {
  color: #dc3545;
  border-bottom: 3px solid #dc3545;
}

.chatInformation_media_image {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 5px;
  row-gap: 5px;
}

.chatInformation_media_image.media_document_and_link {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.chatInformation_media_image_item {
  width: auto;
  height: 100px;
}

.chatInformation_media_image_item img,
.chatInformation_media_image_item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
