#event-add {
  select option {
    background: #2d2f31 !important;
  }
  .selected-option {
    background-color: #2d2f31 !important;
  }

  .fc-col-header-cell-cushion {
    color: rgb(51, 47, 47);
    text-decoration: none;
  }

  .fc-daygrid-day-number {
    color: rgb(32, 27, 27);
    text-decoration: none;
  }

  .fc-day-today {
    .fc-daygrid-day-number {
      color: var(--color-theme-primary);
      font-weight: bold;
    }
  }

  .fc-event:hover {
    opacity: .8;
    cursor: pointer;
  }

  .fc-toolbar-title {
    text-transform: uppercase;
  }

  .fc {
    .fc-popover {
      z-index: 999;
    }
  }

  .file-drop-area {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    border: 1px dashed rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    transition: 0.2s;
  }

  .form-upload-image-event{
    height: 22em !important;
  }

  .form-upload-image-livestream{
    height: 20em !important;
  }

  .choose-file-button {
    padding: 0 !important;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin-right: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }

  .file-message {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 4;
  }

  .choose_file_image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
  }

  .fit-image {
    object-fit: cover;
    background-color: black;
  }

  .view-bottom-image {
    background: rgba(0, 0, 0, 0.569);
    color: white;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 0;
    position: absolute;
    z-index: 2;
  }

  .error-image {
    width: 100%;
    justify-content: center;
    text-align: center;
    background-color: white;
    position: absolute;
    z-index: 1;
  }
  .event-update-label{
    position: absolute;
    left: 40px;
    bottom: 20px;
  }

  .fc-event-title {
    color: #3788d8;
  }

  .fc-event-time {
    color: #3788d8;
  }

  :root {
    --fc-button-bg-color: var(--color-theme-primary);
    --fc-button-border-color: var(--color-theme-primary);
    --fc-button-hover-bg-color: var(--color-theme-primary-medium);
    --fc-button-hover-border-color: var(--color-theme-primary-medium);
    --fc-button-active-bg-color: var(--color-theme-primary);
    --fc-button-active-border-color: var(--color-theme-primary);
  }

  .fc .fc-button-primary:focus {
    box-shadow: rgb(231 127 133 / 50%) 0px 0px 0px 0.2rem;
  }

  .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: rgb(231 127 133 / 50%) 0px 0px 0px 0.2rem;
  }


  .form-select:focus{
    box-shadow: rgb(231 127 133 / 50%) 0px 0px 0px 0.2rem !important;
    border-color:  var(--color-theme-primary-medium) !important;
  }

  .form-control:focus {
    box-shadow: rgb(231 127 133 / 50%) 0px 0px 0px 0.2rem !important;
    border-color:  var(--color-theme-primary-medium) !important;
  }

  .form-check-input:checked {
    background-color:var(--color-theme-primary-medium) !important;
    border-color: var(--color-theme-primary-medium) !important;
  }

  .form-check-input:focus {
    box-shadow: rgb(231 127 133 / 50%) 0px 0px 0px 0.2rem !important;
    border-color:  var(--color-theme-primary-medium) !important;
  }

  .css-t3ipsp-control{
    box-shadow: rgb(231 127 133 / 50%) 0px 0px 0px 0.2rem !important;
    border-color:  var(--color-theme-primary-medium) !important;
  }

  .eventupdate_livestream_container{
    background-color: var(--color-theme-primary-light-white);
    padding: 1em;
    border-radius: 0.5em;
  }

  /*
  Mobile
  */
  @media only screen and (max-width: 900px) {
    .fc-toolbar-title {
      font-size: 1.35em !important
    }
  }

  @media only screen and (max-width: 768px) {
    #long_calendar {
      .fc-toolbar-title {
        font-size: 16px !important
      }

      .fc-addEvent-button {
        font-size: 10px;
      }

      .fc-todayButton-button {
        font-size: 10px;
      }

      .fc-button-group {
        font-size: 10px;
      }

      .fc-event-title {
        font-size: 10px;
      }

      .fc-col-header-cell-cushion {
        color: black;
      }

      .fc-daygrid-more-link {
        font-size: 10px;
      }

      .fc-daygrid-day-number {
        font-size: 12px;
      }

      .fc-col-header-cell-cushion {
        font-size: 12px;
      }
    }
  }

}

