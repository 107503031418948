  .giftdetailmodal_image {
    width: 100%;
    height:100%;
    object-fit: cover;
    border-radius: var(--bs-modal-border-radius) 0 0 var(--bs-modal-border-radius);
  }

  .giftdetailmodal_closebtn{
    position: absolute;
    top:0;
    right:0.5em;
    background: transparent !important;
    border: 0 !important;
    font-size: var(--font-size-h4);
  }

  .giftmodal_detail{
    &.modal .modal-dialog{
      max-width: 80%;
    }
  }


  .giftdetailmodal_container{
    overflow-y: hidden;
  }

  .giftdetailmodal_30{
    width: 35%;
    height:36em;
  }

  .giftdetailmodal_40{
    overflow-y: scroll;
    width: 38%;

    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }

  .giftdetailmodal_30r{
    display: flex;
    flex-direction: column;
    width: 27%;
    padding: 1.5em;
    overflow-y: visible;
    height:36em;
  }

  @media only screen and (max-width: 1199px) {
    .giftmodal_detail{
      &.modal .modal-dialog{
        max-width: 90%;
      }
    }

    .giftdetailmodal_30{
      width: 50%;
      height:36em;
    }

    .giftdetailmodal_40{
      width: 50%;
      overflow-y: scroll;
      height: auto !important;

      /* Tùy chỉnh kiểu thanh scrollbar */
      &::-webkit-scrollbar {
        width: 2px; /* Chiều rộng thanh scrollbar dọc */
      }

      /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
      &::-webkit-scrollbar-thumb {
        background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
        border-radius: 12px; /* Độ cong của thanh scrollbar */
      }

      /* Tùy chỉnh kiểu của "dải" (track) */
      &::-webkit-scrollbar-track {
        background-color: transparent; /* Màu nền của dải scrollbar */
      }
    }

    .giftdetailmodal_30r{
      width: 0;
      padding: 0 !important;
      display: none;
    }
  }

  @media only screen and (max-width: 576px) {
    .giftdetailmodal_container{
      overflow-y: scroll;
    }

    .giftmodal_detail{
      &.modal .modal-dialog{
        max-width: 100%;
      }
    }

    .giftdetailmodal_30{
      width: 100%;
      height:36em;
    }

    .giftdetailmodal_40{
      width: 100%;
      overflow-y: visible;
      height: auto !important;

      /* Tùy chỉnh kiểu thanh scrollbar */
      &::-webkit-scrollbar {
        width: 2px; /* Chiều rộng thanh scrollbar dọc */
      }

      /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
      &::-webkit-scrollbar-thumb {
        background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
        border-radius: 12px; /* Độ cong của thanh scrollbar */
      }

      /* Tùy chỉnh kiểu của "dải" (track) */
      &::-webkit-scrollbar-track {
        background-color: transparent; /* Màu nền của dải scrollbar */
      }
    }

    .giftdetailmodal_30r{
      width: 0;
      padding: 0 !important;
      display: none;
    }
  }

  .giftdetailmodal_body {
    padding:0 !important;
    height:36em;
  }
  .giftdetailmodal_container_user{
    background: var(--global-body-bgcolor);
    padding: 0.5em;
    border-radius: var(--border-radius-10);
    display: flex;
    align-items: center;
    margin: 0.3em 0;
    width: 100%;
  }
  .giftdetailmodal_iconuser{
    color: var(--global-font-color);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    margin-right: 0.3em;
  }
  .giftdetailmodal_txtnameuser{
    flex:1;
    color: var(--color-theme-secondary);
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-medium);
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
  .giftdetailmodal_txtinfouser{
    color: var(--global-font-color);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
  }
  .giftdetailmodal_avatar{
    width: 3em;
    height: 3em;
    border-radius: 3em;
    object-fit: cover;
  }
  .giftdetailmodal_title_received {
    overflow: hidden;

    color: var(--global-font-title);
    padding-bottom: 0.5em;
    font-size: var(--font-size-h6);
    //line-height: 1.5rem;
    font-weight: var(--font-weight-medium);
  }
  .giftdetailmodal_listuser_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1;

    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }
  .giftdetailmodal_listuser_header{
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .giftdetailmodal_title {
    overflow: hidden;

    color: var(--global-font-title);

    font-size: var(--font-size-h4);
    //line-height: 1.5rem;
    font-weight: var(--font-weight-semi-bold);
  }
  .giftdetailmodal_price {
    color: var(--color-theme-primary-medium);
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-semi-bold);
  }
  .giftdetailmodal_received {
    color: var(--color-theme-success);
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-semi-bold);
  }
  .giftdetailmodal_condition_content{
    padding-bottom: 0.5em;
    margin: 1em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    border-top: 1px solid rgba(0, 0, 0, 0.1);;
  }
  .giftdetailmodal_condition {
    margin-top: 1em;
    color: var(--color-theme-secondary);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-medium);
  }
  .giftdetailmodal_condition_title{
    color: var(--global-font-color);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-medium);
    margin-right: 0.5em;
  }
  .giftdetailmodal_condition_value{
    color: var(--color-theme-primary);
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-bold);
  }
  .giftdetailmodal_txtdes{
    color: var(--global-font-color);
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-medium);

    margin-top: 5px;
    //margin-bottom: 100px;
    text-align: justify;
    //min-height: 100px;
    resize: none;
    overflow-y: hidden;

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }

    th, td {
      padding: 16px;
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
    }

    th:first-child, td:first-child {
      border-left: 1px solid #e0e0e0;
    }

    th {
      background-color: #f8f8f8;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #fff8f8;
    }
  }
