#course-list {
  .course_txtnumofcourse {
    font-size: var(--font-size-normal);
    color: var(--global-font-color);
    font-weight: var(--font-weight-medium);
  }
  .course_container_search {
    & input {
      outline: none !important;
      box-shadow: none !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 0;
      padding: 0 !important;
      margin: 0 0 0 0.5em !important;
    }

    & input:focus {
      outline: none !important;
      box-shadow: none !important;
      width: 13em;
      border-bottom: 1px solid var(--color-theme-primary) !important;
      border-radius: 0;
      padding: 0 !important;
      margin: 0 0 0 0.5em !important;
    }
  }
  .course_numofcourse {
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
  }
  .course_txtcourse {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .course_header_list {
    margin-bottom: 2em;
    padding: 1em;
    background: var(--color-theme-white-box);
    border-radius: var(--border-radius-box);
    -webkit-box-shadow: var(--global-box-shadow);
    box-shadow: var(--global-box-shadow);
    border: 1px solid #f0f0f0;
  }

  .course_btn_add {
    background: var(--color-theme-primary-medium) !important;
    color: var(--color-theme-white) !important;
    &:hover {
      background: var(--color-theme-primary) !important;
    }
  }

  .search-box .search-input {
    padding-left: 2.5rem;
    padding-right: 2rem;
    border-radius: 0.375rem;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .search-box .search-box-icon {
    position: absolute;
    color: var(--phoenix-gray-500);
    top: 50%;
    left: 1rem;
    -webkit-transform: translateY(-48%);
    -ms-transform: translateY(-48%);
    transform: translateY(-48%);
  }
  svg:not(:host).svg-inline--fa,
  svg:not(:root).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
  }

  .svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
  .course_main_wrap .breadcrumb,
  .course_main_wrap .breadcrumb a,
  .course_main_wrap .breadcrumb .breadcrumb-item,
  .course_main_wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: #a51c30;
  }
  .course_main_wrap .breadcrumb-item.active {
    color: #000;
  }
}
