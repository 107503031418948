.memberPage_container {
  display: grid;
  place-items: center;
}

.memberPage_wrapper {
  display: grid;
  row-gap: 20px;
  width: 100%;
}

.memberPage_body {
  display: grid;
  row-gap: 20px;
}

.memberPage_body_wrapper {
  display: grid;
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  row-gap: 20px;
  width: 100%;
}

.memberPage_header {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
}

.memberPage_header_tab_list {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 10px;
  justify-content: center;
}

.memberPage_header_tab_item {
  padding: 8px 10px;
  font-size: var(--bs-nav-link-font-size);
  color: unset;
  cursor: pointer;
  text-decoration: unset;
}

.memberPage_header_tab_item:hover {
  color: var(--color-theme-primary);
}

.memberPage_header_tab_item.active {
  font-weight: 600;
  color: var(--gamifa--preset--color--black);
  border-bottom: 1px solid var(--color-theme-primary);
}

.memberPage_header_button_wrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  column-gap: 10px;
}

.memberPage_header_button_invite {
  min-width: 60px;
}

.memberPage_header_button_search {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d3d3d3;
  color: #fff;
}

.memberPage_header_user {
  display: grid;
  row-gap: 10px;
}

.memberPage_header_user_desc {
  font-size: 15px;
}

.memberPage_header_user_icon {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.memberPage_footer_button {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  width: 100%;
  padding: 8px 16px;
  border-radius: 6px;
}

.memberPage_footer_button_title {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 578px) {
  .memberPage_header_tab_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
