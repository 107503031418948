.coursesIncome__container {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  padding: 28px;
}

.coursesIncome__first {
  width: 100%;
  //height: 100%;
  background-color: #a51c30;
  color: #fff;
  border-radius: 6px;
  padding: 21px 28px;
}

.courseIncome__first--body {
  display: flex;
  flex-direction: column;
}

.coursesIncome__first--revenue {
  font-size: 18px;
}

.coursesIncome__first--statistical {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
}

.coursesIncome__first--title {
  font-size: 14px;
}

.coursesIncome__first--body {
  display: flex;
  flex-direction: column;
}

.coursesIncome__first--value {
  font-size: 18px;
  font-weight: 600;
  height: 24px;
}

.coursesIncome__first--subTitle {
  font-size: 12px;
  margin-top: 2px;
}

.coursesIncome__second {
  display: grid;
  align-items: center;
  row-gap: 25px;
  margin: 48px 0 19px 0;
}

.courseIncome__tabs {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba(#a0a0a0, 0.25);
}

.courseIncome__item {
  display: flex;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  color: #000;
  font-weight: 400;
  position: relative;
}

.courseIncome__item:hover {
  color: #a51c30;
}

.courseIncome__item.activeTab {
  color: #a51c30;
  font-weight: 500;
}

.courseIncome__item.activeTab::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 1px solid #a51c30;
}

.courseIncome__filter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  justify-content: center;
  align-items: center;
}

.courseIncome__filter--time,
.courseIncome__filter--product {
  max-width: 250px;
  min-width: 200px;
  height: 34px;
  padding: 0 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
  background-color: rgba(#d9d9d9, 0.25);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}

.courseIncome__filter--time:hover,
.courseIncome__filter--product:hover {
  background-color: rgba(#d9d9d9, 0.8);
}

.courseIncome__filter--time > img,
.courseIncome__filter--product > img {
  width: 15px;
  height: 15px;
  object-fit: cover;
}

.courseIncome__filter--title {
  font-size: 14px;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: normal;
}

.courseIncome__filterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coursesIncome__search {
  position: relative;
}

.coursesIncome__search > img {
  position: absolute;
  right: 12px;
  bottom: 4px;
}

.coursesIncome__search--input {
  outline: unset;
  background-color: rgba(#d9d9d9, 0.25);
  border: none;
  border-radius: 6px;
  padding: 3px 12px 3px 40px;
  color: #000;
  width: 202px;
  font-size: 15px;
}

.coursesIncome__history {
  display: flex;
  align-items: center;
  font-size: 24px;
  height: 29px;
  color: #000;
  font-weight: 600;
}

.coursesIncome__third--pagination,
.coursesIncome__third--paginationMentor {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.coursesIncome__third--pagination .active > .page-link,
.page-link.active {
  background-color: #a51c30;
  border-color: #a51c30;
}

.coursesIncome__third--paginationMentor .active > .page-link,
.page-link.active {
  background-color: var(--color-theme-secondary);
  border-color: var(--color-theme-secondary);
}

.coursesIncome__third--pagination .pagination {
  margin-bottom: unset;
}

@media screen and (max-width: 1200px) {
  .coursesIncome__first--statistical {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

  .coursesIncome__first--wrapper.statisticalCoursesMobile {
    display: none;
  }

  .coursesIncome__first--subTitle {
    font-size: 10px;
  }

  .courseIncome__filterWrapper {
    display: grid;
    row-gap: 10px;
  }

  .courseIncome__filter {
    grid-row-start: 2;
    display: grid;
    grid-column: span 2;
    grid-template-columns: auto auto;
    justify-content: flex-start;
  }

  .coursesIncome__search {
    justify-content: flex-end;
    grid-column-start: 2;
  }
}

@media screen and (max-width: 480px) {
  .courseIncome__filter {
    grid-template-columns: 1fr;
    row-gap: 10px;
  }

  .courseIncome__filter--time,
  .courseIncome__filter--product {
    max-width: 100%;
    min-width: 100%;
  }
}
