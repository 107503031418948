.detailPayment__container {
  display: grid;
}

.detailPayment__second {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 24px;
}

.detailPayment__container--buttonWithdraw {
  font-size: 16px;
  width: 100px;
}

@media screen and (max-width: 1200px) {
  .detailPayment__second {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
