.userProfileSetting_container {
  display: grid;
  row-gap: 20px;
}

.userProfileSetting_label {
  font-size: 20px;
  font-weight: 600;
}

.userProfileSetting_image {
  border-radius: 50%;
  object-fit: cover;
}

.userProfileSetting_image_user {
  display: grid;
  column-gap: 10px;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
}

.userProfileSetting_edit_image {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid var(--color-theme-primary);
  border-radius: 6px;
  color: var(--color-theme-primary);
}

.userProfileSetting_edit_image:hover {
  background-color: var(--gamifa--preset--color--custom-sun);
}

.userProfileSetting_body,
.userProfileSetting_footer_body {
  display: grid;
  row-gap: 20px;
  margin-top: 10px;
}

.userProfileSetting_body_item {
  position: relative;
}

.userCountryPhone_item {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, auto));
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}

.phoneNumber_wrapper {
  display: grid;
  grid-template-columns: 80px 1fr;
  column-gap: 10px;
  grid-template-rows: 45px;
}

.userProfileSetting_body_item_input {
  min-height: 45px;
  font-size: 14px;
  height: auto;
}

.userProfileSetting_body_item_input.validate_failed {
  border-color: var(--bs-form-invalid-border-color) !important;
}

.userProfileSetting_body_item_label.validate_failed {
  top: 30%;
  color: var(--bs-form-invalid-border-color);
}

.userProfileSetting_body_item_textArea {
  min-height: 45px;
  height: auto;
  overflow: hidden;
}

.userCountryPhone_button_icon {
  transition: 0.25s ease;
  height: 30px;
}

.userCountryPhone_button_icon.active {
  transform: rotate(180deg);
}

.userProfileSetting_body_item_label {
  position: absolute;
  font-size: 12px;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  user-select: none;
  background-color: #fff;
  padding: 0 4px;
  transition: 0.25s ease;
  cursor: text;
}

.userProfileSetting_body_item_input:focus + .userProfileSetting_body_item_label,
.userProfileSetting_body_item_input:not(:placeholder-shown) + .userProfileSetting_body_item_label {
  top: 0px;
  left: 20px;
}

.userProfileSetting_body_item_textArea:focus + .userProfileSetting_body_item_label,
.userProfileSetting_body_item_textArea:not(:placeholder-shown)
  + .userProfileSetting_body_item_label {
  top: 0px;
  left: 20px;
}

.userProfileSetting_footer_check {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  column-gap: 10px;
  align-items: center;
}

.userProfileSetting_footer_check_active {
  transform: rotate(180deg);
}

.userProfileSetting_footer_check div:first-child {
  margin-top: 5px;
}

.userProfileSetting_footer_check span {
  font-weight: 600;
}

.userProfileSetting_button_save {
  display: flex;
  justify-content: flex-end;
}
