.giftreceiveraddmodal {
  &.modal .modal-dialog {
    max-width: 40%;
  }
}

.giftreceiveraddmodal_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--bs-modal-border-radius) 0 0 var(--bs-modal-border-radius);
}

.giftreceiveraddmodal_closebtn {
  position: absolute;
  top: 0;
  right: 0.5em;
  background: transparent !important;
  border: 0 !important;
  font-size: var(--font-size-h4);
}

.giftreceiveraddmodal_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2em;
}

.giftreceiveraddmodal_30 {
  width: 35%;
  height: 36em;
}

.giftreceiveraddmodal_40 {
  overflow-y: scroll;
  width: 38%;

  /* Tùy chỉnh kiểu thanh scrollbar */
  &::-webkit-scrollbar {
    width: 2px; /* Chiều rộng thanh scrollbar dọc */
  }

  /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
  &::-webkit-scrollbar-thumb {
    background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
    border-radius: 12px; /* Độ cong của thanh scrollbar */
  }

  /* Tùy chỉnh kiểu của "dải" (track) */
  &::-webkit-scrollbar-track {
    background-color: transparent; /* Màu nền của dải scrollbar */
  }
}

.giftreceiveraddmodal_30r {
  display: flex;
  flex-direction: column;
  width: 27%;
  padding: 1.5em;
  overflow-y: visible;
  height: 36em;
}

@media only screen and (max-width: 1199px) {
  .giftreceiveraddmodal {
    &.modal .modal-dialog {
      max-width: 40%;
    }
  }


  .giftreceiveraddmodal_30 {
    width: 50%;
    height: 36em;
  }

  .giftreceiveraddmodal_40 {
    width: 50%;
    overflow-y: scroll;
    height: auto !important;

    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }

  .giftreceiveraddmodal_30r {
    width: 0;
    padding: 0 !important;
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .giftreceiveraddmodal_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2em;
  }

  .giftreceiveraddmodal {
    &.modal .modal-dialog {
      max-width: 100%;
    }
  }

  .giftreceiveraddmodal_30 {
    width: 100%;
    height: 36em;
  }

  .giftreceiveraddmodal_40 {
    width: 100%;
    overflow-y: visible;
    height: auto !important;

    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }

  .giftreceiveraddmodal_30r {
    width: 0;
    padding: 0 !important;
    display: none;
  }
}

.giftreceiveraddmodal_body {
  padding: 0 !important;
  height: 50em;
}

.giftreceiveraddmodal_container_user {
  background: var(--global-body-bgcolor);
  padding: 0.5em;
  border-radius: var(--border-radius-10);
  display: flex;
  align-items: center;
  margin: 0.3em 0;
  width: 100%;
}

.giftreceiveraddmodal_iconuser {
  color: var(--global-font-color);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  margin-right: 0.3em;
}

.giftreceiveraddmodal_txtnameuser {
  flex: 1;
  color: var(--color-theme-secondary);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-medium);

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.giftreceiveraddmodal_txtinfouser {
  color: var(--global-font-color);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
}

.giftreceiveraddmodal_avatar {
  width: 3em;
  height: 3em;
  border-radius: 3em;
  object-fit: cover;
}

.giftreceiveraddmodal_title_received {
  overflow: hidden;

  color: var(--global-font-title);
  padding-bottom: 0.5em;
  font-size: var(--font-size-h6);
  //line-height: 1.5rem;
  font-weight: var(--font-weight-medium);
}

.giftreceiveraddmodal_listuser_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;

  /* Tùy chỉnh kiểu thanh scrollbar */
  &::-webkit-scrollbar {
    width: 2px; /* Chiều rộng thanh scrollbar dọc */
  }

  /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
  &::-webkit-scrollbar-thumb {
    background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
    border-radius: 12px; /* Độ cong của thanh scrollbar */
  }

  /* Tùy chỉnh kiểu của "dải" (track) */
  &::-webkit-scrollbar-track {
    background-color: transparent; /* Màu nền của dải scrollbar */
  }
}

.giftreceiveraddmodal_listuser_header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.giftreceiveraddmodal_title {
  overflow: hidden;

  color: var(--global-font-title);

  font-size: var(--font-size-h4);
  //line-height: 1.5rem;
  font-weight: var(--font-weight-semi-bold);
}

.giftreceiveraddmodal_price {
  color: var(--color-theme-primary-medium);
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-semi-bold);
}

.giftreceiveraddmodal_received {
  color: var(--color-theme-success);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-semi-bold);
}

.giftreceiveraddmodal_condition_content {
  padding-bottom: 0.5em;
  margin: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
  border-top: 1px solid rgba(0, 0, 0, 0.1);;
}

.giftreceiveraddmodal_condition {
  margin-top: 1em;
  color: var(--color-theme-secondary);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-medium);
}

.giftreceiveraddmodal_condition_title {
  color: var(--global-font-color);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-medium);
  margin-right: 0.5em;
}

.giftreceiveraddmodal_condition_value {
  color: var(--color-theme-primary);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-bold);
}

.giftreceiveraddmodal_txtdes {
  color: var(--global-font-color);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-medium);

  margin-top: 5px;
  //margin-bottom: 100px;
  text-align: justify;
  //min-height: 100px;
  resize: none;
  overflow-y: hidden;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  th, td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }

  th:first-child, td:first-child {
    border-left: 1px solid #e0e0e0;
  }

  th {
    background-color: #f8f8f8;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #fff8f8;
  }
}

.giftreceiveraddmodal_itemcontainer {
  margin: 1em 0;
}

.giftreceiveraddmodal_item {
  display: grid;
  grid-template-columns: 1fr auto;
  background: var(--global-body-bgcolor);
  padding: 1em;
  border-radius: var(--border-radius-box);
  align-items: center;
}

.giftreceiveraddmodal_imgavatar {
  width: 4em;
  height: 4em;
  border-radius: 4em;
  object-fit: cover;
}

.giftreceiveraddmodal_info {
  margin: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
}

.giftreceiveraddmodal_container_user_txtlvl {
  color: var(--global-font-color);
  font-size: var(--font-size-normal);
}

.giftreceiveraddmodal_name {
  color: var(--global-font-title);
  font-size: var(--font-size-h5);
}

.giftreceiveraddmodal_btn_add {
  background: rgba(241, 223, 223, 0.6) !important;
  color: var(--color-theme-primary) !important;
  font-size: var(--font-size-normal) !important;
  font-weight: var(--font-weight-medium) !important;

  &:hover {
    background: var(--color-theme-primary) !important;
    color: var(--color-theme-white) !important;
  }
}

.giftreceiveraddmodal_btn_close {
  background: var(--color-theme-primary-medium) !important;
  color: var(--color-theme-white) !important;
  font-size: var(--font-size-normal) !important;

  &:hover {
    background: var(--color-theme-primary) !important;
  }
}

.gift_scroll_bar{
  /* Tùy chỉnh kiểu thanh scrollbar */
  &::-webkit-scrollbar {
    width: 2px; /* Chiều rộng thanh scrollbar dọc */
  }

  /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
  &::-webkit-scrollbar-thumb {
    background-color: var(--border-color-light-opacity); /* Màu nền của thanh scrollbar */
    border-radius: 12px; /* Độ cong của thanh scrollbar */
  }

  /* Tùy chỉnh kiểu của "dải" (track) */
  &::-webkit-scrollbar-track {
    background-color: transparent; /* Màu nền của dải scrollbar */
  }
}

@media only screen and (max-width: 768px) {
  .giftreceiveraddmodal_item {
    display: flex;
    flex-direction: column;
    background: var(--global-body-bgcolor);
    padding: 1em;
    border-radius: var(--border-radius-box);
    align-items: center;
  }
}
