#course-update-module{
  .courseupdatemodules_txtcontent {
    //margin-top: 2em;
    font-size: 1.1em;
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-semi-bold);
    //margin-bottom: 1em;
  }
  .courseupdatemodules_txtempty {
    font-size: 18px;
    margin-top: 25px;
    color: gray;
    font-weight: 600;
  }
  .courseupdatemodules_viewmodule{
    width: 100%;
    //background: white;
    padding: 15px 25px;
    margin-bottom: 100px;
  }
  .courseupdatemodules_btnview{
    font-weight: var(--font-weight-semi-bold);
    background: var(--color-theme-primary-light) !important;
    color: var(--color-theme-primary) !important;
    &:hover{
      background: var(--color-theme-primary) !important;
      color: var(--color-theme-white) !important;
    }
  }
  .courseupdatemodules_btnback {
    font-size: 16px;
    color: var(--color-theme-white) !important;
    font-weight: 400;
    display: flex !important;
    align-items: center !important;
    background: var(--color-theme-secondary) !important;
    border: 0 !important;
  }
  .courseupdatemodules_viewmodule .breadcrumb,
  .courseupdatemodules_viewmodule .breadcrumb a,
  .courseupdatemodules_viewmodule .breadcrumb .breadcrumb-item,
  .courseupdatemodules_viewmodule .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-medium);
    font-size: inherit;
  }
  .courseupdatemodules_viewmodule .breadcrumb-item.active {
    color: var(--global-font-color);
  }

}
