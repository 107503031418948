.courseupdatemoduleitem {
  border-top: 0.5px solid #d1d7dc;
  border-right: 0.5px solid #d1d7dc;
  border-left: 0.5px solid #d1d7dc;
  //padding: 12px;
}
.courseupdatemoduleitem.last_module {
  border-bottom: 0.5px solid #d1d7dc;
}

.courseupdatemoduleitem_angleicon {
  margin-right: 15px;
  width:14px;
  height: 14px;
  transition: transform 0.3s;
  color: var(--global-font-color);
}

.courseupdatemoduleitem_title {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}
.courseupdatemoduleitem_title.empty_module {
  color: gray;
  //font-weight: 400;
  //font-size: 18px;
}

.courseupdatemoduleitem_numModule {
  color: #000;
  font-weight: 400;
  font-size: 14px;
}

.courseupdatemoduleitem_titlemodule {
  color: #000;
  font-weight: 400;
  font-size: 15px;
}

.courseupdatemoduleitem_groupheader {
  background: #f7f9fa;
  padding: 12px;
}
.courseupdatemoduleitem_groupheader.not_empty {
  cursor: pointer;
}

.rotate_arrow_module {
  transform: rotate(180deg);
}

.courseupdatemoduleitem_titlemodule_createat {
  color: #000;
  font-weight: 400;
  font-size: 12px;
}
.courseupdatemoduleitem_inputnamemodule {
  background: transparent;
  padding: 5px 15px;
  margin: 0 12px 0 0;
  color: var(--global-font-title);
  font-weight: 600;
  font-size: 18px;
  flex: 1;
}
.courseupdatesubmoduleitem_inputnamemodule {
  background: transparent;
  padding: 5px 15px;
  margin: 0 12px;
  flex: 1;

  color: var(--global-font-title);
  font-weight: 400;
  font-size: 15px;
}
.courseupdatemoduleitem_input{
  outline: none;
  background: transparent;
  border: 1px dashed transparent;
}
.courseupdatemoduleitem_input:hover {
  border: 1px dashed gray;
}
.courseupdatemoduleitem_addbutton {
  margin-top: 5px;
  margin-bottom: 5px;
  background: #cb4859;
  border-radius: 5px;
  color: white;
  border: 0;
  padding: 7px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  visibility: hidden;
  opacity: 0;
  transition: transform 0.3s, visibility 0s linear 0.3s, opacity 0.3s;
  &.delete_btn{
    background: var(--color-theme-danger-light);
    color: var(--color-theme-danger);
  }
}
.courseupdatemoduleitem_addbutton.disable_btn {
  background: #c9c9c9;
}
.courseupdatemoduleitem_addbutton:hover {
  background: #a51c30;
  &.delete_btn{
    background: var(--color-theme-danger);
    color: var(--color-theme-white);
  }
}
.courseupdatesubmoduleitem_iconplus{
  margin-right: 12px;
}
.courseupdatesubmoduleitem_addbutton_init {
  margin-top: 5px;
  margin-bottom: 5px;
  background: var(--color-theme-primary-medium);
  border-radius: 5px;
  color: white;
  border: 0;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 12px;
  &:hover{
    background: var(--color-theme-primary);
  }
}
.courseupdatesubmoduleitem_addbutton_initmodule {
  margin-top: 5px;
  margin-bottom: 5px;
  background: var(--color-theme-primary-light);
  border-radius: 5px;
  color: var(--color-theme-primary);;
  border: 0;
  padding: 10px 15px;
  text-decoration: none;
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-bold);
  &:hover{
    background: var(--color-theme-primary);
    color: var(--color-theme-white)
  }
}
.courseupdatesubmoduleitem_addbutton {
  margin-top: 5px;
  margin-bottom: 5px;
  background: #cb4859;
  border-radius: 5px;
  color: white;
  border: 0;
  padding: 7px 15px;
  text-decoration: none;
  font-size: var(--font-size-small);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.3s, visibility 0s linear 0.3s, opacity 0.3s;
  &.delete_btn{
    background: var(--color-theme-danger-light);
    color: var(--color-theme-danger);
  }
}
.courseupdatesubmoduleitem_addbutton.disable_btn {
  background: #c9c9c9;
}
.courseupdatesubmoduleitem_addbutton:hover {
  background: #a51c30;
  &.delete_btn{
    background: var(--color-theme-danger);
    color: var(--color-theme-white);
  }
}
.courseupdate_file{
  width: max-content;
}
.courseupdate_file_name{

  .courseupdate_media_file_name{
    margin-left: 5px;
    font-size: 13px;
  }
}

.courseupdate_file:hover{
  background: none !important;
  opacity: 0.8 !important;
  color: var(----color-theme-primary) !important;
}

div.hover_to_show:hover>div>button.courseupdatesubmoduleitem_addbutton{
  visibility: visible;
  opacity: 1;
  transition: transform 0.3s, visibility 0s linear 0s, opacity 0.3s;
}
div.hover_to_show:hover>div>button.courseupdatemoduleitem_addbutton{
  visibility: visible;
  opacity: 1;
  transition: transform 0.3s, visibility 0s linear 0s, opacity 0.3s;
}
div.force_visible>div>button.courseupdatesubmoduleitem_addbutton{
  visibility: visible;
  opacity: 1;
}
div.force_visible>div>button.courseupdatemoduleitem_addbutton{
  visibility: visible;
  opacity: 1;
}
