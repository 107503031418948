.user_screen_cover_border {
  height: 280px;
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 16px 16px;

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.bottom_user {
  justify-content: flex-end;
  display: flex;
  margin-top: 20px;
  height: 30px;
}

.view_img_avatar_image {
  position: absolute;
  top: 270px;
  text-align: center;

  .img_avatar {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    margin-left: 50px;
    border-radius: 50%;
    border: 10px #fff solid;

    @media (max-width: 576px) {
      margin-left: -10px;
    }
  }
}

.post_user {
  margin-top: 60px;

  .view_info {
    background-color: white;
    padding: 20px;
    border-radius: 8px;

    .item_info {
      height: 20px;
      margin-top: 10px;
    }
  }

  .view_post {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
}
