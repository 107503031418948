#add_challenge {
  .add-header {
    height: 350px;
  }

  .cover-add {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 1;
    object-fit: cover;
    border-radius: 10px;
  }

  .file-input-cover {
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
  }

  .image-cover-add-container {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
  }

  .image-cover-add {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .button-choose-image-cover {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 30px;
    z-index: 3;
    top: 10px;
    right: 15px;
  }

  .main-content-add {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    z-index: 3;
  }

  .avatar-add {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100px;
    background-color: black;
  }

  .file-input-avatar {
    width: 200px;
    height: 200px;
    position: absolute;
    border-radius: 100px;
    z-index: 2;
    opacity: 0;
  }

  .image-avatar-add {
    width: 200px;
    height: 200px;
    position: absolute;
    border-radius: 100px;
    z-index: 1;
    object-fit: cover;
    background-color: black;
  }

  .label-add-header {
    color: white;
    margin-bottom: 0px;
  }

  .vertical-timeline-element {
    margin: 1em 0;
  }

  .vertical-timeline {
    padding: 0;
  }

  .vertical-timeline-element-title {
    color: #000;
  }

  .challenge-gift-item {
    border: 1px dashed var(--color-theme-primary);
    border-radius: 3px;
    transition: 0.2s;

    .title,
    .description {
      word-break: break-word;
    }
  }

  .image-challenge-gift {
    width: 52px;
    height: 52px;
    border-radius: 10px;
    object-fit: cover;
  }

  .status-active {
    background-color: #F3F4F5;
    border: none;
  }

  .ck-editor__editable_inline {
    max-height: 400px;
    min-height: 200px;
  }
}

#challenge_cookbook {
  .cookbook-description {
    line-height: 1.5rem;
    height: 48px;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
