.courselistitem {
  height: 410px;
  text-decoration: none;
  margin: 0 0 2em;
  padding: 0 1em;

  .courselistitem_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius) !important;
    transition: all 0.5s ease;
    transform: scale(1);
    max-width: unset !important;
  }

  .courselistitem_container_img {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 46%;
  }

  .courselistitem_img_layer_time {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    color: var(--color-theme-primary);
  }

  .courselistitem_img_status {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    color: var(--color-theme-success);
    &.ban {
      color: var(--color-theme-danger);
    }
  }

  .courselistitem_card_info_price {
    display: grid;
    grid-template-columns: auto repeat(1, minmax(0, 1fr));
    margin-bottom: 5px;
    column-gap: 10px;
  }

  .courselistitem_txt_layer_time {
    margin: 0;
    padding: 0;
    font-size: var(--font-size-normal);
    color: var(--global-font-color);
    font-weight: var(--font-weight-medium);
  }

  .courselistitem_txt_layer_public {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: var(--color-theme-info);
  }

  .courselistitem_container {
    height: 100%;
    width: 100%;
    background: var(--color-theme-white-box);
    border-radius: var(--border-radius);
    padding: 0.75em;

    border: 1px solid #f0f0f0;

    a {
      text-decoration: none;
    }
  }
  //.courselistitem_container:hover {
  //  -webkit-box-shadow: var(--global-box-shadow);
  //  box-shadow: var(--global-box-shadow);
  //}
  .courselistitem_container:hover .courselistitem_img {
    transform: scale(1.05);
  }

  .courselistitem_title {
    margin: 0.5em 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;

    color: var(--global-font-title);

    font-size: 1em;
    line-height: 1.5rem;
    font-weight: var(--font-weight-semi-bold);

    &:hover {
      color: var(--color-theme-primary);
    }
  }

  .courselistitem_description {
    color: black;
    font-size: 13px;
    font-weight: 400;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .courselistitem_container_info {
    //padding: 10px 15px 0 15px;
  }

  .courselistitem_bottom {
    height: 54%;
    //padding-bottom: 15px;
  }

  .courselistitem_txt_status.done {
    font-size: var(--font-size-normal);
    color: var(--color-theme-success);
  }

  .courselistitem_txt_status.ban {
    font-size: var(--font-size-normal);
    color: var(--color-theme-danger);
  }

  .courselistitem_txt_status.draf {
    font-size: var(--font-size-normal);
    color: var(--global-font-color);
  }

  .courselistitem_progress {
    margin: 0 5px;
    height: 4px;
  }

  .progress_bar_container {
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 100px;
    overflow: hidden;
    height: 10px;
  }

  .progress_bar {
    background-color: #3498db;
    height: 10px;
    border-radius: 100px 0 0 100px;
    width: 50%;
  }

  .courselistitem_txt_progress {
    font-size: var(--font-size-normal);
    color: var(--global-font-color);
    font-weight: var(--font-weight-medium);
  }
  .courselistitem_txt_progress.percent {
    color: #a51c30;
    font-weight: 700;
  }

  .courselistitem_container_status {
    height: 40px;
  }

  .courselistitem_container_status.courselistitem_btn {
    background: var(--color-theme-primary-light);
    font-size: var(--font-size-small);
    font-family: var(--highlight-font-family);
    letter-spacing: var(--letter-spacing-one);
    font-weight: var(--font-weight-semi-bold);
    line-height: var(--font-line-height-body);
    border-radius: var(--border-radius);
    padding: 0.813em 2em;
    border: 0.063em solid transparent;
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: var(--color-theme-primary);
  }
  .courselistitem_container_status.courselistitem_btn:hover {
    border-color: var(--color-theme-primary);
    background: var(--color-theme-primary);
    color: var(--color-theme-white);
  }
}
