.giftreceiveritem{
    background: var(--global-body-bgcolor);
    padding: 2em 0;
    border-radius: var(--border-radius-box);
    position: relative;

  .giftreceiveritem_container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .giftreceiveritem_iconmenu{
    width: 1.5em;
    height: 1.5em;
  }
  .giftreceiveritem_avatar{
    width: 5em;
    height: 5em;
    border-radius: 5em;
    object-fit: cover;
    margin: 0 1em 0 2em;
  }
  .giftreceiveritem_imgproduct{
    width: 5em;
    height: 5em;
    border-radius: 5em;
    object-fit: cover;
  }
  .giftreceiveritem_container_user{
    min-width: 48%;
    display: flex;
    align-items: center;
    width: max-content !important;
  }
  .giftreceiveritem_container_gift_table{
    min-width: 48%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column !important;
  }
  .giftreceiveritem_container_gift{
    min-width: 48%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .giftreceiveritem_container_icongift{
    width: 2%;
    height: 100%;
    color: var(--color-theme-primary)
  }
  .giftreceiveritem_container_user_info{
    flex:1;
    display: flex;
    flex-direction: column !important;
  }
  .giftreceiveritem_container_user_txtname{
    color: var(--global-font-title);
    font-size: var(--font-size-h5);
  }
  .giftreceiveritem_container_user_txtnamegift{
    color: var(--global-font-title);
    font-size: var(--font-size-h6);
  }
  .giftreceiveritem_container_user_txtlvl{
    color: var(--global-font-color);
    font-size: var(--font-size-normal);
  }
  .giftreceiveritem_container_user_txtPrice{
    color: var(--color-theme-primary);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    text-align: end;
  }
  .giftreceiveritem_iconuser{

  }
  .giftreceiveritem_container_user_txtTime{
    font-size: var(--font-size-small);
    color: var(--global-font-color);
    font-weight: var(--font-weight-medium);
    text-align: end;
  }
  .giftreceiveritem_container_user_txtStatus{
    align-self: end;
    align-items: end;
    font-size: var(--font-size-tiny);
    color: var(--color-theme-white);
    margin: 1px 0;
    padding: 1px 5px;
    border-radius: var(--border-radius-box);
    text-align: end;
    &.prepare{
      background: var(--color-theme-grey);
    }
    &.transport{
      background: var(--color-theme-info);
    }
    &.received{
      background: var(--color-theme-success);
    }
    &.cancel{
      background: var(--color-theme-danger);
    }
  }

  @media only screen and (max-width: 992px) {
    .giftreceiveritem_container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
    }
    .giftreceiveritem_container_user_txtlvl{
      color: var(--global-font-color);
      font-size: var(--font-size-tiny);
    }
    .giftreceiveritem_container_user_txtname{
      color: var(--global-font-title);
      font-size: var(--font-size-h6);
    }
    .giftreceiveritem_avatar{
      width: 5em;
      height: 5em;
      border-radius: 5em;
      object-fit: cover;
      margin: 0 1em 0 0;
    }
    .giftreceiveritem_imgproduct{
      width: 5em;
      height: 5em;
      border-radius: 5em;
      object-fit: cover;
      margin: 0 0.5em 0 1em;
    }
    .giftreceiveritem_container_icongift{
      width: 25px;
      height: 25px;
      color: var(--color-theme-primary);
    }
    .giftreceiveritem_container_user{
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      //width: max-content !important;
    }
    .giftreceiveritem_container_gift{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 1em;
      padding-top: 1em;
      flex-direction: column !important;
    }
  }

  .giftreceiveritem_optionmenu{
    position: absolute !important;
    top: 0;
    right: -0.5em;
    background: transparent;
    padding: 0.15em !important;
    border-radius: var(--border-radius-box);
    &:hover{
      background: var(--color-theme-primary-light);
    }
    & a{
      font-size: var(--font-size-normal);
    }
  }

  .cancel_gift{
    color: var(--color-theme-primary) !important;
  }

}
