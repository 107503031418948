.memberLists_container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 10px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 6px;
}

.memberLists_left {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.memberLists_left_info {
  display: grid;
}

.memberLists_left_info_name {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--gamifa--preset--color--black);
}

.memberLists_left_info_position {
  font-size: 15px;
}

.memberLists_left_info_des {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: normal;
  font-size: 14px;
  color: var(--gamifa--preset--color--black);
}

.memberLists_left_info_active {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  place-items: center flex-start;
  font-size: 15px;
  color: var(--gamifa--preset--color--black);
}

.memberLists_left_info_active_title {
  font-size: 15px;
}

.memberLists_right_button_chat {
  display: grid;
  place-items: center;
  border: 1px solid var(--color-theme-primary);
  border-radius: 6px;
  padding: 5px 10px;
}

.memberLists_right_button_chat:hover {
  background-color: #fadada;
}

.memberLists_right_icon_chat {
  width: 15px;
  height: 15px;
  object-fit: cover;
}

.memberLists_right_button_setting {
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center flex-start;
}

.memberLists_right_button_wrapper {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
}

.memberLists_right_button_setting {
  display: grid;
  column-gap: 10px;
  background-color: var(--color-theme-primary);
  color: #fff;
  border-radius: 6px;
  padding: 5px 10px;
}

.memberLists_modal_setting_user_header {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.memberLists_modal_setting_user_wrapper {
  display: grid;
  row-gap: 20px;
}

.memberLists_modal_setting_user_body {
  display: grid;
  grid-template-columns: auto auto;
  min-width: 150px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.memberLists_modal_setting_user {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.memberLists_modal_setting_user_info {
  display: grid;
  grid-template-rows: auto auto;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--gamifa--preset--color--black);
}

.memberLists_modal_setting_user_info_name {
  font-weight: 600;
}

.memberLists_modal_setting_user_body_button {
  color: var(--color-theme-primary);
  text-decoration: underline;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start !important;
  width: fit-content;
}

@media screen and (max-width: 920px) {
  .memberLists_container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .memberLists_right {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 468px) {
  .memberLists_left {
    grid-template-columns: 1fr;
    place-items: center;
  }
}
