#form-file-upload_module {
  text-align: center;
  position: relative;
  width:180px;
  height:100px;
}
#container_loading_module {
  width:100%;
  height:100px;
}

#input-file-upload_module {
  display: none;
}

#label-file-upload_module {
  padding: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  //border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  font-size: 13px;
}

#label-file-upload_module.drag-active {
  background-color: #ffffff;
}

.upload-button_module {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 13px;
  border: none;
  font-family: '.SFNSText-Regular', sans-serif;
  background-color: transparent;
}

.upload-button_module:hover {
  text-decoration-line: underline;
}

#drag-file-element_module {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.thumbnail_module{
  position: absolute;
  width:180px;
  height:100px;
  pointer-events: none;
  object-fit: cover;
  //border: 1px solid #fff;
}

.container_media_module{
  display: flex;
  width:180px;
  height:100px;
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-box);

  .loaded {
      position: absolute;
      width: 0;
      height: 100%;
      background-color: #fff;
      top: 0;
      left: 0;
      z-index: 9;
      //opacity: 0.4;
    color: black;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}
.thumbnail_module_bottom{
  background: black;
  opacity: 0.8;
  color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 0;
  //border-left: 1px solid white;
  //border-right: 1px solid white;
}
