// src/components/EmojiReaction.scss

.emoji_livestream {
  z-index: 999;
  position: absolute;
  //animation: flyIn 1s ease-in-out, fadeOut 1s ease-in-out 1s;
  animation: floating-heart 3s infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  pointer-events: none;
  transform: translateY(-75%) translateX(-75%) scale(1);
}

.heart:nth-child(1) {
  animation-delay: 1s;
}

.heart:nth-child(2) {
  animation-delay: 2.5s;
}

.heart:nth-child(3) {
  animation-delay: 3.5s;
}

.heart:nth-child(4) {
  animation-delay: 4.5s;
}

@keyframes floating-heart {
  0% {
    transform: translateY(-75%) translateX(-75%) scale(1);
  }
  10% {
    transform: translateY(-120%) translateX(-75%) scale(0.9);
  }
  20% {
    transform: translateY(-220%) translateX(-80%) scale(0.8);
  }
  30% {
    transform: translateY(-320%) translateX(-20%) scale(0.7);
  }
  40% {
    transform: translateY(-420%) translateX(-70%) scale(0.6);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-520%) translateX(-30%) scale(0.4);
  }
  100% {
    opacity: 0;
    transform: translateY(-620%) translateX(-50%) scale(0.2);
  }
}
