#eventDetail{
  .overlay_img_live{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
  }

  .breadcrumb,
  .breadcrumb a,
  .breadcrumb .breadcrumb-item,
  .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: var(--gamifa--preset--color--pale-pink);
    font-weight: var(--font-weight-medium);
    font-size: inherit;
  }

  .breadcrumb-item.active {
    color: #fff;
  }

  .overlay_banner_live{
    width: 100%;
    max-height: 15em;
    height: auto;
    object-fit: cover;
  }

  .overlay_member{
    background: transparent;
    justify-content: space-between;
    padding: 1em;
  }

  .taglive_member{
    align-self: flex-start;
    background: var(--color-theme-primary);
    border-radius: var(--border-radius-box);
    padding: 0.2em 0.5em;
    top: 1em;
    left: 1em;
    font-size: var(--font-size-small);
    color: var(--color-theme-white);
  }

  .overlay_text_live{
    color: var(--color-theme-white) !important;
    font-weight: var(--font-weight-semi-bold);
  }

  .border_light{
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .color_primary{
    color: var(--color-theme-primary)
  }
}
