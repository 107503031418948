$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1399px;

@mixin for-phone-only {
  @media (max-width: $sm) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $md) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $xl) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: $xxl) {
    @content;
  }
}

.podcast-player {
  background-color: #fff;
  display: flex;
  width: 100%;
  @include for-tablet-portrait-up {
    width: calc(100% - var(--sidebar-width));
  }
  padding: 0 16px;
  position: fixed;
  z-index: 999;
  bottom: 0;
  padding-top: 8px;
  &__name-container {
    display: inline-block;
    overflow-x: hidden;
    width: 150px;
  }
  &__name {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-left: 16px;
    & > * {
      font-size: 14px;
      animation: animPodcastName 2s linear infinite;
      padding-left: 24px;
    }
  }
  &__countdown-time {
    font-size: 12px;
  }

  &__controller {
    align-self: center;
  }

  &__volume {
    width: 120px;
  }

  &__countdown > * {
    font-size: 12px;
  }
}

@keyframes animPodcastName {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
