.courseFilterProduct__container {
  display: grid;
  grid-template-rows: repeat(1, minmax(500px, 1fr));
  grid-template-columns: repeat(2, 1fr);
}

.courseFilterProduct__left {
  display: grid;
  grid-template-rows: 40px 1fr;
  row-gap: 30px;
  padding-right: 16px;
}

.courseFilterProduct__search {
  position: relative;
}

.courseFilterProduct__search > img {
  position: absolute;
  left: 12px;
  transform: translate(0, 50%);
}

.courseFilterProduct__search--input {
  outline: unset;
  background-color: rgba(#d9d9d9, 0.25);
  border: none;
  border-radius: 6px;
  padding: 3px 12px 3px 40px;
  color: #000;
  width: 100%;
  font-size: 15px;
}

.courseFilterProduct__list,
.courseFilterProduct__listSelect {
  list-style: none;
  padding: unset;
  margin: unset;
  display: grid;
  row-gap: 10px;
  height: 50px;
}

.courseFilterProduct__listSelect {
  padding-right: 10px;
}

.courseFilterProduct__listSelect--wrapper {
  height: auto;
  overflow-y: auto;
}

.courseFilterProduct__list--item {
  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 10px;
  height: 50px;
  align-items: center;
}

.courseFilterProduct__list--product > img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  object-fit: cover;
}

.courseFilterProduct__list--select {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
}

.courseFilterProduct__list--select > img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.courseFilterProduct__list--title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.courseFilterProduct__list--product {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
}

.productSelect {
  cursor: default;
}

.courseFilterProduct__list--input {
  width: 15px;
  height: 15px;
}

.courseFilterProduct__right {
  border-left: 1px solid rgba(#000000, 0.25);
  padding-left: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 30px;
}

.courseFilterProduct__loadMore {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding-right: 20px;
  text-decoration: underline;
}

.courseFilterProduct__loadMore:hover {
  color: #303030;
}
