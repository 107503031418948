.avatarMember_wrapper {
  display: grid;
  place-items: center;
  position: relative;
  width: 94px;
  height: 94px;
  border-radius: 50%;
}

.avatarMember_image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.circular-chart {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 94px;
  height: 94px;
}

.circular-chart.orange .circle {
  stroke: var(--color-theme-primary);
}

.circular-chart.green .circle {
  stroke: #4cc790;
}

.circular-chart.blue .circle {
  stroke: #3c9ee5;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 2;
}

.circle {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.green .circle {
  stroke: #4cc790;
}

.circular-chart.blue .circle {
  stroke: #3c9ee5;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.avatarMember_level_wrapper {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 50%);
}

.avatarMember_level_body {
  position: relative;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
}

.avatarMember_level_content {
  position: absolute;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
