.missionDetail_container {
  display: grid;
  grid-template-rows: auto repeat(1, minmax(0, 1fr));
  row-gap: 24px;
}

.missionDetail_body {
  display: grid;
  margin: 0 auto;
  row-gap: 30px;
}

.missionDetail_body_header {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, auto));
  background-color: var(--color-theme-primary-light);
  color: var(--color-theme-black);
  height: auto;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 0px 1px var(--color-theme-primary);
}

.missionDetail_body_header_first {
  font-size: 18px;
  font-weight: 500;
  height: auto;
}

.missionDetail_body_header_second {
  display: grid;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  margin-bottom: 10px;
}

#missionDetail .bg-primary {
  background-color: var(--color-theme-primary) !important;
}

.missionDetail_body_info_gift {
  display: grid;
  row-gap: 20px;
}

.missionDetail_body_info_gift_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  row-gap: 10px;
}

.missionDetail_body_info_gift_body {
  box-shadow: 0 0 5px var(--color-theme-primary);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-theme-white);
  cursor: default;
}

.missionDetail_body_info_gift_body.disabled {
  background-color: unset;
}

.missionDetail_body_info_header {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  column-gap: 10px;
}

.missionDetail_body_info_header_day {
  font-size: 18px;
  font-weight: 700;
}

.missionDetail_body_info_status_completed {
  color: var(--bs-success);
  font-weight: 500;
}

.missionDetail_body_info_mission {
  display: grid;
  row-gap: 10px;
  margin-top: 10px;
}

.missionDetail_body_info_award {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: flex-start;
  align-items: center;
}

.missionDetail_body_info_award_question {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--color-theme-primary);
}

.missionDetail_body_info_award_name {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: flex-start;
  column-gap: 5px;
}

.missionDetail_body_info_award_name > img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.missionDetail_body_info_award_name > span {
  font-weight: 400;
}

.missionDetail_info_award_name {
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.missionDetail_info_award_name span:first-child {
  font-weight: 500;
  word-break: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.missionDetail_info_award_name span:last-child {
  font-size: 12px;
  font-weight: 500;
}

.missionDetail_body_info_mission_item {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-theme-primary);
  padding: 0 10px 10px;
  column-gap: 10px;
}

.missionDetail_body_info_mission_item:last-child {
  margin-bottom: 10px;
}

.missionDetail_info_mission_item_left {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 5px;
  align-items: center;
}

.missionDetail_info_mission_item_left > span {
  font-size: 14px;
  font-weight: 500;
  word-break: normal;
}

.missionDetail_body_info_award_title {
  font-weight: 600;
}

.missionDetail_body_container {
  display: grid;
  background-color: var(--color-theme-primary-medium);
  border-radius: 6px;
  margin-bottom: 20px;
  box-shadow: 0 0px 1px var(--color-theme-primary);
  color: #fff;
}

.missionDetail_body_container.disabled {
  background-color: var(--color-theme-primary-light);
  color: var(--color-theme-black);
}

.missionDetail_body_container:last-child {
  margin-bottom: unset;
}

.missionDetail_body_container_header {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, auto));
  row-gap: 10px;
  padding: 20px;
}

.missionDetail_header_bold {
  font-size: 18px;
  font-weight: 500;
}

.missionDetail_body_content {
  display: grid;
  row-gap: 10px;
  grid-template-rows: auto repeat(1, minmax(0, 1fr));
}

.missionDetail_header_title_bold {
  font-size: 18px;
  font-weight: 500;
}

.missionDetail_body_header_wrapper {
  display: grid;
  grid-template-rows: repeat(2, minmax(0, auto));
  row-gap: 5px;
}

.missionDetail_body_header_progress_title {
  font-size: 14px;
}

.missionDetail_container_header {
  padding: 5px 20px;
  color: var(--color-theme-black);
  background-color: var(--gamifa--preset--color--theme-white);
}

.missionDetail_body_progressBar {
  display: flex;
  flex-direction: column;
}

.missionDetail_container_second {
  padding: 20px;
}

.missionDetail_right_list_child_item {
  display: grid;
  grid-template-columns: 10px repeat(1, 1fr);
  align-items: center;
  column-gap: 20px;
}

.missionDetail_list_child_item_left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.missionDetail_list_child_item_left::after {
  content: '';
  width: 1px;
  height: calc(100% - 10px);
  background-color: var(--color-theme-white);
}

.missionDetail_right_list_child_item:last-child .missionDetail_list_child_item_left::after {
  content: none;
}

.missionDetail_list_child_item_left_title {
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-theme-white);
}

.missionDetail_list_child_item_right {
  padding: 10px 0;
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(1, minmax(0, 1fr)) auto;
}

.missionDetail_right_children_title {
  font-size: 14px;
  font-weight: 500;
}

.missionDetail_right_children_value {
  font-size: 14px;
}

.missionDetail_container_tabs {
  display: flex;
  flex-wrap: wrap;
  word-break: normal;
  border-radius: 6px;
  padding: 10px 20px;
  min-height: 50px;
  background-color: var(--color-theme-primary-light);
}

.missionDetail_container_tabs_item_active {
  border-bottom: 1px solid var(--color-theme-primary);
  color: var(--color-theme-primary);
  font-weight: 500;
}

.missionDetail_container_tabs_item {
  margin-right: 20px;
  font-size: 16px;
  padding: 10px 0;
  transition: 0.5s all cubic-bezier(0.42, 0, 0.58, 1);
}

.missionDetail_container_tabs_item:hover {
  color: var(--color-theme-primary);
  transform: translate(0px, -5px);
}

.missionDetail_container_tabs_item:last-child {
  margin-right: unset;
}

.missionDetail_right_info_award_name {
  font-weight: 400;
  display: flex;
  align-items: center;
}

.missionDetail_right_info_award_question {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--color-theme-primary);
}

.missionTimeline_right_info_award_name:hover{
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--color-theme-primary);
  color: #fff;
}