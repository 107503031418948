.messageComponent_time {
  display: flex;
  justify-content: center;
}

.chatList_time_text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #626f82;
  margin: 5px 0;
}

.messageComponent_chat_author {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin: 0;
  min-height: 40px;
}

.messageComponent_media,
.messageComponent_media_author {
  max-width: 250px;
  max-height: 250px;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 12px;
}

.messageComponent_media_author {
  margin: 8px 0 0 40px;
}

.messageComponent_chat_client {
  display: flex;
  justify-content: left;
  align-items: flex-end;
  margin: 0;
  min-height: 40px;
}

#messageComponent .messageComponent_chat_content {
  max-width: 400px;
  margin: 8px 0 0 40px;
  border-radius: 12px;
  background: #dc3545;
  color: #ffffff;
  padding: 12px 16px;
  font-size: 14px;
  word-break: break-all;
  word-break: break-word;
  white-space: pre-line;
  text-align: left;
}

#messageComponent .messageComponent_chat_content_client {
  margin: 4px 40px 8px 0;
  border-radius: 12px;
  background-color: #f3f3f3;
  max-width: 400px;
  color: #414141;
  padding: 12px 16px;
  font-size: 14px;
  word-break: break-all;
  word-break: break-word;
  white-space: pre-line;
  text-align: left;
}

.messageComponent_chat_client_wrapper {
  display: grid;
  grid-template-columns: 44px auto;
  column-gap: 5px;
}

.messageComponent_chat_client_avatar img {
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 50%;
}
