.channelSettingCategory_header {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
}

.channelSettingCategory_header_title {
  font-size: 20px;
  font-weight: 600;
}

.channelSettingCategory_body_list_item {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f1f5;
  padding: 12px;
}

.channelSettingCategory_body_item_info {
  display: grid;
  grid-template-rows: auto auto;
}

.channelSettingCategory_body_item_button {
  display: none;
  grid-template-columns: auto auto;
  column-gap: 10px;
  align-items: center;
}

.channelSettingCategory_body_list_item:hover .channelSettingCategory_body_item_button {
  display: grid;
}

.addCategory_form_wrapper {
  display: grid;
  row-gap: 20px;
}

.addCategory_form {
  display: grid;
  row-gap: 10px;
}

.addCategory_form_button {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
}
