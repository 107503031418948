.addCard__select,
.addCard__input {
  height: 50px;
}

.addCard__input:focus {
  box-shadow: rgba(231, 127, 133, 0.5) 0px 0px 0px 0.2rem;
  border-color: var(--color-theme-primary-medium);
  outline: none;
}

.validation_error {
  box-shadow: rgba(250, 0, 12, 0.5) 0px 0px 0px 0.2rem;
  border-color: var(--color-theme-primary-medium);
}

.text_error {
  margin: 5px 0 0;
  color: rgba(250, 0, 12, 0.5);
}
