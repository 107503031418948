@mixin typography($fontSize: 14px, $fontWeight: 400, $lineHeight: 1rem) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}
.podcast-item {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
  max-width: 500px;
  &__posted-at {
    @include typography(12px, 400);
  }
  &__title,
  &__excerpt {
    @include typography(14px, 600, 24px);
  }

  &__title {
    color: #000;
  }
  &__excerpt {
    font-weight: 300;
    max-width: 500px;
  }

  &__controller {
    width: fit-content;
    padding: 6px 8px;
    cursor: pointer;
  }

  &__countdown {
    @include typography(12px, 400);
  }
}
