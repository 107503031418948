.missionTimeline_body_container {
  display: grid;
  grid-template-columns: auto repeat(1, minmax(0, 1fr));
  column-gap: 30px;
  width: 100%;
  height: auto;
}

.missionTimeline_container_left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.missionTimeline_container_left_content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--color-theme-white);
  color: var(--color-theme-primary);
  border: 2px solid var(--color-theme-primary);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.missionTimeline_container_left::after {
  content: '';
  width: 2px;
  height: calc(100% - 50px);
  background-color: var(--color-theme-primary);
}

.missionTimeline_body_container:last-child .missionTimeline_container_left::after {
  content: none;
}

.missionTimeline_container_right {
  padding-bottom: 20px;
}

.missionTimeline_line {
  padding-top: 20px;
  border-bottom: 1px solid var(--color-theme-primary);
}

.missionTimeline_body_container:last-child .missionTimeline_line {
  padding-top: 0px;
  border-bottom: unset;
}

.missionTimeline_body_container:last-child .missionTimeline_container_right:last-child {
  border-bottom: unset;
  padding-bottom: unset;
}

.missionTimeline_right_header_title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-theme-black);
}

.missionTimeline_container_right_content {
  display: grid;
  row-gap: 5px;
  color: var(--color-theme-black);
}

.missionTimeline_container_right_header {
  display: flex;
  justify-content: space-between;
}

.missionTimeline_right_header_button {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  column-gap: 10px;
}

.missionTimeline_right_content_title {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, auto));
  justify-content: flex-start;
  column-gap: 10px;
  font-size: 14px;
  font-weight: 400;
  word-break: normal;
}

.missionTimeline_right_info_award {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: flex-start;
  align-items: center;
}

.missionTimeline_right_info_award_question {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--color-theme-primary);
}

.missionTimeline_right_info_award_name {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: flex-start;
  column-gap: 5px;
}

.missionTimeline_right_info_award_name > img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.missionTimeline_right_info_award_name > span {
  font-weight: 400;
}

.missionTimeline_right_award_name {
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.missionTimeline_right_award_name span:first-child {
  font-weight: 500;
  word-break: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.missionTimeline_right_award_name span:last-child {
  font-size: 12px;
  font-weight: 500;
}

.missionTimeline_right_info_award_title {
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .missionTimeline_body_container {
    grid-template-columns: unset;
  }

  .missionTimeline_container_left {
    display: none;
  }
}
