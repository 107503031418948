.aboutPage_container {
  display: grid;
  place-items: center;
  width: 100%;
}

.aboutPage_wrapper {
  display: grid;
  row-gap: 20px;
  background-color: #fff;
  padding: 16px;
  width: 100%;
  border-radius: 6px;
}

.aboutPage_first {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  justify-content: space-between;
  align-items: center;
}

.aboutPage_title {
  font-weight: 600;
  font-size: 22px;
  color: var(--gamifa--preset--color--theme-black);
}

.aboutPage_button {
  display: flex;
  align-items: center;
  background-color: var(--color-theme-primary);
  border-radius: 6px;
  color: #fff;
  height: 40px;
  padding: 0 10px;
  text-decoration: none;
}

.aboutPage_button:hover {
  background-color: var(--color-theme-primary-medium);
}

.aboutPage_button_text {
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
}

.aboutPage_second_media {
  width: 100%;
}

.aboutPage_second_image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 6px;
}

.aboutPage_second_video {
  width: 100%;
  border-radius: 6px;
}

.aboutPage_second_listMedia {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.aboutPage_second_List_item {
  position: relative;
}

.aboutPage_second_List_item_image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 8px;
  margin-right: 16px;
  border-radius: 6px;
}

.aboutPage_second_List_item_icon {
  position: absolute;
  top: 15px;
  right: 20px;
  background-color: #f2f2f296;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.aboutPage_second_List_item_icon:hover {
  background-color: #c5c5c5a4;
}

.aboutPage_four {
  display: flex;
  justify-content: center;
}

.aboutPage_four_members,
.aboutPage_four_author {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
}

.aboutPage_four_members span,
.aboutPage_four_author span {
  font-weight: 600;
  color: var(--gamifa--preset--color--theme-black);
}

.aboutPage_four_author {
  margin-left: 20px;
  column-gap: 10px;
}

.aboutPage_four_author_image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.aboutPage_five_header {
  font-weight: 600;
  color: var(--gamifa--preset--color--theme-black);
  line-height: 1.5;
}

.aboutPage_five {
  display: flex;
  flex-direction: column;
}

.aboutPage_button_wrapper {
  display: flex;
  margin-top: 20px;
}

.aboutPage_five_button {
  margin-right: 10px;
}

.aboutPage_second_List_item_button_plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  background-color: #f0f0f0;
  margin-top: 8px;
  margin-right: 16px;
}

.aboutPage_second_icon_plus {
  width: 40p;
  height: 40px;
}

.aboutPage_second_empty_image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: #f0f0f0;
  border-radius: 6px;
}

.aboutPage_second_empty_image span {
  font-weight: 700;
}

.aboutPage_five_content {
  white-space: pre-line;
}
