#long_challenge {
    .color-text-title {
        color: var(--color-theme-primary) !important;
    }

    .nav-tabs {
        border: none !important;
        background: var(--color-theme-primary-light) !important;
        border-radius: 5px !important;
        padding-top: 10px !important;
        padding-bottom: 0px !important;
    }

    .nav-link {
        color: rgb(110, 110, 110) !important;
        margin-left: 10px !important;
        border-color: var(--color-theme-primary-light) !important;
        border-width: 2px !important;
    }

    .nav-link:hover {
        color: var(--color-theme-primary-medium) !important;
    }

    .nav-link.active {
        color: var(--color-theme-primary) !important;
        background-color: transparent !important;
        border-bottom-color: var(--color-theme-primary) !important;
        border-bottom-width: 2px !important;

    }

    .image-challenge-item {
        object-fit: cover;
        height: 164px;
        background-color: black;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .border-calendar-icon {
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background-color: rgba(0, 0, 0, 0.118);
        display: flex
    }

    .icon-size-challenge-item {
        font-size: 10px;
    }

    .text-size-challenge-item {
        font-size: 12px;
    }

    .person-participant-container {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.118);
        border-radius: 5px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .challenge_title {
        line-height: 1.5rem;
        font-weight: var(--font-weight-semi-bold);
        height: 48px;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .image-avatar-challenge-rank {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        object-fit: cover;
    }
}

#challenge_tab {
   &.tab-search-container {
        height: 55px;
        background-color: var(--color-theme-primary-light);
    }
    
    .tab-item {
        border-bottom: 2px solid var(--color-theme-primary-light);
    }
    
    .tab-item:hover {
        color: var(--color-theme-primary-medium);
    }
    
    .tab-item-active {
        color: var(--color-theme-primary);
        border-bottom: 2px solid var(--color-theme-primary);
    }

    .search-tab{
        width: 0;
        transition: width 2s;
    }
    .animation-form-input{
        width: 0px;
        transition: 0.5s ease-in-out;
        padding: 0;
        box-shadow: none !important;
        border: unset;
    }
    .animation-form-input:hover{
        width: 100px;
    }
}