.userNotificationSetting_container,
.userNotificationSetting_body {
  display: grid;
  row-gap: 20px;
}

.userNotificationSetting_label {
  font-size: 20px;
  font-weight: 600;
}

.userNotificationSetting_body_item {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: 10px;
}

.userNotificationSetting_body_item_left {
  display: grid;
  grid-template-rows: auto auto;
  align-items: flex-start;
}

.userNotificationSetting_body_item_title {
  font-size: 18px;
}

.userNotificationSetting_body_item_left_content {
  font-size: 15px;
  line-height: 1.5;
}

.userNotificationSetting_body_item_right input:first-child {
  width: 40px;
  height: 20px;
}
