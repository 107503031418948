#course-study {
  .container_study {
    height: calc(100vh - 68px);
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 68px;
  }

  .coursestudy_viewmodule {
    width: 100%;
    background: white;
    max-height: calc(100vh - 60px);
    position: relative !important;
    flex:1;
  }

  .coursestudy_viewmodules {
    flex: 1;
    overflow-y: auto;
  }

  .coursestudy_viewmodules {
    // min-height: 100vh;
    margin-bottom: 80px;
  }

  .coursestudy_txtnamecourse {
    font-size: 20px;
    color: #a51c30;
    font-weight: 700;
    margin-top: 25px;
    line-height: 1.1;
    overflow-wrap: break-word;
  }

  .coursestudy_empty {
    font-size: 18px;
    align-self: center;
    color: gray;
    font-weight: 700;
    margin-top: 15px;
  }

  .coursestudy_btnback {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    //padding: 20px;
    padding: 15px 0 !important;
    border-radius: 0 !important;
    background: var(--color-theme-secondary) !important;
    border: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    font-size: var(--font-size-body) !important;
  }

  .coursestudy_txtnumofmodule {
    margin: 15px 0;
    font-size: 14px;
    color: #444444;
    font-weight: 400;
  }

  .study_container {
    height: 100%;
    // display: grid;
    // grid-template-columns: 1fr repeat(1,minmax(370px,auto));
  }

  .coursestudy_iconback {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: var(--color-theme-white);
  }


  .container_study_videocontainer {
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .container_study_videocontainer_error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
  }

  .col_left_container_study_video {
    // width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: calc(100% - 60px);
  }

  .courses_right_column_jamviet_add {
    //padding: 15px;
    // position: relative;
    // width: 100%;
    display: flex;
    flex-direction: column;
  }


  /*
  Mobile
  */
  @media only screen and (max-width: 768px) {
    .container_study_videocontainer,
    .col_left_container_study_video {
      height: 300px;
    }

    .courses_right_column_jamviet_add {
      padding: 0 !important
    }

    .coursestudy_viewmodule {
      width: 100%;
      background: white;
      max-height: calc(100vh - 60px);
      position: relative !important;
      flex: 1;
    }

    .study_container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, minmax(0, auto));
      height: auto;
    }

  }

  .video_wrap {
    position: relative;
    z-index: 99;
    width: 100%;

    #video_source {
      position: relative;
      z-index: 9;
    }

    .videoWatermark {
      position: absolute;
      z-index: 1001;
      bottom: 4em;
      right: 2em;
      border-radius: 6px;
      opacity: 0.8;
    }
  }
}







