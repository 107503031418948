#coursesIncomeTable {
  .coursesIncomeTable__container {
    width: 100%;
    height: 100%;
  }

  .coursesIncomeTable__header {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    height: 60px;
    background-color: #a51c30;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 0 20px;
    column-gap: 10px;
  }

  .coursesIncomeTable__header.fromClient {
    background-color: var(--color-theme-secondary);
  }

  .coursesIncomeTable__item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }

  .coursesIncomeTable__item:nth-child(1),
  .coursesIncomeTable__item:nth-child(2),
  .coursesIncomeTable__item:nth-child(3) {
    justify-content: flex-start;
  }

  .coursesIncomeTable__list--wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: flex-start;
    column-gap: 10px;
    height: auto;
    padding: 10px 20px;
  }

  .coursesIncomeTable__list--item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .coursesIncomeTable__list--item:nth-child(1),
  .coursesIncomeTable__list--item:nth-child(2) {
    justify-content: flex-start;
  }

  .coursesIncomeTable__list--image > img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  .evenNumber {
    background-color: #ffeef0;
  }

  .oddNumber {
    background-color: #fff;
  }

  .evenNumber.fromClientWrapper {
    background-color: #f6f6f6;
  }

  .coursesIncomeTable__list--percent {
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }

  .coursesIncomeTable__list--buyerWrapper,
  .coursesIncomeTable__list--affiliateWrapper {
    display: grid;
    grid-template-columns: 28px 1fr;
    align-items: center;
    column-gap: 6px;
  }

  .coursesIncomeTable__list--buyer,
  .coursesIncomeTable__list--price,
  .coursesIncomeTable__list--revenue,
  .coursesIncomeTable__list--name,
  .coursesIncomeTable__list--subTitle,
  .coursesIncomeTable__list--revenueMobile,
  .coursesIncomeTable__list--contentName,
  .coursesIncomeTable__list--contentRole {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
  }
  .text_decoration_none{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .coursesIncomeTable__link{
    a {
      text-decoration: none !important;
    }
  }

  .coursesIncomeTable__list--price.fromClientItem,
  .coursesIncomeTable__list--revenue.fromClientItem {
    color: var(--color-theme-secondary);
  }

  .coursesIncomeTable__list--buyer,
  .coursesIncomeTable__list--contentName {
    font-size: 16px;
    color: #000;
    font-weight: 700;
  }

  .coursesIncomeTable__list--contentRole {
    font-size: 14px;
    color: #6d6d6d;
    font-weight: 500;
  }

  .coursesIncomeTable__list--price,
  .coursesIncomeTable__list--revenue {
    color: #a51c30;
    font-size: 18px;
    font-weight: 700;
  }

  .coursesIncomeTable__list--name {
    font-size: 16px;
    color: #373737;
    font-weight: 600;
  }

  .coursesIncomeTable__list--subTitle {
    font-size: 14px;
    color: #444444;
    font-weight: 500;
  }

  .coursesIncomeTable__list--headerMobile {
    display: none;
    color: #000;
    font-size: 14px;
    font-weight: 700;
  }

  .displayPosition {
    grid-column-start: 4;
  }

  .affiliateBlock {
    justify-content: flex-start;
  }

  .coursesIncomeTable__list--percentMobile,
  .coursesIncomeTable__list--buyTimeMobile {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    .coursesIncomeTable__header {
      display: none;
    }

    .coursesIncomeTable__list--headerMobile {
      display: grid;
    }

    .coursesIncomeTable__list--wrapper {
      height: auto;
      grid-template-columns: 1fr;
      justify-content: space-between;
      padding: 16px 22px;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.103);
      row-gap: 10px;
    }

    .coursesIncomeTable__list--item,
    .coursesIncomeTable__list--buyTimeMobile {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: 35px;
      justify-content: space-between;
      column-gap: 5px;
    }

    .coursesIncomeTable__list {
      display: grid;
      row-gap: 10px;
    }

    .coursesIncomeTable__list--buyTime {
      font-size: 12px;
      color: #373737;
      font-weight: 600;
    }

    .coursesIncomeTable__list--percentMobile {
      display: grid;
      color: #a51c30;
      font-size: 14px;
    }

    .coursesIncomeTable__list--revenueMobile {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 5px;
      align-items: center;
    }

    .evenNumber {
      background-color: #fff;
    }

    .coursesIncomeTable__list--item:nth-child(2) {
      grid-row: 2;
    }

    .coursesIncomeTable__list--item:nth-child(3) {
      grid-row: 3;
    }

    .coursesIncomeTable__list--item:nth-child(4) {
      grid-row: 4;
    }

    .coursesIncomeTable__list--item:nth-child(6) {
      grid-row: 5;
    }

    .coursesIncomeTable__list--subTitle,
    .coursesIncomeTable__list--itemMobile,
    .fragmentHollow {
      display: none;
    }

    .coursesIncomeTable__list--name {
      font-size: 14px;
    }

    .displayPosition {
      grid-column-start: unset;
    }
    .coursesIncomeTable__list--item:nth-child(1),
    .coursesIncomeTable__list--item:nth-child(2),
    .affiliateBlock {
      justify-content: space-between;
    }
  }
}
