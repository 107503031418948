.notificationList_container {
  display: grid;
  place-items: center;
}

.notificationList_wrapper {
  display: grid;
  background-color: #fff;
  padding: 16px;
  width: 100%;
  border-radius: 6px;
}

.notificationList_header {
  font-weight: 600;
  font-size: 22px;
  color: var(--gamifa--preset--color--theme-black);
}

.notificationList_second_wrapper {
  display: grid;
  grid-template-columns: 60px 1fr;
  column-gap: 10px;
  align-items: center;
  margin-top: 16px;
}

.notificationList_second_image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.notificationList_second_body {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, auto));
}

.notificationList_second_body_label {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.notificationList_second_body_content,
.notificationList_second_body_time {
  font-size: 15px;
  word-break: break-word;
}

.notificationList_second_body:hover {
  color: var(--color-theme-primary);
}
