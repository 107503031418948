#treeWrapper {
  width: auto;
  height: 100vh;
}

.node__branch > circle {
  fill: yellow;
}

.tooltip-node {
  pointer-events: none; /*let mouse events pass through*/
  opacity: 0;
  transition: opacity 0.3s;
  // text-shadow: 1px 1px 0px #000;
  // color: red;
  &.show {
    opacity: 1;
  }
  &__text {
    font-size: 12px;
    // font-weight: bold;
    // text-align: center;
    // text-shadow: 0px 1px 0px #000;
  }
}
.node-container {
  overflow: visible;
}
