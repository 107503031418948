.h5, .h6, h5, h6 {
	color: #07142e;
	color: var(--global-font-title);
}

#navbar_in_header .dropdown-menu .item-body {
	max-height: 22em;
	overflow-y: auto;
	overflow-x: hidden;
}

.css_prefix-notification-box {
	padding: 1em 2em;
	-webkit-transition: all .45s ease-in-out;
	transition: all .45s ease-in-out;
	position: relative;
}

.css_prefix-notification-box:hover {
	background: #f8f9fa;
	background: var(--global-body-bgcolor);
}

.css_prefix-notification-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.item-body .item-details p.notification-text {
	color: #6f7f92;
	color: var(--global-font-color);
	font-size: .875em;
	font-size: var(--font-size-normal);
	line-height: 1.7em;
	font-weight: 500;
	font-weight: var(--font-weight-medium);
}

.css_prefix-notification-info .item-details a {
	font-size: inherit;
	color: #6f7f92;
	color: var(--global-font-color);
	text-decoration: none;
}


.css_prefix-notification-info .item-details .item-time {
	font-size: .75em;
	font-size: var(--font-size-small);
	margin-top: .375em;
	color: #6f7f92;
	color: var(--global-font-color);
}


.item-body .item-details p {
	font-size: .875em;
	font-size: var(--font-size-normal);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: normal;
}

.bp-messages-wrap img.avatar {
	border-radius: var(--border-radius-full) !important;
	transition: none;
	max-width: 100%;
	max-height: 100%;
}


.bp-messages-wrap .threads-list {
	position: relative;
	display: table;
	width: 100%;
	table-layout: auto;
}

.bp-messages-wrap .threads-list .thread {
	border-radius: var(--border-radius);
	padding: 0.5em 1em;
	position: relative;
	text-decoration: none;

}
.bp-messages-wrap .threads-list .thread {
	display: flex;
	align-content: center;
	align-items: flex-start;
	justify-content: space-between;
}

.bp-messages-wrap .threads-list .thread:hover {
	background: #f8f9fa;
	background: var(--global-body-bgcolor);
}


.bp-messages-wrap * {
	box-sizing: border-box !important;
	font-family: var(--bm-font-family);
	vertical-align: initial;
}

.bp-messages-wrap .threads-list .thread > * {
	display: table-cell;
	vertical-align: top;
	padding-bottom: 10px;
	padding-top: 10px;
	background: none;
	border: none;
	border-bottom: 1px solid rgba(var(--bm-border-secondary-color), 0.4);
	line-height: initial;
}

.header-messages .bp-messages-wrap .threads-list .thread > * {
	vertical-align: top;
	border: none;
}
.bp-messages-wrap .threads-list .thread .pic {
	padding-left: 15px;
	width: 50px;
	height: 50px;
	text-align: left;
	box-sizing: content-box !important;
	position: relative;
	opacity: 1;
	z-index: 1;
}

.header-messages .bp-messages-wrap .threads-list .thread .time {
	color: var(--global-font-color);
	line-height: 1.7em;
	font-weight: var(--font-weight-regular);
	font-family: var(--highlight-font-family);
	font-weight: var(--font-weight-regular);
	font-size: var(--font-size-small);
}


.header-messages .bp-messages-wrap .threads-list .thread .bm-info {
	width:200px
}
.header-messages .bp-messages-wrap .threads-list .thread .bm-info .name {
	margin-bottom: .2em;
	margin-top: .3em;
}
.bm-name-verified {
	color: var(--color-theme-primary);
	line-height: 0;
}
.bp-messages-wrap .threads-list .thread .bm-info .name,
.bp-messages-wrap .threads-list .thread .bm-info h4 {
	font-size: var(--font-size-normal);
	line-height: 16px;
	color: var(--global-font-title);
	font-weight: var(--font-weight-medium);
	margin: 0;
}

.header-messages .bp-messages-wrap .threads-list .thread .bm-info .last-message {
	font-size: var(--font-size-normal);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}




























