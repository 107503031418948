.userAccountSetting_container {
  display: grid;
  row-gap: 10px;
}

.userAccountSetting_label {
  font-size: 20px;
  font-weight: 600;
}

.userAccountSetting_body {
  display: grid;
  row-gap: 20px;
  margin-top: 10px;
}

.userAccountSetting_body_item {
  position: relative;
}

.userAccountSetting_body_item_input {
  min-height: 45px;
  font-size: 14px;
  height: auto;
}

.userAccountSetting_body_item_input.validate_failed {
  border-color: var(--bs-form-invalid-border-color) !important;
}

.userAccountSetting_body_item_label.validate_failed {
  color: var(--bs-form-invalid-border-color);
  top: 30%;
}

.userAccountSetting_body_item_label {
  position: absolute;
  font-size: 12px;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  user-select: none;
  background-color: #fff;
  padding: 0 4px;
  transition: 0.25s ease;
  cursor: text;
}

.userAccountSetting_body_item_input:focus + .userAccountSetting_body_item_label,
.userAccountSetting_body_item_input:not(:placeholder-shown) + .userAccountSetting_body_item_label {
  top: 0px;
  left: 20px;
}

.userAccountSetting_body_item_eye {
  position: absolute;
  top: 10px;
  right: 10px;
}

.userAccountSetting_footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.userAccountSetting_footer_forgotPassword {
  margin-right: 10px;
  text-decoration: none;
}
