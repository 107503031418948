.eventlistitem{
  height:430px;
  .eventlistitem_optionmenu{
    position: absolute !important;
    top: 0.5em;
    padding: 0.3em !important;
    right: 1em;
    background: transparent;
    //padding-bottom: 0 !important;
    border-radius: var(--border-radius-box);
    &:hover{
      background: rgba(165,28,48,0.1);
    }
    & a{
      font-size: var(--font-size-normal);
    }
  }

  .eventlistitem_iconmenu{
    width: 1.5em;
    height: 1.5em;
  }

  .delete_event{
    color: var(--color-theme-primary) !important;
  }

  .border{
    border: 1px solid rgba(0, 0, 0, 0.1)
  }

  .eventlistitem_taglive{
    background: var(--color-theme-primary);
    border-radius: var(--border-radius-box);
    padding: 0.2em 0.5em;
    position: absolute;
    top: 1em;
    left: 1em;
    font-size: var(--font-size-small);
    color: var(--color-theme-white);
  }
}
