.mobile-container{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 50px;
}
.mobile-des{
  text-align: center;
  padding: 0 75px;
}
.mobile-title,
.mobile-sub-title{
  color: var(--global-font-color) !important;
}
.mobile-bg{
  background-color: var(--color-theme-primary-light) !important;
}

.mobile-hr{
  margin-top: 0;
  margin-bottom: 48px;
  color: var(--color-theme-primary);
}
.mobile-btn-change-color{
  color: var(--color-theme-primary) !important;
}
.mobile-btn-change-color:hover{
  border-color: transparent !important;
  opacity: 0.8;
}
.mobile-btn:hover{
  background: var(--color-theme-primary-light) !important;
  color:  var(--color-theme-primary) !important;
  border-color: var(--color-theme-primary-light)!important;
}
.mobile-img{
  width: 100px;
  height: 100px;
}
.mobile-mr{
  margin-right: 20px;
}
//mobile-process
.mobile-process-container{
  background-color:#F0F0EF;
  height: 100%;
  text-align: center;
  padding: 60px 50px;
  .mobile-process{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 10px;

    .mobile-process-content{
      text-align:  center;
    }
  }
}

//mobile-table
.mobile-table{
  text-align: start;
  td,th {
    width: 200px;
  }
  tr:nth-child(odd) td {
    background-color: var(--color-theme-primary-light);
  }
}

//mobilr-info
.mobile-info-content{
  justify-content: space-between;
}
.mobile-info-store{
  width: 500px !important;
}
.mobile-reponsive{
  display: block;
}
.mobile-reponsive-small{
  display: none;
}

//reponsive
@media screen and (max-width: 1420px) and (min-width: 1201px) {
  .mobile-title{
    font-size: 36px;
  }
  .mobile-img{
    width: 75px;
    height: 75px;
  }
  .mobile-mr{
    margin-right: 0;
  }
  .mobile-info-store{
    width: 415px !important;
  }
}

@media screen and (max-width: 1200px) {
  .mobile-title{
    font-size: 30px;
  }
  .mobile-des{
    padding: 0;
  }
  .mobile-img{
    width: 50px;
    height: 50px;
  }
  .mobile-mr{
    margin-right: 0;
  }
  .mobile-process-text{
    font-size: 12px;
  }
  .mobile-info-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mobile-br{
    //display: none;
  }
}
@media screen and (max-width: 992px) {
  .mobile-img{
    width: 100px;
    height: 100px;
  }
  .mobile-process-container{
    padding: 60px 20px;
    .mobile-process{
      display: flex;
      flex-direction: row;
      text-align: center;
      padding: 20px 15px;
      margin-right: 0;
      .mobile-process-content{
        padding-top: 20px;
      }
    }
  }
  .mobile-reponsive{
    display: none;
  }
  .mobile-reponsive-small{
    display: block;
  }
  .mobile-process-lable{
    min-width: 50px;
    color: var(--color-theme-primary);
    font-size: 14px;
    margin: 0;
  }
  .mobile-process-text{
    font-size: 14px;
  }
}
.mobile-register-color-picker{
  width: 200px;
  min-height: 200px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .mobile-container{
    padding: 60px 20px;
  }
  .mobile-register-content{
    flex-direction: column !important;
  }
  .mobile-register-color-picker{
    margin: auto;
  }
}
@media screen and (max-width: 540px) {
  .mobile-btn{
    width: 100%;
  }
}
