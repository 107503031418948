.addMission_body_container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-theme-primary-light);
  padding: 20px;
  border-radius: 6px;
  margin-top: 10px;
}

.addMission_body_wrapper {
  display: grid;
  grid-template-columns: 50px repeat(1, minmax(0, 1fr));
  column-gap: 30px;
  width: 100%;
  height: auto;
}

.addMission_body_header {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: flex-start;
  column-gap: 20px;
  row-gap: 20px;
}

.addMission_body_header_title_wrapper {
  margin-bottom: 10px;
}

.addMission_header_right_content_title {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
}

.addMission_body_second_wrap {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: flex-start;
  column-gap: 20px;
  row-gap: 20px;
}

.addMission_body_header_first,
.addMission_body_header_second {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.addMission_body_header_third {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.addMission_body_header_label {
  margin-bottom: 10px;
}

.addMission_right_children_title {
  font-size: 16px;
  font-weight: 500;
}

.addMission_body_header_wrapper {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr)) auto;
  align-items: flex-start;
}

.addMission_body_header_button {
  display: grid;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding-top: 38px;
}

.addMission_body_header_wrapper_left {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: 1fr;
  align-items: center;
}

.addMission_list_child_item_right {
  padding-bottom: 20px;
}

.addMission_body_header_first_select {
  padding: 5px;
  border-radius: 6px;
}

.addMission_body_header_title {
  font-size: 16px;
  font-weight: 500;
}

.addMission_container_left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addMission_container_left_content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--color-theme-white);
  color: var(--color-theme-primary);
  border: 2px solid var(--color-theme-primary);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addMission_container_left::after {
  content: '';
  width: 2px;
  height: calc(100% - 50px);
  background-color: var(--color-theme-primary);
}

.addMission_body_wrapper:last-child .addMission_container_left::after {
  content: none;
}

.addMission_container_right {
  padding-bottom: 20px;
}

.addMission_line {
  padding-top: 20px;
  border-bottom: 1px solid var(--color-theme-primary);
}

.addMission_body_wrapper:last-child .addMission_line {
  padding-top: 0px;
  border-bottom: unset;
}

.addMission_body_wrapper:last-child .addMission_container_right:last-child {
  border-bottom: unset;
  padding-bottom: unset;
}

.addMission_right_header_title {
  font-size: 18px;
  font-weight: 600;
}

.addMission_container_right_content {
  display: grid;
  row-gap: 5px;
}

.addMission_right_content_title,
.addMission_right_content_body {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  justify-content: flex-start;
  column-gap: 10px;
  font-weight: 400;
  word-break: normal;
}

.addMission_right_info_award {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.addMission_right_info_award_content_wrapper {
  display: grid;
  row-gap: 5px;
}

.addMission_right_info_award_content {
  display: grid;
  grid-template-columns: 250px 1fr;
  height: auto;
  column-gap: 20px;
  margin-top: 10px;
}

.addMission_right_info_award_content_image {
  display: flex;
  flex-direction: column;
  min-height: 250px;
}

.addMission_right_info_award_image_wrapper {
  height: 100%;
}

.addMission_right_info_award_image_wrapper.validate_failed label {
  border-width: unset !important;
  border-style: unset !important;
  border-color: unset !important;
}

.addMission_right_info_award_image_wrapper.validate_failed {
  border-color: var(--bs-form-invalid-border-color) !important;
  border: 2px dashed var(--bs-border-color);
}

.addMission_right_info_award_coin {
  display: grid;
  grid-template-columns: 1fr;
}

.addMission_right_info_award_question {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--color-theme-primary);
}

.addMission_right_info_award_name {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: flex-start;
  column-gap: 5px;
}

.addMission_right_info_award_name > img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.addMission_right_info_award_name > span {
  font-weight: 400;
}

.addMission_right_award_name {
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.addMission_right_award_name span:first-child {
  font-weight: 500;
  word-break: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.addMission_right_award_name span:last-child {
  font-size: 12px;
  font-weight: 500;
}

.addMission_right_info_award_title {
  font-weight: 600;
}

.settingMission_footer {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: flex-end;
  column-gap: 10px;
}

.addMission_right_list_child_item {
  display: grid;
  grid-template-columns: 10px repeat(1, 1fr);
  align-items: center;
  column-gap: 20px;
}

.addMission_right_list_child_item:first-child {
  padding-top: 20px;
}

.addMission_list_child_item_left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addMission_list_child_item_left::after {
  content: '';
  width: 1px;
  height: calc(100% - 10px);
  background-color: var(--color-theme-primary);
}

.addMission_right_list_child_item:last-child .addMission_list_child_item_left::after {
  content: none;
}

.addMission_list_child_item_left_title {
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-theme-primary);
}

.addMission_body_add_mission_wrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 20px;
  padding: 20px 10px 0;
}

.addMission_right_info_award_wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 10px;
}

.addMission_body_header_option_award {
  display: flex;
}

.addMission_body_header_option_body {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  align-items: center;
  column-gap: 5px;
  margin-right: 30px;
}

.addMission_body_header_option_body label {
  color: var(--bs-body-color);
}

.addMission_body_header_option_body input {
  width: 20px;
  height: 30px;
  accent-color: var(--color-theme-primary);
}

.addMission_body_header_option_gift {
  margin-top: 10px;
}

.validate_failed {
  border-color: var(--bs-form-invalid-border-color) !important;
}

@media screen and (max-width: 768px) {
  .addMission_body_header {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    // grid-template-rows: repeat(2, minmax(0, 1fr));
    row-gap: 20px;
  }

  .addMission_body_header_first,
  .addMission_body_header_second {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (max-width: 480px) {
  .addMission_body_second_wrap {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .addMission_container_left {
    display: none;
  }

  .addMission_body_wrapper,
  .addMission_body_header_wrapper {
    grid-template-columns: unset;
  }

  .addMission_body_header_button {
    justify-content: flex-end;
    padding-top: 20px;
  }
}
