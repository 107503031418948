#containerLivestreamRoom {
  background-color: #000;
  height: 100vh;
  overflow: hidden;

  .livestream_action {
    height: calc(100vh - 30px);
    margin: 15px 0 15px -10px;

    .livestream_action_row_1 {
      overflow: hidden;
      background-color: var(--color-sidebar-dark);
      height: 22vh;
      border-radius: 8px;
    }

    .livestream_action_row_2 {
      overflow: hidden;
      background-color: var(--color-sidebar-dark);
      height: calc(78vh - 43px);
      border-radius: 8px;
    }
  }

  .livestream_container_preview {
    height: calc(100vh - 3.28rem);
    background-color: var(--color-sidebar-dark);
    margin: 15px 0;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
  }

  .livestream_footer {
    height: calc(8vh + 10px);
    background-color: var(--color-sidebar-dark);
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em;
  }

  .livestream_setting_volumn{
    display: flex;
  }

  .livestream_setting_group{
    display: flex;
  }

  @media screen and (max-width: 768px) {
    .livestream_setting_volumn{
      display: block;
    }
    .livestream_setting_group{
      display: block;
    }
  }

  .livestream_header {
    justify-content: space-between;
    display: flex;
    background-color: var(--color-sidebar-dark);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.3em 0;
    align-items: center;
  }

  .livestreamroomvideo{
    height: 100vh;
    position: relative;
  }

  .video-player {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: var(--border-radius-box);
  }

  .video-js .vjs-tech {
    object-fit: contain;
  }

  .livestreamroomvideo_container{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }

  .livestreamroomvideo_btn_react{
    width: 3.5em;
    height: 3.5em;
    border: 0;
    background: transparent;
    padding: 0 !important;
  }

  .livestreamroomvideo_btn_react_float{
    width: 2.5em;
    height: 2.5em;
    border: 0;
    background: transparent;
    padding: 0 !important;
  }

  .big_react{
    transform: scale(1.4);
  }

  .livestreamroomvideo_img_react{
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.4);
    }

    &:not(:hover) {
      transition: transform 0.3s ease-in-out;
      opacity: 0.8;
    }
  }

  .livestreamroomvideo_container_top{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    display: flex;
    padding: 1em 1em 2.5em 1em;
    justify-content: space-between;
    align-items: center;
  }


  .livestreamroomvideo_container_bottom{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    display: flex;
    padding: 2.5em 1em 1em 1em;
    justify-content: flex-end;
  }

  .livestreamroomvideo_avatar_mentor{
    width: 4em;
    height: 4em;
    border-radius: 4em;
    object-fit: cover;
  }

  .livestreamroomvideo_txtname_mentor{
    color: var(--color-theme-white);
    font-weight: var(--font-weight-semi-bold);
    font-size: var(--font-size-h5);
  }

  .livestreamroomvideo_txtview_mentor{
    color: var(--color-theme-white);
    font-size: var(--font-size-h6);
  }

  .livestreamroomreact{
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .react_img_board{
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
  }

  .livestreamroom_btn{
    margin-left: 1em;
    background-color: transparent;
    border: 0;
    font-size: var(--font-size-normal);
    color: var(--color-theme-white);
  }

  .spinner-live{
    width: 6em;
    height: 6em;
  }

  .livestreamroom_btn_setting_source{
    margin-left: 0;
    padding-left: 0 !important;
    margin-right: 2em;
    background-color: transparent;
    border: 0;
    font-size: var(--font-size-normal);
    color: var(--color-theme-white);
  }

  .icon_btn{
    font-size: var(--font-size-h5);
  }

  .volume-slider {
    display: flex;
    align-items: center;
  }

  .slider {
    width: 100%;
    opacity: 0.7;
  }

  .slider:hover {
    opacity: 1;
  }

  .volume-label {
    margin-left: 1em;
    width: 50px;
    font-size: var(--font-size-normal);
  }

  .livestreamroom_info_des_container{
    color: var(--color-theme-white);
    overflow-y: scroll;
    h6{
      color: var(--color-theme-white);
    }
    p{
      font-size: var(--font-size-small);
    }

    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-theme-white); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }


}


.livestreamroomDeviceModal{

  .livepreview_device_modal_body{
    background-color: var(--color-sidebar-dark);
    border-radius: var(--border-radius-box);
    height: 340px;
  }

  .livestreamSelection{
    height:240px !important;
  }

  & .color-text-title{
    color: var(--color-theme-primary) !important;
  }
  & .nav-tabs{
    border: none !important;
    background: transparent !important;
    border-radius: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 0 !important;
  }

  & .nav-link {
    color: var(--color-theme-white) !important;
    border: 0 !important;
    margin-bottom: 20px !important;
  }

  & .nav-link:hover {
    color: var(--color-theme-primary-medium) !important;
  }

  & .nav-link.active {
    border-radius: 10px;
    color: var(--color-theme-black) !important;
    background-color: var(--color-theme-white) !important;
    border-bottom-color: var(--color-theme-primary) !important;
    border-bottom-width: 2px !important;
  }

  label{
    color: var(--color-theme-white) !important;
  }
  button.upload-button{
    color: var(--color-theme-white) !important;
  }

  .dragdrop_container{
    background: transparent !important;
  }

  #label-file-upload{
    background-color: transparent !important;
  }

}
