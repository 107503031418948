#livestreamChat{
  display: flex;
  height: 100%;
  flex-direction: column;

  .livestreamroom_chat_container {
    flex:1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .livestreamroom_chatlist_container {
    flex:1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;


    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-theme-white); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }

  .livestreamroom_input{
    color: var(--color-theme-white);
    flex:1;
    padding: 0.3em 0.7em;
    border-radius: var(--border-radius-box);
    border: 0;
    outline: none !important;
    background: rgba(225, 225, 225, 0.12);

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--color-input-placeholder);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--color-input-placeholder);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--color-input-placeholder);
    }
  }
}
