.giftlistitem{
  height: 440px;
  text-decoration: none;
  margin: 0 0 1em;
  //padding: 0 1em;

  .giftlistitem_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius) !important;
    transition: all .5s ease;
    transform: scale(1);
    max-width: unset !important;
  }


  .giftlistitem_container_img {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 60%;
    background: var(--color-theme-white);
    border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
    border: 1px solid #f0f0f0;
    position: relative;
  }

  .giftlistitem_img_layer_time {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    color: var(--color-theme-primary);
  }

  .giftlistitem_img_status {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    color: var(--color-theme-success);

    &.ban {
      color: var(--color-theme-danger);
    }
  }

  .giftlistitem_txt_layer_time {
    margin: 0;
    padding: 0;
    font-size: var(--font-size-normal);
    color: var(--global-font-color);
    font-weight: var(--font-weight-medium);
  }

  .giftlistitem_txt_layer_public {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: var(--color-theme-info);
  }

  .giftlistitem_container {
    height: 100%;
    width: 100%;
    background: transparent;
    //border-radius: var(--border-radius);
    //padding: 0.75em;
    //
    //border: 1px solid #f0f0f0;
    transition: all .5s ease;

    a {
      text-decoration: none;
    }

    &:hover .giftlistitem_img {
      transform: scale(1.05);
      border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
    }

    &:hover {
      background: var(--color-theme-white-box);
    }

    &:hover li {
      opacity: 1;
      -webkit-opacity: 1;
      visibility: visible;
      -webkit-transform: translateX(0) !important;
      transform: translateX(0) !important;
    }
  }

  //.giftlistitem_container:hover {
  //  -webkit-box-shadow: var(--global-box-shadow);
  //  box-shadow: var(--global-box-shadow);
  //}


  .giftlistitem_title {
    width: 90%;
    margin: 0.5em 0.5em;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    text-align: center;

    color: var(--global-font-title);

    font-size: var(--font-size-h6);
    //line-height: 1.5rem;
    font-weight: var(--font-weight-semi-bold);

    &:hover {
      color: var(--color-theme-primary)
    }
  }

  .giftlistitem_price {
    color: var(--color-theme-primary-medium);
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-semi-bold);
  }

  .giftlistitem_received {
    color: var(--global-font-color);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-medium);
  }

  .giftlistitem_container_info {
    //padding: 10px 15px 0 15px;
  }

  .giftlistitem_bottom {
    height: 40%;
    //padding-bottom: 15px;
  }

  .giftlistitem_txt_status.done {
    font-size: var(--font-size-normal);
    color: var(--color-theme-success);
  }


  .giftlistitem_txt_status.ban {
    font-size: var(--font-size-normal);
    color: var(--color-theme-danger);
  }

  .giftlistitem_txt_status.draf {
    font-size: var(--font-size-normal);
    color: var(--global-font-color);
  }

  .giftlistitem_progress {
    margin: 0 5px;
    height: 4px
  }

  .progress_bar_container {
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 100px;
    overflow: hidden;
    height: 10px;
  }

  .progress_bar {
    background-color: #3498db;
    height: 10px;
    border-radius: 100px 0 0 100px;
    width: 50%;
  }

  .giftlistitem_txt_progress {
    font-size: var(--font-size-normal);
    color: var(--global-font-color);
    font-weight: var(--font-weight-medium);
  }

  .giftlistitem_txt_progress.percent {
    color: #a51c30;
    font-weight: 700;
  }

  .giftlistitem_container_status {
    height: 40px;
  }

  .giftlistitem_container_status.giftlistitem_btn {
    background: transparent;
    font-size: var(--font-size-normal);
    font-family: var(--highlight-font-family);
    letter-spacing: var(--letter-spacing-one);
    font-weight: var(--font-weight-semi-bold);
    line-height: var(--font-line-height-body);
    border-radius: var(--border-radius);
    padding: 0.813em 2em;
    border: 0.063em solid transparent;
    display: inline-block;
    vertical-align: top;
    text-transform: none;
    white-space: nowrap;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: var(--color-theme-primary);
    margin: 0 3em 1.5em 3em;

    &:hover {
      border-color: var(--color-theme-primary);
      background: var(--color-theme-primary);
      color: var(--color-theme-white)
    }
  }


  .giftlistitem_container_condition {
    position: absolute;
    top: 0em;
    left: auto;
    right: 0.5em;
    display: inline-block;
    margin: 0;
    transition: all .7s ease-in-out;
    -webkit-transition: all .7s ease-in-out;
    -moz-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;

    ul {
      //display: block;
      //list-style-type: disc;
      //margin-block-start: 1em;
      //margin-block-end: 1em;
      //margin-inline-start: 0px;
      //margin-inline-end: 0px;
      //padding-inline-start: 40px;
    }

    li {
      background: var(--color-theme-white);
      color: var(--color-theme-primary);
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 0 0.5em;
      font-size: var(--font-size-normal);
      font-weight: var(--font-weight-medium);
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      text-align: center;
      margin-top: 0.4em;
      border-radius: var(--border-radius);
      -webkit-box-shadow: var(--global-box-shadow);
      box-shadow: var(--global-box-shadow);
      opacity: 0;
      -webkit-opacity: 0;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      visibility: hidden;
      -webkit-transform: translateX(100%) !important;
      transform: translateX(100%) !important;
      border: 1px solid #f0f0f0;
    }
  }

  .giftlistitem_icon {
    width: 0.8em;
    height: 0.8em;
  }

  @media only screen and (max-device-width: 768px) {
    .giftlistitem_container_status.giftlistitem_btn {
      background: transparent;
      font-size: var(--font-size-small);
      font-family: var(--highlight-font-family);
      letter-spacing: var(--letter-spacing-one);
      font-weight: var(--font-weight-semi-bold);
      line-height: var(--font-line-height-body);
      border-radius: var(--border-radius);
      padding: 0.813em 2em;
      display: inline-block;
      vertical-align: top;
      text-transform: uppercase;
      white-space: nowrap;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      margin: 0 3em 1.5em 3em;

      border-color: var(--color-theme-primary);
      background: var(--color-theme-primary);
      color: var(--color-theme-white)
    }

    .giftlistitem_container {
      height: 100%;
      width: 100%;
      background: var(--color-theme-white-box);
      //border-radius: var(--border-radius);
      //padding: 0.75em;
      //
      //border: 1px solid #f0f0f0;
      transition: all .5s ease;

      a {
        text-decoration: none;
      }

      &.giftlistitem_img {
        transform: scale(1.05);
        border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
      }


      & li {
        opacity: 1;
        -webkit-opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(0) !important;
        transform: translateX(0) !important;
      }
    }
  }

}
