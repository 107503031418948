#gift-receiver{
  .giftreceiver_container {
    max-width: 62.375rem;
    width: 100%;
  }


  .giftreceiver_container_header {
    background: rgb(165,28,48);
    background: linear-gradient(162deg, var(--color-theme-primary-medium) 0%, var(--color-theme-warning) 40%, var(--color-theme-primary-light) 70%, var(--gamifa--preset--color--theme-yellow) 100%);
    height: 18em;
    width: 100%;
    display: flex;
    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  /*
  ========================
        3D Effect
  ========================
  */


  .giftreceiver_txtgift {
    text-transform: uppercase;
    font-size: var(--font-size-h1);
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191,
    1px 1px 1px #919191,
    1px 2px 1px #919191,
    1px 3px 1px #919191,
    1px 4px 1px #919191,
    1px 5px 1px #919191,
    1px 0px 1px #919191,
    1px 0px 1px #919191,
    1px 0px 1px #919191,
    1px 0px 1px #919191,
    1px 16px 5px rgba(16,16,16,0.4),
    1px 20px 8px rgba(16,16,16,0.2),
    1px 22px 30px rgba(16,16,16,0.2),
    1px 26px 40px rgba(16,16,16,0.4);
  }

  .giftreceiver_container_left {
    padding: 1.5em 0 1.5em 1.7em !important;
  }
  .giftreceiver_container_left_sticky {
    position: sticky;
    top: 6em;
  }

  .giftreceiver_container_products {
    padding: 1.7em 1.7em 1.5em 1.2em !important;
  }

  .giftreceiver_container_fillter {
    background: var(--color-theme-white);
    padding: 1em;
    border: 1px solid #f0f0f0;
    border-radius: var(--border-radius-box);
    margin-bottom: 1.5em;
    h5{
      font-weight: var(--font-weight-medium);

      overflow: hidden;
      line-height: 40px;

      //margin-bottom: 20px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      color: var(--color-theme-secondary);

      padding-bottom: 0.5em;
    }

    .form-check{
      color: var(--global-font-color);
      font-size: var(--font-size-body);
      font-weight: var(--font-weight-medium);
      margin: 0.3em 0;
    }
  }

  .giftreceiver_container_products_header_frame {
    //margin: 1.5em 0;
    background: var(--color-theme-white);
    width: 100%;
    border: 1px solid #f0f0f0;
    padding: 1em;
    border-radius: var(--border-radius-box);
  }
  .giftreceiver_container_products_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5{
      color:var(--global-font-color)
    }
  }

  @media (max-width: 1199px)  {
    .sidebar-service-right.css_prefix-woo-sidebar {
      bottom: 0;
      left: 0;
      position: fixed;
      right: auto;
      top: 0;
      width: var(--filter-sidebar-width);
      transform: translate(-100%);
      -webkit-transition: transform .3s cubic-bezier(.645,.045,.355,1);
      transition: transform .3s cubic-bezier(.645,.045,.355,1);
      z-index: 99999;
      background: var(--color-theme-white-box);
      -webkit-box-shadow: var(--global-box-shadow);
      box-shadow: var(--global-box-shadow);
      padding: 0!important;
      margin: 0!important;
    }
  }


  @media only screen and (max-width: 811px) {
    .giftreceiver_container_header {
      background: rgb(165,28,48);
      background: linear-gradient(162deg, var(--color-theme-primary-medium) 0%, var(--color-theme-warning) 40%, var(--color-theme-primary-light) 70%, var(--gamifa--preset--color--theme-yellow) 100%);
      height: 8em;
      width: 100%;
      display: flex;
      & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .form-select, select {
    //line-height: 3.123em!important;
    //height: 3.123em!important;
    font-weight: var(--font-weight-medium) !important;
    padding: 0.5em 3em 0.5em 1em!important;
    width: 100%!important;
    color: var(--global-font-color)!important;
    background-color: var(--global-body-bgcolor) !important;
    border: 0.0625em solid var(--border-color-light)!important;
    border-radius: var(--border-radius)!important;
    -webkit-transition: all .45s ease-in-out!important;
    transition: all .45s ease-in-out!important;
  }

  .giftreceiver_container_admin_header {
    margin-top: 1em;
    border-top: 1px solid rgba(0,0,0,0.1);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1em;
    h6{
      color:var(--global-font-color)
    }
  }

  .giftreceiver_container_search {
    & input {
      outline: none !important;
      box-shadow: none !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 0;
      padding: 0 !important;
      margin: 0 0 0 0.5em !important;
    }

    & input:focus {
      outline: none !important;
      box-shadow: none !important;
      width: 13em;
      border-bottom: 1px solid var(--color-theme-primary) !important;
      border-radius: 0;
      padding: 0 !important;
      margin: 0 0 0 0.5em !important;
    }
  }

  .giftreceiver_btn_add{
    background: var(--color-theme-primary-medium) !important;
    color: var(--color-theme-white) !important;
    &:hover{
      background: var(--color-theme-primary) !important;
    }
  }

  .giftreceiver_container_user{
    background: var(--color-theme-white);
    //margin-top: 1em;
    padding: 0 1.5em;
  }
  .giftreceiver_title{
    background: var(--global-body-bgcolor);
    padding: 1em 1.5em;
  }
  .giftreceiver_container_tab{
    padding: 1em;
    margin-top: 1em;
    background: var(--color-theme-white);

    & .color-text-title{
      color: var(--color-theme-primary) !important;
    }
    & .nav-tabs{
      border: none !important;
      background: var(--color-theme-primary-light) !important;
      border-radius: 5px !important;
      padding-top: 10px !important;
      padding-bottom: 0px !important;
    }

    & .nav-link {
      color: rgb(110, 110, 110) !important;
      border-color: var(--color-theme-primary-light) !important;
      border-width: 2px !important;
    }

    & .nav-link:hover {
      color: var(--color-theme-primary-medium) !important;
    }

    & .nav-link.active {
      color: var(--color-theme-primary) !important;
      background-color: transparent !important;
      border-bottom-color: var(--color-theme-primary) !important;
      border-bottom-width: 2px !important;
    }
  }
  #buddypress div.item-list-tabs .css_prefix-subtab-container ul>li, #buddypress div.item-list-tabs>ul>li {
    display: inline-block;
    padding: 1.624em 0;
    float: inherit;
  }

}
