.detailPaymentModalWithdraw__body {
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 10px;
}

.detailPaymentModalWithdraw__bankWrapper {
  display: grid;
  row-gap: 10px;
}

.detailPaymentModalWithdraw__account {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #b3afaf;
  border-radius: 6px;
  position: relative;
}

.detailPaymentModalWithdraw__account.detailPaymentModalWithdraw__accountChecked {
  background-color: #fff1f1;
  border: 1px solid #ff0000;
}

.detailPaymentModalWithdraw__default {
  display: none;
}

.detailPaymentModalWithdraw__default.detailPaymentModalWithdraw__checked {
  display: flex;
  position: absolute;
  font-size: 14px;
  top: 10px;
  right: 10px;
}

.detailPaymentModalWithdraw__infoBank {
  display: grid;
  grid-template-rows: repeat(2, auto);
  color: #000;
}

.detailPaymentModalWithdraw__infoBank--number {
  font-size: 20px;
  color: #a51c30;
  font-weight: 700;
}

.detailPaymentModalWithdraw__infoBank--infoUser {
  font-weight: 400;
  font-size: 18px;
}

.detailPaymentModalWithdraw__information {
  display: grid;
  grid-template-rows: repeat(4, auto);
  row-gap: 10px;
}

.detailPaymentModalWithdraw__account--iconBank {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 80px;
}

.detailPaymentModalWithdraw__inputWrapper {
  display: grid;
  row-gap: 10px;
}

.detailPaymentModalWithdraw__input {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 10px;
}

.detailPaymentModalWithdraw__input--input {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 50px;
  border: 1px solid black;
  font-size: 16px;
  font-weight: 500;
  color: #a51c30;
  border-radius: 6px;
  outline: none;
  height: 40px;
  padding: 20px 10px;
}

.detailPaymentModalWithdraw__information--balance {
  color: #a51c30;
  font-size: 20px;
  font-weight: 500;
}

.detailPaymentModalWithdraw__information--title {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}

.detailPaymentModalWithdraw__information--note {
  color: red;
}
