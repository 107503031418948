
.forgotPassword_container{
  max-width: 400px;
  padding: 100px 20px;
  margin: auto;
  height: 100%;
}

.forgotPassword_footer{
  display: flex;
  flex-direction: column;
}
.forgotPassword_body{
  margin-bottom: 15px;
}
.forgotPassword_footer_comebackLogin{
  margin-top: 10px;
}
.btn_resend_code{
  border: none !important;
  background: none !important;
  margin-bottom: 4px;
}
.btn_resend_code:hover{
  opacity: 0.8;
  color: var(--color-theme-primary) !important;
}

//change password
.changePassword_container {
  display: grid;
  row-gap: 10px;
  max-width: 400px;
  margin: auto;
}
.changePassword_label{
  font-size: 20px;
  font-weight: 600;
  margin-left: 5%;
}
.changePassword_body{
  display: grid;
  row-gap: 20px;
  margin-top: 10px;
  width: 90%;
  margin: auto;
}
.changePassword_body_item{
  position: relative;
}
.changePassword_body_item_input{
  min-height: 45px;
  font-size: 14px;
  height: auto;
}

.changePassword_body_item_input.validate_failed {
  border-color: var(--bs-form-invalid-border-color) !important;
}
.changePassword_body_item_label{
  position: absolute;
  font-size: 12px;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  user-select: none;
  background-color: #fff;
  padding: 0 4px;
  transition: 0.25s ease;
  cursor: text;
}
.changePassword_body_item_label.validate_failed {
  color: var(--bs-form-invalid-border-color);
  top: 30%;
}
.changePassword_body_item_input:focus + .changePassword_body_item_label,
.changePassword_body_item_input:not(:placeholder-shown) + .changePassword_body_item_label {
  top: 0px;
  left: 20px;
}
.changePassword_body_item_eye{
  position: absolute;
  top: 24px;
  right: 10px;
  transform: translateY(-50%);
}
.changePassword_body_item_eye.validate_failed {
  top: 22px;
}

.changePassword_footer button{
  width: 90%;
  margin: auto;
  display: flex;
}
