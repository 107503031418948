.order_container_select_box {
  background: var(--color-theme-white);
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: var(--border-radius-box);
  padding: 1em;
}
.order_select_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //h1{
  //  color:var(--global-font-color)
  //}
}
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px
}
