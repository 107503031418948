.validateCodePage_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.validateCodePage_wrapper {
  display: grid;
  width: 100%;
  max-width: 388px;
}

.validateCodePage_phone_validate_input {
  display: grid;
  column-gap: 10px;
  // grid-template-columns: 80px 1fr;
}

.validateCodePage_validate_number_code {
  margin-top: 30px;
  display: grid;
}

.validateCodePage_validate_number_code_input {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.validateCodePage_validate_number_code_input_mobile{
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));

}

.validateCodePage_validate_number_code_input_item {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 12px;
  background-color: '#F3F4F5';
}

.validateCode_line {
  position: absolute;
  right: -15px;
  top: 3px;
}
