.chatDetail_container {
  display: grid;
  grid-template-rows: auto 1fr;
}

.chatDetail_body {
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 52px);
  column-gap: 2px;
}

.chatDetail_header {
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 52px;
  padding: 0 24px;
  border-bottom: 1px solid #eee;
  background-color: var(--gamifa--preset--color--white);
}

.chatDetail_header_logo_wrapper {
  display: grid;
  grid-template-columns: 45px auto;
  align-items: center;
  column-gap: 5px;
  justify-content: flex-start;
  width: 100%;
}

.chatDetail_header_logo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.chatDetail_list_message_wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: 450px;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background-color: var(--gamifa--preset--color--white);
  box-shadow: 0px 4px 8px 0px rgba(14, 20, 38, 0.08);
}

.chatDetail_content_message_wrapper {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
}

.chatDetail_information_wrapper {
  max-width: 340px;
  width: 340px;
  height: 100%;
  background-color: var(--gamifa--preset--color--white);
}

.chatDetail_list_message_input {
  padding-left: 35px !important;
}

.chatDetail_list_message_input::placeholder {
  font-size: 14px;
}

.chatDetail_list_message_search {
  position: absolute;
  left: 10px;
  top: 9px;
  width: 18px;
  height: 18px;
}

.chatDetail_list_message_header {
  display: grid;
  row-gap: 10px;
  padding: 16px 24px;
}

.chatDetail_list_message_button {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-start;
  column-gap: 8px;
}

.chatDetail_list_message_button button {
  display: flex;
  height: 36px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  outline: none;
  font-size: 14px;
  white-space: nowrap;
  border: none;
  color: #0e1426;
  background: #f3f4f5;
}

.chatDetail_list_message_button button.chatDetail_active {
  background: #fdeff1;
  color: #dc3545;
}

.chatDetail_list_message_empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chatDetail_list_message_empty img {
  width: 150px;
  height: 150px;
}

.chatDetail_list_message_content {
  padding: 20px 0;
  display: grid;
  grid-auto-rows: 70px;
  row-gap: 5px;
  overflow: auto;
  padding: 0 8px;
}

// .chatDetail_list_message_content::-webkit-scrollbar {
//   width: 5px;
// }

// .chatDetail_list_message_content::-webkit-scrollbar-thumb {
//   background-color: #dc3545;
//   border-radius: 6px;
// }

.chatDetail_content_message_button {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  position: absolute;
  top: 20px;
  right: 30px;
}

.chatDetail_content_message_button_item {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatDetail_content_message_button_item:hover {
  border-radius: 50%;
  background-color: #e0e2e6;
}

@media screen and (max-width: 1024px){
      .chatDetail_container{
        .chatDetail_header{
          display: none;
        }
        .chatDetail_body{
          display: block;
          height: 100vh;
          .chatDetail_list_message_wrapper{
            display: none ;
          }

          .chatDetail_content_message_wrapper{
            .chatDetail_content_message_button{
              right: 10px;
         
            }
          }

          .chatDetail_information_wrapper{
            position: absolute;
            top: 0;
            width: 100%;
            max-width: unset;
          }
        }
      }
}