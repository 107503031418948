.orderChannel_container {
  width: 100%;
  display: grid;
  place-items: center;
}

.orderChannel_wrapper {
  display: grid;
  place-items: center;
  width: 100%;
}

.orderChannel_logo_wrapper {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  align-items: center;
}

.orderChannel_logo_title {
  font-size: 26px;
  font-weight: 700;
  color: var(--color-theme-primary);
}

.orderChannel_logo_image {
  border-radius: 50%;
  object-fit: cover;
}

.orderChannel_body {
  display: grid;
  row-gap: 20px;
}

.orderChannel_title_info {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
}

.orderChannel_information {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  column-gap: 10px;
  background: #f0f0f0;
  border-radius: 6px;
  padding: 16px;
}

.orderChannel_information_content {
  display: grid;
  grid-template-rows: auto auto;
  align-items: flex-start;
}

.orderChannel_title_info_payment {
  background: #f0f0f0;
  border-radius: 6px;
}

.orderChannel_title_info_payment_item {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  column-gap: 10px;
  border-bottom: 1px #e0e0e0 solid;
  padding: 10px 20px;
  align-items: center;
}

.orderChannel_title_info_payment_item:last-child {
  border-bottom: unset;
}
