/**
*   Global change bootstrap
*/

:root {
  --gamifa-font-family: system-ui, -apple-system, 'system-ui', '.SFNSText-Regular', sans-serif;
  --gamifa-font-color: #6f7f92;
  --color-theme-primary: #a51c30;
  --color-theme-primary-medium: #cb4859;
  --color-theme-white: #fff;
  --gamifa--preset--color--black: #000000;
  --gamifa--preset--color--option: #2d2f31;
  --gamifa--preset--color--cyan-bluish-gray: #abb8c3;
  --gamifa--preset--color--white: #ffffff;
  --gamifa--preset--color--pale-pink: #f78da7;
  --gamifa--preset--color--vivid-red: #cf2e2e;
  --gamifa--preset--color--luminous-vivid-orange: #ff6900;
  --gamifa--preset--color--luminous-vivid-amber: #fcb900;
  --gamifa--preset--color--light-green-cyan: #7bdcb5;
  --gamifa--preset--color--vivid-green-cyan: #00d084;
  --gamifa--preset--color--pale-cyan-blue: #8ed1fc;
  --gamifa--preset--color--vivid-cyan-blue: #0693e3;
  --gamifa--preset--color--vivid-purple: #9b51e0;
  --gamifa--preset--color--theme-primary: #e36d60;
  --gamifa--preset--color--theme-secondary: #41848f;
  --gamifa--preset--color--theme-red: #c0392b;
  --gamifa--preset--color--theme-green: #27ae60;
  --gamifa--preset--color--theme-blue: #2980b9;
  --gamifa--preset--color--theme-yellow: #f1c40f;
  --gamifa--preset--color--theme-black: #1c2833;
  --gamifa--preset--color--theme-grey: #95a5a6;
  --gamifa--preset--color--theme-white: #ecf0f1;
  --gamifa--preset--color--custom-daylight: #97c0b7;
  --gamifa--preset--color--custom-sun: #eee9d1;
  --gamifa--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --gamifa--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --gamifa--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --gamifa--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --gamifa--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --gamifa--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --gamifa--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --gamifa--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --gamifa--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --gamifa--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --gamifa--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --gamifa--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
}

/**
 * Custom Properties
 * aka CSS variables.
 *
 * @link: https://developer.mozilla.org/en-US/docs/Web/CSS/--*
 **/

:root {
  /* Container */
  --content-width: 84.433em;
  --content-width-sm: 75em;
  --sidebar-width: 16.862em;
  --mini-sidebar-width: 5.5em;
  --customizer-width: 27em;
  --filter-sidebar-width: 18em;

  /* Typography */
  --global-font-family: system-ui, -apple-system, 'system-ui', '.SFNSText-Regular', sans-serif;
  --highlight-font-family: system-ui, -apple-system, 'system-ui', '.SFNSText-Regular', sans-serif;
  --global-font-size: 1em;
  --global-font-line-height: 1.75;
  --global-box-shadow: 0 14px 54px rgba(0, 0, 0, 0.03);

  /* Page Spacing */
  --global-page-top-spacing: 2em;
  --global-page-bottom-spacing: 2em;
  --global-page-top-spacing-tablet: 2em;
  --global-page-bottom-spacing-tablet: 2em;
  --global-page-top-spacing-mobile: 1em;
  --global-page-bottom-spacing-mobile: 1em;

  /* Fontsize */
  --font-size-tiny: 0.65em;
  --font-size-small: 0.75em;
  --font-size-normal: 0.875em;
  --font-size-body: 1em;
  --font-size-h1: 2.5em;
  --font-size-h2: 2.074em;
  --font-size-h3: 1.728em;
  --font-size-h4: 1.44em;
  --font-size-h5: 1.2em;
  --font-size-h6: 1em;
  --font-size-xs: 0.75em;

  /* Line-Height */
  --font-line-height-body: 1.75;
  --font-line-height-p: 1.75;
  --font-line-height-h1: 1.3;
  --font-line-height-h2: 1.3;
  --font-line-height-h3: 1.3;
  --font-line-height-h4: 1.3;
  --font-line-height-h5: 1.3;
  --font-line-height-h6: 1.3;

  /* Font-Weight */
  --font-weight-body: 400;
  --font-weight-h1: 600;
  --font-weight-h2: 600;
  --font-weight-h3: 500;
  --font-weight-h4: 500;
  --font-weight-h5: 500;
  --font-weight-h6: 500;

  /* font-weight*/
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  /* Letter spacing */
  --font-letter-spacing-body: 0;
  --font-letter-spacing-link: 0;
  --font-letter-spacing-h1: 0;
  --font-letter-spacing-h2: 0;
  --font-letter-spacing-h3: 0;
  --font-letter-spacing-h4: 0;
  --font-letter-spacing-h5: 0;
  --font-letter-spacing-h6: 0;
  --letter-spacing: 0.25em;
  --letter-spacing-one: 0.0625em;
  --letter-spacing-two: 0.125em;
  --letter-spacing-three: 0.188em;
  --letter-spacing-four: 0.25em;
  --letter-spacing-eight: 0.5em;

  /* border-radius */
  --border-radius: 0.313em; /* 5px*/
  --border-radius-10: 0.625em; /* 10px*/
  --border-radius-box: 0.375em; /*6px*/
  --border-radius-12: 0.75em; /*12px*/
  --border-radius-16: 1em; /* 16px */
  --border-radius-eight: 0.5em; /* 8px */
  --border-radius-pill: 3.125em; /* 50px */
  --border-radius-full: 100%; /* 100% */

  /* z index */
  --z-index-minus: -1;

  /* Colors */
  --global-body-bgcolor: #f8f9fa;
  --global-body-lightcolor: #f9fbff;
  --global-font-color: #6f7f92;
  --global-font-title: #07142e;
  --border-color-light: #f1f1f1;
  --border-color-dark: #7f858b;
  --color-theme-white-box: #fff;
  --border-color-light-opacity: rgba(47, 101, 185, 0.19);
  --verify-user-color: #3897f0;
  --dark-icon-color: #64738b;
  --color-post-sticky-bg: #e5efff;
  --color-input-placeholder: #7c859b;
  --color-input-border: #cbcbcb;
  --color-menu-font: #7f858b;
  --disable-color: #cbcbcb;
  --global-font-dark: #6f7f92;
  --color-default-bg-avatar: #ddeaff;
  --color-theme-light-grey: #eff4ff;
  --gradient-color: linear-gradient(151deg, #2f65b9 14.58%, rgb(47 101 185 / 22%) 82.02%);
  --instra-color: linear-gradient(45deg, #f09433, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888);

  /* Custom editor colors */
  --color-theme-primary: #a51c30;
  --color-theme-primary-medium: #cb4859;
  --color-theme-primary-dark: #2f65b90c;
  --color-theme-primary-light: rgb(241, 223, 223);
  --color-theme-primary-light-white: rgb(253, 246, 246);
  --color-theme-success: #00c792;
  --color-theme-success-dark: #029f76ff;
  --color-theme-success-light: #00c79216;
  --color-theme-danger: #f14646;
  --color-theme-danger-dark: #f23737ff;
  --color-theme-danger-light: #f1464616;
  --color-theme-warning: #ffe978;
  --color-theme-warning-dark: #fbe15fff;
  --color-theme-warning-light: #ffe97826;
  --color-theme-info: #0099cc;
  --color-theme-info-dark: #0187b4ff;
  --color-theme-info-light: #0099cc16;
  --color-theme-orange: #f07a3a;
  --color-theme-orange-dark: #ed6922ff;
  --color-theme-orange-light: #f07a3a16;
  --color-theme-grey: #6f7f92;
  --color-theme-grey-dark: #898c8f;
  --color-theme-grey-light: #ebf4ff;
  --color-theme-secondary: #000505;
  --color-theme-black: #000;
  --color-theme-white: #fff;
  --color-theme-skeleton: #f4f8ff;

  --color-theme-online: #2dcdb2;
  --color-theme-offline: #f6c23a;
  --unread-message-color: #f7f7f7;

  --color-theme-ratting: #ffb800;

  --lp-primary-color: var(--color-theme-primary);
  --lp-secondary-color: #07142e;

  --main-bm-color: var(--color-theme-primary) !important;
  --main-bm-bg-color: var(--color-theme-white-box) !important;
  --bm-font-family: var(--global-font-family) !important;
  --bm-mention-color: var(--global-font-color) !important;
  --bm-mention-bg: var(--global-body-bgcolor) !important;

  --rgb-background: var(--color-theme-white-box) !important;
  --color-c: var(--global-font-title);

  --header-height: 70px;
  /* vertical menu */
  --color-sidebar-dark: #212529;
}

@media (min-width: 1920px) {
  :root {
    --font-size-body: 0.834vw;
  }
}

@media (max-width: 991px) {
  :root {
    --font-size-h1: 2em;
    --font-size-h2: 1.8em;
    --font-size-h3: 1.6em;
    --font-size-h4: 1.4em;
    --font-size-h5: 1.2em;
    --font-size-h6: 1em;
  }
}

@media (max-width: 767px) {
  :root {
    --font-size-body: 0.875em;
    --font-size-h1: 1.8em;
    --font-size-h2: 1.6em;
    --font-size-h3: 1.4em;
    --font-size-h4: 1.2em;
    --font-size-h5: 1.1em;
    --font-size-h6: 1em;
  }
}

[data-mode='dark'] {
  --color-theme-white: #fff;
  --global-body-bgcolor: #091025;
  --global-body-lightcolor: #f9fbff;
  --global-font-color: #a5a8ab;
  --global-font-title: #fff;
  --border-color-light: #101421;
  --border-color-dark: #7f858b;
  --border-color-light-opacity: rgba(0, 0, 0, 0.19);
  --dark-icon-color: #fff;
  --color-post-sticky-bg: #e5efff;
  --color-input-placeholder: #7c859b;
  --color-input-border: #cbcbcb;
  --color-menu-font: #7f858b;
  --disable-color: #cbcbcb;
  --color-default-bg-avatar: #ddeaff;
  --color-theme-white-box: #080d1e;
  --unread-message-color: #0b1126;
  --menu-label-color: #566b8c;
  --color-theme-skeleton: #080f26;
  --color-theme-light-grey: #212224;
  --color-default-bg-avata: #010f26;
}

/**
* Custom for button
*/

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--color-theme-primary);
  --bs-btn-border-color: var(--color-theme-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-theme-primary);
  --bs-btn-hover-border-color: var(--color-theme-primary);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-theme-primary);
  --bs-btn-active-border-color: var(--color-theme-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--color-theme-primary);
  --bs-btn-disabled-border-color: var(--color-theme-primary);
}
.bg-primary {
  background-color: var(--color-theme-primary) !important;
}

.btn-outline-primary {
  --bs-btn-color: var(--color-theme-primary);
  --bs-btn-border-color: var(--color-theme-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-theme-primary);
  --bs-btn-hover-border-color: var(--color-theme-primary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-theme-primary);
  --bs-btn-active-border-color: var(--color-theme-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--color-theme-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--color-theme-primary);
  --bs-gradient: none;
}
span {
  font-size: 16px;
  font-family: var(--bm-font-family);
  // color: $textColor;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  div {
    width: 100% !important;
  }

  iframe {
    border: 0;
    margin: 0 0 20px 0;
  }
}

.text-primary {
  color: var(--color-theme-primary) !important;
}

//CSS Select, Option
select > option {
  background-color: var(--gamifa--preset--color--option) !important;
  color: var(--color-theme-white) !important;
}

//Css TextArea
textarea {
  border-radius: unset !important;
}

//Css Button
.MuiButtonBase-root.MuiButton-root.MuiButton-contained { 
  color: #fff !important;
}