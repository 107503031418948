.modal_container{
  overflow-y: hidden;
}
.modal_closebtn{
  position: absolute;
  top:0;
  right:0.5em;
  background: transparent !important;
  border: 0 !important;
  font-size: var(--font-size-h4);
}

.modal_text{
  padding-top: 30px;
  font-size: 16px;
  text-align: center;
}
