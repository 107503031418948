.aboutSkeleton_container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.img_holder {
  width: 100%;
  height: 300px;
}

.img_view {
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 8px;
}

.des_view {
  width: 48%;
  margin-right: 10px;
  border-radius: 3px;
}
