.modalUploadImage_button_plus_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalUploadImage_wrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
}

.modalUploadImage_button_plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  background-color: #f0f0f0;
  border-radius: 6px;
}

.modalUploadImage_icon_plus {
  width: 50px;
  height: 50px;
}

.modalUploadImage_image,
.modalUploadImage_video {
  width: 300px;
  height: 300px;
  border-radius: 6px;
  object-fit: cover;
}

.modalUploadImage_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  border: 2px solid #f0f1f5;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
}
