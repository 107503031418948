
.back_gradient {
  border-radius: 16px;
  padding: 16px;
  color: white
}

.btn_action {
  opacity: 0.7;
}

.first_circle {
  background-color: white;
  width: 220px;
  height: 220px;
  border-radius: 200px;
  opacity: 0.1;
  z-index: 2;
  position: absolute;
  top: -50px;
  right: -50px;
  animation: scaleAnimation 1.5s alternate infinite;
}

@keyframes scaleAnimation {
  0% {
      transform: scale(1.5);
  }

  100% {
      transform: scale(1);
  }
}

.first_secondary {
  background-color: white;
  width: 140px;
  height: 140px;
  border-radius: 200px;
  opacity: 0.2;
  position: absolute;
  top: -15px;
  right: -20px;
  animation: scaleAnimation 1.5s alternate infinite;
}

.animation_action_event {
  top: 0px;
  right: 0px;
  position: absolute;
}

.image_star {
  position: absolute;
  top: -173px;
  left: 0;
  right: 0;
  bottom: 0;
}

.title_home {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 13vw;
  height: 26px;
  font-size: 18px;
  line-height: 1.35;

  @media (max-width: 991px) {
      width: 50vw;
  }
}
