#gift-update{
  .giftupdate_container{
  }
  .giftupdate_container_form{
    overflow-y: scroll;
    /* Tùy chỉnh kiểu thanh scrollbar */
    &::-webkit-scrollbar {
      width: 2px; /* Chiều rộng thanh scrollbar dọc */
    }

    /* Tùy chỉnh kiểu của "nút kéo" (thumb) */
    &::-webkit-scrollbar-thumb {
      background-color: var(--global-font-color); /* Màu nền của thanh scrollbar */
      border-radius: 12px; /* Độ cong của thanh scrollbar */
    }

    /* Tùy chỉnh kiểu của "dải" (track) */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Màu nền của dải scrollbar */
    }
  }

  .giftupdate_file{
    display: flex;
  }

  .giftupdate_file_name{
    display: grid;
    column-gap: 10px;
    align-items: center;
    grid-template-columns: auto 1fr;
  }

  .giftupdate_media_file_name{
    font-size: 15px;
  }

  .giftupdate_uploadmedia_container{
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin-bottom: 16px;
  }
  .giftupdate_container_condition_option{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1em 0;
    width: 48%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    //border-radius: var(--border-radius-box);
    padding: 0.5em 0.5em;
  }

  .giftupdate_container_condition_input{
    width: 65%;
  }

  .form-switch{
    display: flex !important;
    align-items: center !important;
    gap: 0.5em;
  }
  .form-label{
    margin: 0 0.5em 0 0 !important;
  }
  .giftupdate_container_condition_option_quantity{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    visibility: hidden;
    width: 55%;
    &.show_quantity{
      visibility: visible;
    }
  }
  .giftupdate-btn-text{
    color: var(--color-theme-primary);
    border: none;
    background-color: transparent;
  }
  .giftupdate-btn-text:hover{
    opacity: 0.8;
  }
  .giftUpdate_media_wrapper{
    width: 200px;
    height: 150px;
  }
  .giftUpdate_media_body{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
  @media only screen and (max-width: 768px) {
    .giftupdate_container_condition_option{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0 0 1em 0;
      width: 48%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      //border-radius: var(--border-radius-box);
      padding: 0.5em 0.5em;
    }

    .giftupdate_container_condition_option_quantity{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      visibility: hidden;
      width: 100%;
      &.show_quantity{
        visibility: visible;
      }
    }

    .giftupdate_container_condition_input{
      flex:1
    }
  }

}
