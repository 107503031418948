.mobile-header-container{
  background-image: url("../image/ed.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 200px 50px;
  .mobile-header-content{
    color: var(--color-theme-primary);
  }

}
.mobile-display-none{
  display: none;
}

@media screen and (max-width: 1200px) {
  .mobile-header-container{
    background-image: url("../image/bg.png");
    background-size: cover;
    padding: 100px 50px;
  }
}
@media screen and (max-width: 992px) {
  .mobile-header-container{
    background-image: url("../image/bg1.png");
    background-size: cover;
    padding: 60px 50px;
  }
  .mobile-header-content{

  }
}

@media screen and (max-width: 767px) {
  .mobile-header-container{
    padding: 60px 10px;
  }
  .mobile-header-content{

  }
}

@media screen and (max-width: 540px) {

  .mobile-header-container{
    padding: 60px 20px;
  }
  .mobile-header-content{

  }
}
